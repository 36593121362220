import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';

import styles from 'assets/jss/material-dashboard-pro-react/creative-components/customAccordionStyle';

const useStyles = makeStyles(styles);

// TODO: iOutlinedInput` component increases the CSS specificity error

// TODO: FOR FUTURE TYPESCRIPT: color: 'darkGray' | 'white'
export default function CustomAccordion({ initialActive, collapses, color }) {
  const classes = useStyles({ color });

  // eslint-disable-next-line react/destructuring-assignment
  const [active, setActive] = React.useState(initialActive);
  const handleChange = (panel) => (event, expanded) => {
    setActive(expanded ? panel : -1);
  };

  return (
    <div className={classes.root}>
      {collapses.map((prop, key) => (
        <Accordion
          expanded={active === key}
          onChange={handleChange(key)}
          key={key}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded,
          }}
        >
          <AccordionSummary
            expandIcon={active === key ? <Remove /> : <Add />}
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded,
              content: classes.expansionPanelSummaryContent,
              expandIcon: classes.expansionPanelSummaryExpandIcon,
            }}
          >
            <h4 className={classes.title}>{prop.title}</h4>
          </AccordionSummary>
          <AccordionDetails className={classes.expansionPanelDetails}>
            {prop.content}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

CustomAccordion.defaultProps = {
  initialActive: -1,
};

CustomAccordion.propTypes = {
  // Index of the default active collapse
  initialActive: PropTypes.number,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
    }),
  ).isRequired,
  color: PropTypes.string.isRequired,
};
