import React from 'react';

import { makeStyles } from '@material-ui/core';

import styles from 'assets/jss/material-dashboard-pro-react/components/productFeatureStyle';

const useStyles = makeStyles(styles);

// TODO: typescript color: 'blue' | 'orange'
const ProductFeature = ({
  color, text1, text2, text3,
}) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.featureText}>
      {`${text1}: `}
      <span>{text2}</span>
      <span>{text3}</span>
    </div>
  );
};

export default ProductFeature;
