const style = (theme) => ({
  root: (props) => ({
    backgroundColor: props.color === 'white' ? '#F0F0F0' : theme.palette.orange.main,
    borderRadius: '85px',
    height: '144px',
    padding: '32px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    filter: 'drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25))',

    [theme.breakpoints.down('sm')]: {
      padding: '20px 34px',
      height: '104px',
    },
    [theme.breakpoints.only('xs')]: {
      width: '322px',
    },
  }),
  questionsText: (props) => ({
    textAlign: 'center',
    color: props.color === 'white' ? theme.palette.darkGray.main : theme.palette.white.main,
    fontSize: '20px',
    lineHeight: '150%',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  }),
  supportNumberContainer: {
    marginTop: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  supportNumberIcon: (props) => ({
    fontSize: '40px',
    color: props.color === 'white' ? theme.palette.orange.main : theme.palette.white.main,
    marginRight: '12px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  }),
  supportNumberText: (props) => ({
    color: props.color === 'white' ? theme.palette.darkGray.main : theme.palette.white.main,
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '34px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  }),
});

export default style;
