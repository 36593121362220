import moment, { Moment } from 'moment';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  infoCard: {
    margin: '20px auto 0',
    padding: '10px 24px',
    position: 'relative',
    maxWidth: '300px',
    height: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.black.main,

    '& > h4': {
      font: 'inherit',
      fontWeight: 'bold',
      marginBottom: '4px',
    },
    '& > p': {
      font: 'inherit',
      fontWeight: 'normal',

      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
    },

    '& svg': {
      marginLeft: '6px',
      verticalAlign: 'middle',
    },
  },
}));

export type Props = {
  startDate: Moment;
  campaignLengthInMonths: number;
  apnsTotal?: number;
  apnsWithPropensityToSell?: number;
};

export default ({
  startDate, campaignLengthInMonths, apnsTotal, apnsWithPropensityToSell,
}: Props) => {
  const classes = useStyles();

  return (
    <GridContainer justifyContent="center" alignContent="center">
      <GridItem xs={12} sm={6} xl={3}>
        <div className={classes.infoCard} style={{ backgroundColor: '#D5FCCF' }}>
          <h4>First Touch Date</h4>
          <p>{moment(startDate).format('MMMM Do, YYYY')}</p>
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} xl={3}>
        <div className={classes.infoCard} style={{ backgroundColor: '#B5FAAA' }}>
          <h4>Campaign Length</h4>
          <p>{`${campaignLengthInMonths} Months`}</p>
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} xl={3}>
        <div className={classes.infoCard} style={{ backgroundColor: '#D6F5FF' }}>
          <h4>Total APNs</h4>
          <p>
            {apnsTotal ? numberWithCommas(apnsTotal) : 'N/A'}
            {/* <span>
              {numberWithCommas(534532)}
              <Tooltip
                placement="top"
                disableFocusListener
                disableTouchListener
                title="The total number of APNs near the target zip code"
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </span> */}
          </p>
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} xl={3}>
        <div className={classes.infoCard} style={{ backgroundColor: '#B3EDFF' }}>
          <h4>High Propensity to Sell APNs</h4>
          <p>
            {apnsWithPropensityToSell ? numberWithCommas(apnsWithPropensityToSell) : 'N/A'}
            {/* <span>
              {numberWithCommas(34532)}
              <Tooltip
                placement="top"
                disableFocusListener
                disableTouchListener
                title="The number of APNs near the target zip code with a high propensity to sell"
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </span> */}
          </p>
        </div>
      </GridItem>
    </GridContainer>
  );
};
