const style = (theme) => ({
  featureText: (props) => ({
    color: theme.palette.black.main,
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 'bold',
    marginBottom: '8px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
    },

    '& > span:nth-child(1)': {
      color: props.color === 'orange' ? theme.palette.orange.main : theme.palette.primary.main,
      fontSize: '27px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '26px',
      },
    },
    '& > span:nth-child(2)': {
      color: props.color === 'orange' ? theme.palette.orange.main : theme.palette.primary.main,
      fontSize: '16px',
      fontWeight: 'normal',
    },
  }),
});

export default style;
