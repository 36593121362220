import React from 'react';

import { SvgIcon } from '@material-ui/core';

const CallConnectingIcon = (props) => (
  <SvgIcon width="41" height="40" viewBox="0 0 41 40" {...props}>
    <path d="M13.5708 4.23399C11.4908 3.70599 9.31085 3.92999 7.60285 4.88599C5.86285 5.85799 4.64285 7.58199 4.58285 9.88799C4.49085 13.492 5.32485 18.328 8.63685 24.004C11.9068 29.61 15.5168 32.97 18.5128 34.966C20.4048 36.228 22.4949 36.262 24.2809 35.44C26.0409 34.63 27.4689 33.012 28.2108 31.006C28.3955 30.5071 28.4697 29.974 28.4283 29.4436C28.3868 28.9132 28.2308 28.3982 27.9708 27.934L26.0629 24.528C25.476 23.4807 24.5319 22.6795 23.4031 22.2708C22.2743 21.8621 21.0362 21.8731 19.9148 22.302L18.5829 22.812C17.9369 23.058 17.3428 22.944 17.0069 22.612C15.8769 21.502 15.0688 19.996 14.7028 18.372C14.5868 17.86 14.8228 17.256 15.3868 16.83L16.5888 15.922C17.5606 15.1887 18.2314 14.1261 18.4756 12.9335C18.7197 11.7408 18.5203 10.5001 17.9148 9.44399L15.9708 6.05399C15.7133 5.60518 15.3681 5.21277 14.9558 4.9001C14.5435 4.58743 14.0725 4.3609 13.5708 4.23399ZM6.58285 9.93999C6.62285 8.39199 7.40685 7.28599 8.57885 6.62999C9.78285 5.95599 11.4268 5.74999 13.0788 6.16999C13.5688 6.29399 13.9848 6.60999 14.2368 7.04799L16.1788 10.438C16.5421 11.0714 16.6618 11.8155 16.5157 12.5309C16.3695 13.2463 15.9674 13.8838 15.3848 14.324L14.1828 15.232C13.1428 16.016 12.4228 17.36 12.7508 18.81C13.1948 20.774 14.1768 22.636 15.6048 24.038C16.6488 25.062 18.1368 25.122 19.2968 24.678L20.6289 24.168C21.3018 23.9104 22.045 23.9036 22.7226 24.1489C23.4002 24.3943 23.9668 24.8753 24.3189 25.504L26.2249 28.91C26.4649 29.338 26.5049 29.85 26.3349 30.31C25.7549 31.88 24.6649 33.058 23.4449 33.62C22.2509 34.17 20.9009 34.154 19.6228 33.3C16.8808 31.472 13.4829 28.336 10.3628 22.994C7.24085 17.636 6.50085 13.168 6.58485 9.94199L6.58285 9.93999Z" />
    <path d="M25.986 4.12604C25.7542 3.99714 25.4807 3.96561 25.2257 4.03837C24.9706 4.11114 24.7549 4.28224 24.626 4.51404C24.4971 4.74584 24.4656 5.01935 24.5384 5.2744C24.6111 5.52944 24.7822 5.74514 25.014 5.87404L25.782 6.30204C28.1813 7.63485 30.2221 9.52874 31.7302 11.8218C33.2383 14.115 34.1687 16.7391 34.442 19.47L34.506 20.1C34.5136 20.2349 34.5484 20.3668 34.6083 20.4878C34.6682 20.6089 34.752 20.7165 34.8547 20.8043C34.9573 20.892 35.0767 20.9581 35.2056 20.9984C35.3345 21.0388 35.4702 21.0526 35.6046 21.0391C35.7389 21.0256 35.8692 20.985 35.9875 20.9198C36.1057 20.8545 36.2095 20.766 36.2926 20.6596C36.3758 20.5531 36.4365 20.431 36.4711 20.3004C36.5057 20.1699 36.5135 20.0337 36.494 19.9L36.432 19.272C36.1269 16.2192 35.087 13.2857 33.4013 10.7223C31.7155 8.15885 29.4341 6.04178 26.752 4.55204L25.986 4.12604Z" />
    <path d="M23.6422 9.486C23.7789 9.25874 24.0001 9.09505 24.2574 9.03092C24.5147 8.96678 24.7869 9.00746 25.0142 9.144L25.2622 9.292C26.8763 10.2601 28.2608 11.5672 29.3203 13.1228C30.3797 14.6784 31.0887 16.4455 31.3982 18.302L31.4862 18.836C31.5114 18.967 31.5101 19.1016 31.4825 19.2321C31.4548 19.3625 31.4014 19.4861 31.3253 19.5956C31.2491 19.7051 31.1519 19.7982 31.0392 19.8696C30.9266 19.9409 30.8008 19.989 30.6693 20.011C30.5377 20.033 30.4032 20.0285 30.2734 19.9977C30.1437 19.967 30.0214 19.9105 29.9138 19.8318C29.8061 19.7531 29.7154 19.6536 29.6467 19.5393C29.5781 19.4249 29.5331 19.298 29.5142 19.166L29.4242 18.632C29.1625 17.0613 28.5629 15.5661 27.6669 14.2498C26.7708 12.9335 25.5996 11.8274 24.2342 11.008L23.9862 10.858C23.8736 10.7905 23.7753 10.7015 23.697 10.596C23.6187 10.4905 23.562 10.3707 23.53 10.2433C23.4981 10.1159 23.4915 9.9834 23.5108 9.85347C23.5301 9.72353 23.5747 9.59867 23.6422 9.486Z" />
  </SvgIcon>
);

export default CallConnectingIcon;
