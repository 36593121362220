import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';
import Button from 'creative-components/CustomButtons/Button';

import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';

import { importAgentCustomers } from 'utils/api';

import styles from 'assets/jss/material-dashboard-pro-react/modalStyle';
import { warningColor } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles(styles);

const CRMImportModal = ({ onClose, setForceImportsRefresh }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const fileInput = React.createRef();

  const handleCsvChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (!file) return;

    // Show a loading indicator during the upload
    showLoadingIndicatorModal();

    try {
      await importAgentCustomers(file);

      setCurrentAlert('success', 'Your contacts are being imported, it may take a few minutes to complete');

      // Close this modal
      onClose();

      // Refresh the imports data
      setForceImportsRefresh();
    } catch (err) {
      if (err.response) {
        const { error: errorMessage } = err.response.data;
        if (errorMessage) {
          setCurrentAlert('error', `There was an error uploading your CSV: ${errorMessage}`);
        }
      }
    }

    hideLoadingIndicatorModal();
  };

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open
      keepMounted
      onClose={onClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <h4>Import Your Contacts Into CRM Pro</h4>
        <p>
          You can import contacts in bulk by uploading a CSV file. Your CSV file must be in a specific format.
          <br />
          <br />
          {' '}
          <strong><a download target="_blank" rel="noreferrer" href="/crm-pro-import-example.csv" style={{ color: warningColor[0] }}>Click here</a></strong>
          {' '}
          to download an example of a properly formatted CSV file.
        </p>
      </DialogContent>
      <DialogActions
        className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
      >
        <input
          type="file"
          accept="text/csv"
          onChange={handleCsvChange}
          ref={fileInput}
          style={{ display: 'none' }}
        />
        <Button
          color="primary"
          className={classes.importAgentCustomersButton}
          onClick={() => fileInput.current.click()}
        >
          <InsertDriveFileIcon />
          {' '}
          Select File
        </Button>
        <Button onClick={onClose} color="transparent">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CRMImportModal;
