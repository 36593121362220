import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonBase from '@material-ui/core/ButtonBase';
import Backdrop from '@material-ui/core/Backdrop';

import Badge from 'creative-components/Badge/Badge';

import HorizontalTimelineModal from 'components/HorizontalTimeline/HorizontalTimelineModal';

import styles from 'assets/jss/material-dashboard-pro-react/components/horizontalTimelineStyle';

const useStyles = makeStyles(styles);

const HorizontalTimeline = React.forwardRef(({ stories }, ref) => {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = React.useState(null);

  React.useImperativeHandle(ref, () => ({
    closeDialog() {
      setDialogOpen(null);
    },
  }));

  return (
    <div className={classes.timeline}>
      {dialogOpen}
      <div className={classes.filler} />
      {stories.map((story, index) => (
        // <div className={classes.item} key={index}>
        <div
          className={classNames({
            [classes.item]: true,
            [classes.firstItem]: index === 0,
            [classes.lastItem]: index === stories.length - 1,
          })}
          key={index}
        >
          <Tooltip
            title={story.title}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <ButtonBase
              disableRipple={story.disableModal}
              disableTouchRipple={story.disableModal}
              onClick={() => !story.disableModal && setDialogOpen((
                <HorizontalTimelineModal
                  story={story}
                  onClose={() => setDialogOpen(null)}
                />
              ))}
            >
              <div className={classNames({
                [classes.timelineBadge]: true,
                [classes.timelineBadgeLarge]: story.largeBadge,
                [classes[story.badgeColor]]: true,
                [classes[`${story.badgeBorderColor}BadgeBorder`]]: true,
              })}
              >
                <story.badgeIcon htmlColor={story.badgeIconColor} className={`${classes.badgeIcon} ${classNames({ [classes.blinkIcon]: story.blinkBadge })}`} />
              </div>
            </ButtonBase>
          </Tooltip>
          {/* {story.title ? (
            <div className={classes.timelineHeading}>
            <Badge color={story.titleColor}>{story.title}</Badge>
            </div>
          ) : null} */}
        </div>
      ))}
      <div className={classes.filler} />
    </div>
  );
});

HorizontalTimeline.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default HorizontalTimeline;
