import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/components/feedbackQuestionStyle';

const useStyles = makeStyles(styles);

const FeedbackQuestion = ({ question }) => {
  const classes = useStyles();

  return (
    <div className={classes.questionContainer}>
      {question.split('\n').map((part) => (
        <h5 key={part} className={classes.question}>
          {part}
        </h5>
      ))}
    </div>
  );
};

export default FeedbackQuestion;
