const style = (theme) => ({
  root: {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    backgroundColor: theme.palette.white.main,
    borderRadius: '16px',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',

    // Used for effects like the bottom "brokerage account" part showing over the element below
    zIndex: 2,

    [theme.breakpoints.only('xs')]: {
      padding: '48px 14px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '50px 46px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '514px',
      padding: '50px 46px',
    },
  },
  button: {
    margin: '30px 0 32px',
    width: '100%',
    color: theme.palette.white.main,
    padding: '17px 0',
    fontSize: '29px',
    fontWeight: '500',
    lineHeight: '35px',
    background: theme.palette.orange.main,
    borderRadius: '71px',

    '&:hover': {
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '22px',
    },
  },
  text: {
    textAlign: 'center',
    marginBottom: '48px',

    '& > p': {
      color: theme.palette.black.main,
      fontSize: '24px',
      lineHeight: '36px',
      '& > span': {
        fontWeight: 'bold',
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
    '& > div': {
      margin: '20px auto',
      border: `1px solid ${theme.palette.grayScale4.main}`,

      [theme.breakpoints.down('sm')]: {
        border: 'none',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '320px',
      },
    },
  },

  brokerageAccount: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.grayScale7.main,
    borderRadius: '0 0 16px 16px',
    textAlign: 'center',
    padding: '6px 0',
  },
  brokerageAccountButton: {
    fontSize: '20px',
    lineHeight: '22px',

    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },

  submittedInfo: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    textAlign: 'center',

    [theme.breakpoints.only('xs')]: {
      padding: '48px 14px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '100px 46px',
    },

    '& > h2': {
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '40px',
      color: theme.palette.orange.main,
    },
    '& > h3': {
      margin: '25px 0 0',
      fontFamily: 'Montserrat',
      fontWeight: 'bold',
      fontSize: '32px',
      lineHeight: '40px',
      color: theme.palette.black.main,
    },
  },
  submittedInfoRocket: {
    margin: '36px 0',
    fontSize: '133px',
    lineHeight: '140px',
  },
  submittedInfoInstructions: {
    color: theme.palette.black.main,
    fontSize: '24px',
    lineHeight: '150%',
    margin: '20px 0',

    '& > span:first-child': {
      fontWeight: 'bold',
      color: theme.palette.orange.main,
    },
    '& > span:last-child': {
      fontWeight: 'bold',
    },
  },
  submittedInfoDisclaimer: {
    margin: '60px 0 0',
    color: theme.palette.black.main,
    fontSize: '14px',
    lineHeight: '150%',
    fontStyle: 'italic',

    [theme.breakpoints.down('sm')]: {
      margin: '20px 0 0',
    },

    '& > button, & > a': {
      textDecoration: 'underline',
      fontStyle: 'inherit',
      fontWeight: 'inherit',
      fontSize: 'inherit',
    },
  },
  submittedInfoButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',

    '& > button': {
      margin: '0 10px 0',
      backgroundColor: theme.palette.primary.light,
      borderRadius: '8px',
      padding: '8px 12px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      color: theme.palette.grayScale9.main,
      textTransform: 'uppercase',
    },
  },
  connectNowButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  scheduleForLaterButton: {

  },
});

export default style;
