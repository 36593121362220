import React from 'react';
import cx from 'classnames';

import { Hidden, makeStyles } from '@material-ui/core';

import VoicemailIcon from 'components/CustomIcons/VoicemailIcon';
import LetterIcon from 'components/CustomIcons/LetterIcon';
import PostcardIcon from 'components/CustomIcons/PostcardIcon';
import EmailIcon from 'components/CustomIcons/EmailIcon';

import styles from 'assets/jss/material-dashboard-pro-react/components/productSequenceStyle';

const useStyles = makeStyles(styles);

const ProductSequence = ({ asd }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2>
        Sequence Timeline:
        {' '}
        <Hidden smUp>
          <br />
        </Hidden>
        <span>10 steps</span>
      </h2>
      {/* TODO: TEMP */}
      {/* <div className={classes.temp}>TIMELINE</div> */}
      <div className={classes.descriptionText}>
        <p>
          Agent Data uses a touch system to reach out to your clients and/or farm on your behalf. A touch is a marketing piece used in your campaign that is sent from you when you sign up for one of our products.
        </p>
        <span />
        <p>
          We use data from previous campaigns to distinguish which touches yield the best return on your investment and may or may not alter the duration of a campaign, number of touches, type of touch, and content in these touches for any of our products based on this data.
        </p>
      </div>
    </div>
  );
};

export default ProductSequence;
