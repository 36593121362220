import { container, hexToRgb } from 'assets/jss/material-dashboard-pro-react';

import mapOutlineImage from 'assets/img/map-outline-bg.png';

const style = (theme) => ({
  container: {
    ...container,
    position: 'relative',
  },
  root: {
    backgroundColor: theme.palette.lightGray.main,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: '68px 10px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '86px 211px',
    },

    '& h2': {
      color: theme.palette.white.main,
    },
    '& p': {
      color: theme.palette.white.main,
      marginTop: '25px',
      marginBottom: 0,

      [theme.breakpoints.up('md')]: {
        marginLeft: '45px',
        maxWidth: '906px',
      },
    },
  },
  rootBgImage: {
    position: 'relative',
    backgroundImage: `url(${mapOutlineImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  },
  rootBgImageOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `rgba(${hexToRgb(theme.palette.darkGray.main)}, .8)`,
    mixBlendMode: 'hard-light',
  },
});

export default style;
