import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  root: {
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      margin: '0 10px',
      width: 'auto',
    },

    '&:hover': {
      cursor: 'pointer',
    },
    '&:hover div': {
      boxShadow: '0px 4px 22px rgba(0, 0, 0, 0.55)',
      backgroundColor: `rgba(${hexToRgb(theme.palette.orange.main)}, 0.8)`,
    },
    '&:hover span': {
      color: theme.palette.orange.main,
    },
  },
  image: (props) => ({
    position: 'relative',
    borderRadius: '20px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'hard-light',
    background: `url(${props.image})`,

    [theme.breakpoints.down('sm')]: {
      height: '220px',
      width: '220px',
    },
    [theme.breakpoints.only('md')]: {
      height: '200px',
      width: '200px',
    },
    [theme.breakpoints.only('lg')]: {
      height: '300px',
      width: '300px',
    },
    [theme.breakpoints.only('xl')]: {
      height: '370px',
      width: '370px',
    },
  }),
  descriptionText: {
    margin: '34px 0 18px',
    color: theme.palette.black.main,
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '30px',

    [theme.breakpoints.down('sm')]: {
      margin: '8px 0 12px',
      fontSize: '14px',
      lineHeight: '20px',
      maxWidth: '220px',
    },
  },
  learnMoreText: {
    fontFamily: 'Roboto',
    color: theme.palette.gray.main,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '22px',
    textDecoration: 'underline',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
});

export default style;
