import cx from 'classnames';
import moment from 'moment';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  Fade, Icon,
  LinearProgress, Tooltip,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import MailIcon from '@material-ui/icons/Mail';
import PersonIcon from '@material-ui/icons/Person';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

import Button from 'creative-components/CustomButtons/Button';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import CampaignAdminInfoModal from 'components/CampaignAdminInfoModal/CampaignAdminInfoModal';

import {
  checkNextTouchIsVMWithUnassignedRecording, enumToReadableName, isCampaignCancelled, isCampaignDraft, isCampaignPending,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '0px',
  },
  tableHeader: {
    background: '#F2F2F3',
  },
  tableBody: {

  },
  tableRow: {

  },
  tableHeaderCell: {
    textAlign: 'left',
    padding: '12px 16px',
    color: theme.palette.grayScale9.main,
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
  },
  tableDataCell: {
    boxShadow: '0 -1px 0 #BEBEC5', // Use box shadow as small border
    textAlign: 'left',
    padding: '12px 14px',
    color: theme.palette.grayScale9.main,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  nameCell: {
    fontSize: '14px',
    lineHeight: '20px',
    '& > svg': {
      verticalAlign: 'bottom',
      marginRight: '4px',
    },
    '& > a': {
      font: 'inherit',
      color: 'inherit',
      textDecoration: 'underline',

      '&:hover': {
        color: theme.palette.orange.main,
      },
    },
  },

  cellWithIcon: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '5px',
    },
  },
  linkUnderline: {
    '& span': {
      textDecoration: 'underline',
    },
  },

  typeBadge: {
    color: theme.palette.white.main,
    textAlign: 'center',
    padding: '4px 8px',
    borderRadius: '20px',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  },

  /* NOTE: Prefix must be same as event type name w/o spaces */
  AbsenteeOwnersBadge: {
    backgroundColor: theme.palette.absenteeOwners.main,
  },
  NODBadge: {
    backgroundColor: theme.palette.nod.main,
  },
  CommercialBadge: {
    backgroundColor: theme.palette.commercial.main,
  },
  FullFarmBadge: {
    backgroundColor: theme.palette.fullFarm.main,
  },

  leadStats: {
    fontWeight: 700,
  },
  leadStatsGood: {
    color: theme.palette.success.main,
  },
  leadStatsBad: {
    color: theme.palette.warning.main,
  },

  leadResponsesTooltip: {
    padding: '7px 8px',
    '& > p': {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.white.main,
      '& > span': {
        marginLeft: '5px',
        color: theme.palette.primary.main,
      },
    },
  },

  progressBar: {
    background: '#F2F2F3',
    borderRadius: '4px',
    '& .MuiLinearProgress-barColorPrimary': {
      borderRadius: '4px',
    },
  },
}));

const CampaignsTable = ({ campaigns, tableProps }, ref) => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();

  const { isAdmin, isAccountManager, isBrokerageAdmin } = useAuthDataContext();

  const [openModal, setOpenModal] = React.useState(null);

  const tableColumns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: ({ status }) => enumToReadableName(status),
      },
      ...(isAdmin || isAccountManager || isBrokerageAdmin ? [
        {
          Header: 'User',
          accessor: 'userName',
        },
      ] : []),
      {
        Header: 'Campaign Name',
        accessor: 'name',
        Cell: ({ row, value }) => {
          // Check if there are any VM touches with unassigned VM recordings (only for active campaigns)
          const nextTouchIsVMWithUnassignedRecording = row.original.status === 'ACTIVE' && checkNextTouchIsVMWithUnassignedRecording(row.original.touchTriggers);

          // Link goes to the create campaign page for draft campaigns or the campaign details page for existing campaigns
          let linkData;
          if (row.original.status === 'DRAFT') {
            linkData = `/dashboard/create-campaign?campaign=${row.original._id}`;
          } else {
            linkData = {
              pathname: `/dashboard/campaigns/${row.original._id}`,
              state: { previousPathname: location.pathname },
            };
          }

          // No links for admins for draft campaigns
          if ((isAdmin || isBrokerageAdmin) && row.original.status === 'DRAFT') {
            return (
              <div className={classes.nameCell}>
                {value}
              </div>
            );
          }

          return (
            <div className={classes.nameCell}>
              {/* Redirect to campaign page by appending the campaign id to the URL */}
              {nextTouchIsVMWithUnassignedRecording && (
                <Tooltip
                  arrow
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  title={(
                    <div className={classes.leadResponsesTooltip}>
                      <p>Your upcoming Voicemail touch doesn't have a recording assigned</p>
                    </div>
                  )}
                >
                  <WarningRoundedIcon htmlColor={theme.palette.warning.main} />
                </Tooltip>
              )}
              <Link to={linkData}>
                {value}
              </Link>
            </div>
          );
        },
      },
      {
        Header: 'Type',
        accessor: ({ eventType: { name } }) => name,
        Cell: ({ value }) => {
          let shortenedName = value;

          if (value === 'Absentee Owners') shortenedName = 'Absentee';

          return (
            <span className={cx(classes.typeBadge, classes[`${value.replaceAll(' ', '')}Badge`])}>
              {shortenedName}
            </span>
          );
        }
        ,
      },
      {
        Header: 'Properties',
        disableFilters: true,
        accessor: ({ subscribedEvents }) => subscribedEvents.length,
        Cell: ({ row, value }) => {
          const { status, estimatedNumberOfApns } = row.original;

          let content;

          if (isCampaignDraft(status) || isCampaignPending(status)) {
            content = (
              <div className={classes.cellWithIcon}>
                <PersonIcon htmlColor={theme.palette.grayScale10.main} />
                {`${estimatedNumberOfApns} (est.)`}
              </div>
            );
          } else if (isCampaignCancelled(status)) {
            // TODO: ASKED IN SLACK IF PROSPECTS SHOULD BE ACCESSIBLE FOR A CANCELLED CAMPAIGN
            content = (
              <div className={classes.cellWithIcon}>
                <PersonIcon htmlColor={theme.palette.grayScale10.main} />
                {value}
              </div>
            );
          } else {
            content = (
              // Wrap in div to center tooltip over content
              <div>
                <Tooltip
                  arrow
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  title={(
                    <div className={classes.leadResponsesTooltip}>
                      <p>View My Prospects</p>
                    </div>
                )}
                >
                  <Link
                    to={{
                      pathname: `/dashboard/campaigns/${row.original._id}`,
                      state: { previousPathname: location.pathname, tab: 'Prospects' },
                    }}
                    className={classes.linkUnderline}
                  >
                    <div className={classes.cellWithIcon}>
                      <PersonIcon htmlColor={theme.palette.grayScale10.main} />
                      <span>{value}</span>
                    </div>
                  </Link>
                </Tooltip>
              </div>
            );
          }

          return content;
        },
      },
      {
        Header: 'Hot Prospects',
        disableFilters: true,
        accessor: ({ hotProspectsCount }) => hotProspectsCount,
        Cell: ({ row, value }) => {
          const { status } = row.original;

          if (isCampaignDraft(status) || isCampaignPending(status)) {
            return (
              <div className={classes.cellWithIcon}>
                N/A
              </div>
            );
          }

          return (
            // Wrap in div to center tooltip over content
            <div>
              <Tooltip
                arrow
                placement="top"
                disableFocusListener
                disableTouchListener
                title={(
                  <div className={classes.leadResponsesTooltip}>
                    <p>View Hot Prospects</p>
                  </div>
              )}
              >
                <Link
                  to={{
                    pathname: `/dashboard/campaigns/${row.original._id}`,
                    state: { previousPathname: location.pathname, tab: 'Stats - Hot Prospects' },
                  }}
                  className={classes.linkUnderline}
                >
                  <div className={classes.cellWithIcon}>
                    <div style={{ fontSize: 24, marginRight: 5 }}>🔥</div>
                    <span>{value}</span>
                  </div>
                </Link>
              </Tooltip>
            </div>
          );
        },
      },
      /* {
        Header: 'Opens',
        disableFilters: true,
        accessor: ({ uniqueOpenedLeadResponseCounts }) => uniqueOpenedLeadResponseCounts.total,
        Cell: ({ row, value }) => {
          const { subscribedEvents, uniqueOpenedLeadResponseCounts } = row.original;
          const { types } = uniqueOpenedLeadResponseCounts;

          const landingPageCount = types.AGENT_LANDING_OPEN;
          const emailCount = types.EMAIL_OPEN;

          let percentageOfLeadsOpened = 0;
          if (subscribedEvents.length !== 0) percentageOfLeadsOpened = (100 * (value / subscribedEvents.length)).toFixed(0);
          percentageOfLeadsOpened = Math.min(100, percentageOfLeadsOpened);

          return (
            <Tooltip
              arrow
              placement="top"
              disableFocusListener
              disableTouchListener
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 0 }}
              title={(
                <div className={classes.leadResponsesTooltip}>
                  <p>
                    Landing Page:
                    <span>{landingPageCount}</span>
                  </p>
                  <p>
                    Emails:
                    <span>{emailCount}</span>
                  </p>
                  <p>
                    Total:
                    <span>{`${value}*`}</span>
                  </p>
                  <p>
                    <br />
                    <i>*Unique Leads</i>
                  </p>
                </div>
              )}
            >
              <div className={classes.leadStats}>
                {`${percentageOfLeadsOpened}% (${value})`}
              </div>
            </Tooltip>
          );
        },
      },
      {
        Header: 'Replies',
        disableFilters: true,
        accessor: ({ uniqueRepliedLeadResponseCounts }) => uniqueRepliedLeadResponseCounts.total,
        Cell: ({ row, value }) => {
          const { subscribedEvents, uniqueRepliedLeadResponseCounts } = row.original;
          const { types } = uniqueRepliedLeadResponseCounts;

          const landingPageCount = types.AGENT_LANDING_CONSENT_FORM;
          const emailCount = types.EMAIL_REPLY;

          let percentageOfLeadsOpened = 0;
          if (subscribedEvents.length !== 0) percentageOfLeadsOpened = (100 * (value / subscribedEvents.length)).toFixed(0);
          percentageOfLeadsOpened = Math.min(100, percentageOfLeadsOpened);

          return (
            <Tooltip
              arrow
              placement="top"
              disableFocusListener
              disableTouchListener
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 0 }}
              title={(
                <div className={classes.leadResponsesTooltip}>
                  <p>
                    Landing Page:
                    <span>{landingPageCount}</span>
                  </p>
                  <p>
                    Emails:
                    <span>{emailCount}</span>
                  </p>
                  <p>
                    Total:
                    <span>{`${value}*`}</span>
                  </p>
                  <p>
                    <br />
                    <i>*Unique leads</i>
                  </p>
                </div>
              )}
            >
              <div className={cx(
                classes.leadStats,
                percentageOfLeadsOpened > 50 ? classes.leadStatsGood : classes.leadStatsBad,
              )}
              >
                {`${percentageOfLeadsOpened}% (${value})`}
              </div>
            </Tooltip>
          );
        },
      }, */
      {
        Header: 'Progress',
        disableFilters: true,
        accessor: ({ status, campaignStartDate, campaignEndDate }) => {
          // No campaign dates yet for pending campaigns
          if (isCampaignDraft(status) || isCampaignPending(status)) return 0;

          const start = moment(campaignStartDate);
          const end = moment(campaignEndDate);

          let percentage = ((moment().unix() - start.unix()) / (end.unix() - start.unix())) * 100;

          percentage = Math.min(100, Math.floor(percentage));

          return percentage;
        },
        Cell: ({ value }) => (
          <LinearProgress variant="determinate" value={value} className={classes.progressBar} />
        ),
      },
      /* {
        // TODO: Put tooltip here to say that this includes custom mail out addresses?
        Header: 'Delivered',
        disableFilters: true,
        accessor: 'totalNumberOfSuccesses',
        Cell: ({ value }) => (
          <div className={classes.cellWithIcon}>
            <MailIcon htmlColor={theme.palette.grayScale10.main} />
            {value}
          </div>
        ),
      }, */
      {
        Header: 'Start/End Dates',
        disableFilters: true,
        accessor: ({ campaignStartDate, campaignEndDate }) => {
          const startDateString = campaignStartDate ? moment(campaignStartDate).format('MM/DD/YY') : 'TBD';
          const endDateString = campaignEndDate ? moment(campaignEndDate).format('MM/DD/YY') : 'TBD';

          return `${startDateString} - ${endDateString}`;
        },
      },
      ...(isAdmin || isAccountManager ? [
        {
          Header: 'Actions',
          accessor: '_id',
          Cell: ({ value }) => (
            <Tooltip
              arrow
              title="Info"
              placement="top"
            >
              <Button
                justIcon
                round
                simple
                onClick={() => {
                  setOpenModal((
                    <CampaignAdminInfoModal
                      campaign={campaigns.filter((campaign) => campaign._id === value)[0]}
                      onClose={() => { setOpenModal(null); }}
                    />
                  ));
                }}
                color="dark"
              >
                <InfoIcon />
              </Button>
            </Tooltip>
          ),
        },
      ] : []),
    ],
    [],
  );

  const tableData = React.useMemo(
    () => campaigns.map(({
      _id, status, name, agent, eventType, estimatedNumberOfApns, subscribedEvents, hotProspectsCount, campaignStartDate, campaignEndDate, touchTriggers,
      // uniqueOpenedLeadResponseCounts, uniqueRepliedLeadResponseCounts, totalNumberOfSuccesses, // TODO: REMOVE LATER? backend code too!
    }) => ({
      _id,
      status,
      name,
      userName: isAdmin || isAccountManager || isBrokerageAdmin ? `${agent.firstName} ${agent.lastName ?? ''}` : undefined,
      eventType,
      estimatedNumberOfApns,
      subscribedEvents,
      hotProspectsCount,
      // uniqueOpenedLeadResponseCounts,
      // uniqueRepliedLeadResponseCounts,
      // totalNumberOfSuccesses,
      campaignStartDate,
      campaignEndDate,
      touchTriggers,
    })),
    [campaigns],
  );

  return (
    <div className={classes.root}>
      {openModal}

      <ReactTable
        columns={tableColumns}
        data={tableData}
        {...tableProps}
      />
    </div>
  );
};

export default React.forwardRef(CampaignsTable);
