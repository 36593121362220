import React from 'react';
import { Link } from 'react-router-dom';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery, Hidden } from '@material-ui/core';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';
import Card from 'creative-components/Card/Card';
import CardBody from 'creative-components/Card/CardBody';
import Button from 'creative-components/CustomButtons/Button';

import FeatureTestimonial from 'components/Testimonials/FeatureTestimonial';
import InsightsCard from 'components/InsightsCard/InsightsCard';
import Testimonials from 'components/Testimonials/Testimonials';
import ProductMetrics from 'components/Product/ProductMetrics';
import MapMarkerIcon from 'components/CustomIcons/MapMarkerIcon';

import styles from 'assets/jss/material-dashboard-pro-react/views/homePageStyle';

import socialProofDouglasEllimanImage from 'assets/img/home/social-proof/douglas-elliman.png';
import socialProofRemaxImage from 'assets/img/home/social-proof/remax.png';
import socialProofCompassImage from 'assets/img/home/social-proof/compass.png';
import socialProofKellerWilliamsImage from 'assets/img/home/social-proof/keller-williams.png';
import socialProofHarmaImage from 'assets/img/home/social-proof/harma.png';

import testimonialAvatar1Image from 'assets/img/home/testimonial-avatar-1.png';

import insightsImage1 from 'assets/img/home/insights/1.png';
import insightsImage2 from 'assets/img/home/insights/2.png';
import insightsImage3 from 'assets/img/home/insights/3.png';

const useStyles = makeStyles(styles);

// TODO: UPDATE
const metrics = [
  {
    metric: 'Mailers Sent/m',
    value: '304,023',
  },
  {
    metric: 'Conversions/m',
    value: '13,402',
  },
  {
    metric: 'Leads Served',
    value: '54,992',
  },
  {
    metric: 'Hours Saved/m',
    value: '3194',
  },
];

// TODO: FETCH DYNAMICALLY
// This must have 3 elements
const insightCards = [
  {
    link: '#',
    image: insightsImage1,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
  {
    link: '#',
    image: insightsImage2,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
  {
    link: '#',
    image: insightsImage3,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
];

const HomePage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));

  // TODO: MAP MARKER ICONS
  return (
    <div className={classes.root}>
      <div className={classes.heroBackground} />

      <GridContainer className={classes.container}>
        <GridItem xs={1} />
        <GridItem xs={11}>
          <div>
            <h1 className={classes.heroHeader}>
              Your Real Estate
              <br />
              Farming
              <br />
              on Autopilot.
            </h1>
            <Button
              color="primary"
              round
              outlined
              size={!isMobileWidth ? 'lg' : null}
              component={Link}
              to="/sign-up"
            >
              Get Started - Today!
            </Button>
            {/* <Button color="primary" simple>
              button 1
            </Button>
            <Button color="primary">
              button 2
            </Button>
            <Button color="primary" round>
              button 3
            </Button>
            <Button color="primary" outlined size="lg">
              button 4
            </Button>
            <Button color="primary" round outlined size="lg">
              button 5
            </Button>
            <Button color="primary" simple underlined>
              Log-in
            </Button>
            <Button color="primary" simple underlined size="lg">
              Log-in
            </Button> */}
          </div>
        </GridItem>

        <GridItem xs={12} className={classes.socialProofContainer}>
          <img src={socialProofDouglasEllimanImage} className={`${classes.socialProofLogo} ${classes.douglasElliman}`} alt="Douglas Elliman" />
          <img src={socialProofRemaxImage} className={`${classes.socialProofLogo} ${classes.remax}`} alt="REMAX" />
          <img src={socialProofCompassImage} className={`${classes.socialProofLogo} ${classes.compass}`} alt="Compass" />
          <img src={socialProofKellerWilliamsImage} className={`${classes.socialProofLogo} ${classes.kellerWilliams}`} alt="Keller Williams" />
          <img src={socialProofHarmaImage} className={`${classes.socialProofLogo} ${classes.harma}`} alt="Harma Real Estate" />
        </GridItem>
      </GridContainer>

      <div className={classes.absBackground}>
        <div className={classes.absBuilding} />

        <GridContainer className={classes.container}>
          <GridItem md={1} />
          <GridItem xs={9} md={5}>
            <div className={classes.featureAbsContainer}>
              <div className={classes.absDots} />
              <div className={classes.absTouchLine} />

              <h1>
                Absentee Owners
                <span />
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <Hidden smDown>
                <FeatureTestimonial
                  text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod templore magna aliqua."
                  avatar={testimonialAvatar1Image}
                  name="Dhaka Oke"
                  occupation="Product Designer"
                  textColor={theme.palette.darkGray.main}
                  profileTextColor={theme.palette.orange.main}
                  backgroundColor={theme.palette.white.main}
                />
              </Hidden>
              <Button
                color="dark"
                round
                outlined
                className={classes.learnMoreButton}
                component={Link}
                to="/products/absentee-owners"
              >
                Learn More
              </Button>
            </div>
          </GridItem>
          <GridItem xs={3} md={6} />
        </GridContainer>
      </div>

      <div className={classes.nodBackground}>
        <div className={classes.nodBuilding} />

        <GridContainer className={classes.container}>
          <GridItem xs={3} md={6} />
          <GridItem xs={9} md={6}>
            <div className={classes.featureNodContainer}>
              <Hidden smDown>
                <div className={classes.nodDots} />
              </Hidden>
              <div className={classes.nodTouchLine} />

              <h1>
                NOD
                <span />
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <Hidden smDown>
                <FeatureTestimonial
                  text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod templore magna aliqua."
                  avatar={testimonialAvatar1Image}
                  name="Dhaka Oke"
                  occupation="Product Designer"
                  textColor={theme.palette.white.main}
                  profileTextColor={theme.palette.primary.main}
                  backgroundColor={theme.palette.white.main}
                />
              </Hidden>
              <Button
                color="light"
                round
                outlined
                className={classes.learnMoreButton}
                component={Link}
                to="/products/nod"
              >
                Learn More
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.fullFarmBackground}>
        <Hidden mdUp>
          <div className={classes.fullFarmBuilding} />
        </Hidden>

        <GridContainer className={classes.container}>
          <GridItem md={1} />
          <GridItem xs={9} md={5}>
            <div className={classes.featureFullFarmContainer}>
              <Hidden smDown>
                <div className={classes.fullFarmBuilding} />
              </Hidden>

              <h1>
                Full Farm
                <span />
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <Hidden smDown>
                <FeatureTestimonial
                  text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod templore magna aliqua."
                  avatar={testimonialAvatar1Image}
                  name="Dhaka Oke"
                  occupation="Product Designer"
                  textColor={theme.palette.gray.main}
                  profileTextColor={theme.palette.primary.main}
                  backgroundColor={theme.palette.lightGray.main}
                />
              </Hidden>
              <Button
                color="dark"
                round
                outlined
                className={classes.learnMoreButton}
                component={Link}
                to="/products/full-farm"
              >
                Learn More
              </Button>
            </div>
          </GridItem>
          <GridItem xs={3} md={6} />
        </GridContainer>
      </div>

      <div className={classes.commercialBackground}>
        <div className={classes.commercialBuilding} />

        <GridContainer className={classes.container}>
          <GridItem xs={3} md={6} />
          <GridItem xs={9} md={6}>
            <div className={classes.featureCommercialContainer}>
              <div className={classes.commercialBackgroundIcons} />

              <h1>
                Commercial
                <span />
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <br />
                <br />
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tend.
              </p>
              <Button
                color="light"
                round
                outlined
                className={classes.learnMoreButton}
                component={Link}
                to="/products/commercial"
              >
                Learn More
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.crmProBackground}>
        <div className={classes.crmProBuilding} />

        <GridContainer className={classes.container}>
          <GridItem xs={4} md={6} />
          <GridItem xs={8} md={6}>
            <div className={classes.featureCrmProContainer}>
              <div className={classes.crmProBackgroundIcons} />

              <h1>
                CRM Pro
                <span />
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <Hidden smDown>
                <FeatureTestimonial
                  text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod templore magna aliqua."
                  avatar={testimonialAvatar1Image}
                  name="Dhaka Oke"
                  occupation="Product Designer"
                  textColor={theme.palette.white.main}
                  profileTextColor={theme.palette.darkGray.main}
                  backgroundColor={theme.palette.white.main}
                />
              </Hidden>
              <Button
                color="light"
                round
                outlined
                className={classes.learnMoreButton}
                component={Link}
                to="/products/crm-pro"
              >
                Learn More
              </Button>
              <Hidden smDown>
                <h3 className={classes.productMetricsTitle}>We love numbers</h3>
                <ProductMetrics metrics={metrics} />
              </Hidden>
            </div>
          </GridItem>
          <Hidden mdUp>
            <GridItem xs={12} className={classes.crmProSmProductMetrics}>
              <h3 className={classes.productMetricsTitle}>We love numbers</h3>
              <ProductMetrics metrics={metrics} />
            </GridItem>
          </Hidden>
        </GridContainer>
      </div>

      <div className={classes.insightsBackground}>
        <GridContainer className={classes.container}>
          <GridItem xs={12}>
            <h1 className={classes.insightsTitle}>
              Insights
              <Button
                color="primary"
                simple
                underlined
                className={classes.insightsSeeAllButton}
                component={Link}
                to="#" // TODO: BLOG PAGE
              >
                See All
              </Button>
            </h1>
          </GridItem>
          {!isMobileWidth && (
            insightCards.map((card, i) => (
              <GridItem md={4} key={i}>
                <InsightsCard
                  link={card.link}
                  image={card.image}
                  description={card.description}
                />
              </GridItem>
            ))
          )}
        </GridContainer>
        {isMobileWidth && (
          <div className={classes.mobileInsightCards}>
            {insightCards.map((card, i) => (
              <InsightsCard
                key={i}
                link={card.link}
                image={card.image}
                description={card.description}
              />
            ))}
          </div>
        )}
      </div>

      <div className={classes.testimonials}>
        <Testimonials />
      </div>

      {/* <GridContainer className={classes.container}>
        <GridItem xs={12}>
          <Card style={{ backgroundColor: '#cce6ef' }}>
            <h4>Welcome to AgentsdfgsdfasdasData</h4>
          </Card>
        </GridItem>

        {[...Array(12).keys()].map((i) => (
          <GridItem xs={1} key={i}>
            <Card style={{ backgroundColor: '#cce6ef' }}>
              <h4>Welcome to AgentsdfgsdfasdasData</h4>
            </Card>
          </GridItem>
        ))}
      </GridContainer> */}
    </div>
  );
};

export default HomePage;
