import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Close from '@material-ui/icons/Close';

import Button from 'creative-components/CustomButtons/Button';

import styles from 'assets/jss/material-dashboard-pro-react/modalStyle';

const useStyles = makeStyles(styles);

const DecisionModal = ({
  message, onYes, onNo, yesColor, noColor,
}) => {
  const classes = useStyles();
  // Maintain internal error message to prevent flicker bug when closing
  const [m, setM] = React.useState('');

  if (m !== message && message !== '') setM(message);

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={message !== ''}
      keepMounted
      onClose={onNo}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <h5>{m}</h5>
      </DialogContent>
      <DialogActions
        className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
      >
        <Button onClick={onYes} color={yesColor || 'success'}>
          Yes
        </Button>
        <Button onClick={onNo} color={noColor || 'danger'}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DecisionModal.propTypes = {
  message: PropTypes.string.isRequired,
  onYes: PropTypes.func.isRequired,
  onNo: PropTypes.func.isRequired,
  yesColor: PropTypes.string,
  noColor: PropTypes.string,
};

export default DecisionModal;
