import React from 'react';

import { SvgIcon, useTheme } from '@material-ui/core';

const EmailIcon = (props) => {
  const theme = useTheme();

  return (
    <SvgIcon width="50" height="50" viewBox="0 0 50 50" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 8H40C41.5913 8 43.1174 8.59702 44.2426 9.65973C45.3679 10.7224 46 12.1638 46 13.6667V36.3333C46 37.8362 45.3679 39.2776 44.2426 40.3403C43.1174 41.403 41.5913 42 40 42H10C8.4087 42 6.88258 41.403 5.75736 40.3403C4.63214 39.2776 4 37.8362 4 36.3333V13.6667C4 12.1638 4.63214 10.7224 5.75736 9.65973C6.88258 8.59702 8.4087 8 10 8Z" fill={theme.palette.orange.main} />
      <path d="M26.362 13.0316C24.645 12.9067 22.9213 13.1528 21.3079 13.7534C19.6946 14.354 18.2295 15.2949 17.0122 16.5122C15.7949 17.7295 14.854 19.1946 14.2534 20.8079C13.6528 22.4213 13.4067 24.145 13.5316 25.862C13.9637 32.2111 19.5087 37 25.8699 37H30.2987C30.9588 37 31.4989 36.4599 31.4989 35.7998C31.4989 35.1397 30.9588 34.5996 30.2987 34.5996H25.8939C21.4171 34.5996 17.3123 31.695 16.1961 27.3623C14.4078 20.401 20.889 13.9078 27.8502 15.7081C32.195 16.8123 35.0996 20.9171 35.0996 25.3939V26.7141C35.0996 27.6623 34.2474 28.5985 33.2992 28.5985C32.3511 28.5985 31.4989 27.6623 31.4989 26.7141V25.2139C31.4989 22.2013 29.3625 19.4888 26.386 19.0687C25.439 18.9279 24.472 19.0154 23.5656 19.3238C22.6592 19.6322 21.8396 20.1527 21.175 20.842C20.5105 21.5312 20.0202 22.3692 19.7449 23.2862C19.4697 24.2033 19.4175 25.1728 19.5927 26.114C19.8033 27.2482 20.3364 28.2976 21.1284 29.1363C21.9203 29.9751 22.9373 30.5676 24.0576 30.8429C26.266 31.359 28.3663 30.6508 29.7466 29.2466C30.8148 30.7109 32.9512 31.479 34.9075 30.6989C36.5158 30.0627 37.5 28.4184 37.5 26.6901V25.3819C37.5 19.0087 32.7111 13.4637 26.362 13.0316ZM25.4978 28.5985C23.5055 28.5985 21.8972 26.9902 21.8972 24.9978C21.8972 23.0055 23.5055 21.3972 25.4978 21.3972C27.4902 21.3972 29.0985 23.0055 29.0985 24.9978C29.0985 26.9902 27.4902 28.5985 25.4978 28.5985Z" fill="white" />
    </SvgIcon>
  );
};

export default EmailIcon;
