import React from 'react';
import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import CustomInput from 'creative-components/CustomInput/CustomInput';

import styles from 'assets/jss/material-dashboard-pro-react/components/feedbackResponseInputStyle';

const useStyles = makeStyles(styles);

export const longTextResponseCharacterLimit = 1000;

const FeedbackResponseLongText = ({ responseIndex, responseTypesArrayHelpers, responseTypeIndex }) => {
  const classes = useStyles();
  const { values, errors } = useFormikContext();

  return (
    <div className={classes.longInputContainer}>
      <CustomInput
        labelText=""
        margin="normal"
        formControlProps={{
          fullWidth: true,
          style: { paddingTop: 0 },
        }}
        inputProps={{
          placeholder: 'Answer',
          multiline: true,
          rows: 5,
          onChange: (e) => responseTypesArrayHelpers.replace(responseTypeIndex, e.target.value),
        }}
        error={errors.responses !== undefined && errors.responses[responseTypeIndex] !== undefined}
        helperText={(
          <span>
            {`${values.responses[responseTypeIndex] ? values.responses[responseTypeIndex].length : 0} / ${longTextResponseCharacterLimit} characters`}
          </span>
      )}
        feedbackEndIconStyles={{ marginRight: '20px' }} // To not overlap the scrollbar, if it appears
      />
    </div>
  );
};

export default FeedbackResponseLongText;
