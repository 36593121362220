import {
  container,
} from 'assets/jss/material-dashboard-pro-react';

import heroImage from 'assets/img/home/hero.png';
import heroMobileImage from 'assets/img/home/hero-mobile.png';
import absBgImage from 'assets/img/home/abs-bg.svg';
import absBgMobileImage from 'assets/img/home/abs-bg-mobile.svg';
import absBuildingImage from 'assets/img/home/abs-building.png';
import absDotsImage from 'assets/img/home/abs-dots.png';
import absTouchLineImage from 'assets/img/home/abs-touch-line.png';
import nodBuildingImage from 'assets/img/home/nod-building.png';
import nodDotsImage from 'assets/img/home/nod-dots.png';
import nodTouchLineImage from 'assets/img/home/nod-touch-line.png';
import fullFarmBuildingImage from 'assets/img/home/full-farm-building.png';
import commercialBgIconsImageBig from 'assets/img/home/commercial-bg-icons-big.png';
import commercialBgIconsImageSmall from 'assets/img/home/commercial-bg-icons-small.svg';
import commercialBuildingImage from 'assets/img/home/commercial-building.png';
import crmProBgIconsImage from 'assets/img/home/crm-pro-bg-icons.png';
import crmProBuildingImage from 'assets/img/home/crm-pro-building.png';
import insightsBgImage from 'assets/img/home/insights-bg.png';

const style = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.offWhite.main,

    [theme.breakpoints.only('xs')]: {
      backgroundColor: theme.palette.white.main,
    },
  },
  container: {
    ...container,
    position: 'relative',
  },
  heroBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '873px',

    backgroundImage: `url(${heroImage})`,
    backgroundSize: '2141px 864px',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.offWhite.main,

    [theme.breakpoints.only('xs')]: {
      backgroundImage: `url(${heroMobileImage})`,
      height: '375px',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom center',
    },
    [theme.breakpoints.only('sm')]: {
      backgroundSize: 'cover',
      height: '486px',
      backgroundPosition: 'bottom center',
    },
  },

  heroHeader: {
    margin: '212px 0 32px',

    fontWeight: 'bold',
    fontSize: '71.66px',
    lineHeight: '85.64px',
    letterSpacing: 'normal !important',

    [theme.breakpoints.only('xs')]: {
      margin: '32px 0 20px',
      fontSize: '28px',
      lineHeight: '33px',
    },
    [theme.breakpoints.only('sm')]: {
      margin: '42px 0 50px',
      fontSize: '46px',
      lineHeight: '52px',
    },
  },
  socialProofContainer: {
    marginTop: '233px',
    height: '70px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',

    [theme.breakpoints.down('lg')]: {
      marginBottom: '50px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '96px 0 32px',
    },
  },
  socialProofLogo: {
    objectFit: 'contain',
    marginRight: '61px',

    [theme.breakpoints.down('sm')]: {
      marginRight: '15px',
    },
  },
  douglasElliman: {
    maxHeight: '70px',

    [theme.breakpoints.down('md')]: {
      maxHeight: '42px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '29px',
    },
  },
  remax: {
    maxHeight: '28px',

    [theme.breakpoints.down('md')]: {
      maxHeight: '17px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '11px',
    },
  },
  compass: {
    maxHeight: '24px',

    [theme.breakpoints.down('md')]: {
      maxHeight: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '10px',
    },
  },
  kellerWilliams: {
    maxHeight: '50px',

    [theme.breakpoints.down('md')]: {
      maxHeight: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '21px',
    },
  },
  harma: {
    maxHeight: '70px',
    marginRight: 0,

    [theme.breakpoints.down('md')]: {
      maxHeight: '42px',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: '23px',
    },
  },

  learnMoreButton: {
    width: '192px',
  },

  absBackground: {
    // Compensate for the shape on top right
    marginTop: '-25px',
    paddingTop: '51px',

    backgroundImage: `url(${absBgImage})`,
    backgroundRepeat: 'no-repeat',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${absBgMobileImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'top right',
    },
    [theme.breakpoints.only('xs')]: {
      backgroundPosition: 'top 80%',
    },
  },
  absBuilding: {
    position: 'absolute',
    backgroundImage: `url(${absBuildingImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    bottom: '0px',
    right: '0px',

    [theme.breakpoints.only('xs')]: {
      width: 'calc(760px * 0.25)',
      height: 'calc(453px * 0.25)',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(760px * 0.4)',
      height: 'calc(453px * 0.4)',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(760px * 0.65)',
      height: 'calc(453px * 0.65)',
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(760px * 0.85)',
      height: 'calc(453px * 0.85)',
    },
    [theme.breakpoints.only('xl')]: {
      width: '760px',
      height: '453px',
    },
  },
  absDots: {
    position: 'absolute',
    backgroundImage: `url(${absDotsImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    [theme.breakpoints.only('xs')]: {
      bottom: '-26px',
      left: '50%',
      width: 'calc(67px * 0.35)',
      height: 'calc(56px * 0.35)',
    },
    [theme.breakpoints.only('sm')]: {
      bottom: '-20px',
      left: '60%',
      width: 'calc(67px * 0.6)',
      height: 'calc(56px * 0.6)',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '-50px',
      right: '30px',
      width: '67px',
      height: '56px',
    },
  },
  absTouchLine: {
    position: 'absolute',
    backgroundImage: `url(${absTouchLineImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',

    [theme.breakpoints.only('xs')]: {
      width: 'calc(382px * 0.4)',
      height: 'calc(240px * 0.4)',
      top: '18px',
      right: '-76px',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(382px * 0.5)',
      height: 'calc(240px * 0.5)',
      top: '10px',
      right: '-80px',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(382px * 0.75)',
      height: 'calc(240px * 0.75)',
      top: '-60px',
      right: '-420px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '382px',
      height: '240px',
      top: '-60px',
      right: '-500px',
    },
  },
  featureAbsContainer: {
    position: 'relative',
    margin: '75px 0 98px',

    [theme.breakpoints.down('sm')]: {
      margin: '0 0 35px',
    },

    '& > h1': {
      color: theme.palette.darkGray.main,
      marginBottom: 0,

      '& > span': {
        display: 'inline-block',
        width: '30px',
        height: '0px',
        border: `3px solid ${theme.palette.white.main}`,
        marginLeft: '10px',

        [theme.breakpoints.down('sm')]: {
          border: `2px solid ${theme.palette.white.main}`,
        },
      },
    },

    '& > p': {
      color: theme.palette.darkGray.main,
      margin: '24px 0',
      maxWidth: '468px',
      height: '81px',

      [theme.breakpoints.only('xs')]: {
        maxWidth: '200px',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '7px 0 0',
        fontSize: '14px',
        lineHeight: '20px',
        height: 'auto',
        maxWidth: '320px',
      },
    },

    '& > a': {
      marginTop: '43px',

      [theme.breakpoints.down('sm')]: {
        margin: '26px 0 0',
      },
    },
  },

  nodBackground: {
    // Compensate for a blue line at the top
    marginTop: '-2px',
    paddingTop: '2px',
    position: 'relative',

    backgroundColor: theme.palette.lightGray.main,
  },
  nodBuilding: {
    position: 'absolute',
    backgroundImage: `url(${nodBuildingImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    bottom: '0px',
    left: '0px',

    [theme.breakpoints.only('xs')]: {
      width: 'calc(646px * 0.2)',
      height: 'calc(547px * 0.3)',
      backgroundSize: 'cover',
      backgroundPosition: 'top right',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(646px * 0.3)',
      height: 'calc(547px * 0.3)',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(646px * 0.7)',
      height: 'calc(547px * 0.7)',
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(646px * 0.82)',
      height: 'calc(547px * 0.82)',
    },
    [theme.breakpoints.only('xl')]: {
      width: '646px',
      height: '547px',
    },
  },
  nodDots: {
    position: 'absolute',
    backgroundImage: `url(${nodDotsImage})`,
    width: '57px',
    height: '47px',

    [theme.breakpoints.up('md')]: {
      top: '6px',
      right: '30px',
    },
  },
  nodTouchLine: {
    position: 'absolute',
    backgroundImage: `url(${nodTouchLineImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: '163px',

    [theme.breakpoints.only('xs')]: {
      maxWidth: '240px',
      width: '100%',
      height: '45px',
      left: '0px',
      bottom: '-45px',
    },
    [theme.breakpoints.only('sm')]: {
      width: '320px',
      left: '0px',
      bottom: '-140px',
    },
    [theme.breakpoints.only('md')]: {
      width: '400px',
      left: '0px',
      bottom: '-160px',
    },
    [theme.breakpoints.only('lg')]: {
      width: '600px',
      left: '-50px',
      bottom: '-120px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '749px',
      left: '-120px',
      bottom: '-90px',
    },
  },
  featureNodContainer: {
    position: 'relative',
    margin: '64px 0 127px',

    [theme.breakpoints.down('sm')]: {
      margin: '32px 0 54px',
    },

    '& > h1': {
      color: theme.palette.white.main,
      marginBottom: 0,

      '& > span': {
        display: 'inline-block',
        width: '30px',
        height: '0px',
        border: `3px solid ${theme.palette.primary.main}`,
        marginLeft: '10px',

        [theme.breakpoints.down('sm')]: {
          border: `2px solid ${theme.palette.primary.main}`,
        },
      },
    },

    '& > p': {
      color: theme.palette.white.main,
      marginTop: '24px',
      marginBottom: '27px',
      maxWidth: '468px',
      height: '81px',

      [theme.breakpoints.only('xs')]: {
        maxWidth: '200px',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '7px 0 0',
        fontSize: '14px',
        lineHeight: '20px',
        height: 'auto',
        maxWidth: '320px',
      },
    },

    '& > a': {
      marginTop: '40px',

      [theme.breakpoints.down('sm')]: {
        margin: '26px 0 0',
      },
    },
  },

  fullFarmBackground: {
    backgroundColor: theme.palette.offWhite.main,
    position: 'relative',
    overflow: 'hidden',
  },
  fullFarmBuilding: {
    position: 'absolute',
    backgroundImage: `url(${fullFarmBuildingImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    bottom: '-155px', // Amount of margin in parent container + a few for a white line

    [theme.breakpoints.only('xs')]: {
      width: '350px',
      height: '122px',
      right: '-120px',
      bottom: 0,
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(1428px * 0.4)',
      height: 'calc(533px * 0.4)',
      right: '-100px',
      bottom: 0,
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(1428px * 0.75)',
      height: 'calc(533px * 0.75)',
    },
    [theme.breakpoints.up('lg')]: {
      width: '1428px',
      height: '533px',
    },
  },
  featureFullFarmContainer: {
    position: 'relative',
    margin: '64px 0 153px',

    [theme.breakpoints.down('sm')]: {
      margin: '32px 0 35px',
    },

    '& > h1': {
      color: theme.palette.gray.main,
      marginBottom: 0,

      '& > span': {
        display: 'inline-block',
        width: '30px',
        height: '0px',
        border: `3px solid ${theme.palette.orange.main}`,
        marginLeft: '10px',

        [theme.breakpoints.down('sm')]: {
          border: `2px solid ${theme.palette.orange.main}`,
        },
      },
    },

    '& > p': {
      color: theme.palette.darkGray.main,
      marginTop: '24px',
      marginBottom: '28px',
      maxWidth: '528px',
      height: '51px',

      [theme.breakpoints.only('xs')]: {
        maxWidth: '200px',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '7px 0 0',
        fontSize: '14px',
        lineHeight: '20px',
        height: 'auto',
        maxWidth: '320px',
      },
    },

    '& > a': {
      marginTop: '40px',

      [theme.breakpoints.down('sm')]: {
        margin: '26px 0 0',
      },
    },
  },

  commercialBackground: {
    backgroundColor: theme.palette.gray.main,
    position: 'relative',
    overflow: 'hidden',
  },
  commercialBuilding: {
    position: 'absolute',
    backgroundImage: `url(${commercialBuildingImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    bottom: '0px',
    left: '0px',

    [theme.breakpoints.only('xs')]: {
      width: 'calc(609px * 0.2)',
      height: 'calc(423px * 0.2)',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(609px * 0.35)',
      height: 'calc(423px * 0.35)',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(609px * 0.75)',
      height: 'calc(423px * 0.75)',
    },
    [theme.breakpoints.up('lg')]: {
      width: '609px',
      height: '423px',
    },
  },
  commercialBackgroundIcons: {
    position: 'absolute',
    backgroundImage: `url(${commercialBgIconsImageSmall})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '344px',
    height: '92px',

    [theme.breakpoints.only('xs')]: {
      width: '344px',
      height: '92px',
      top: '-36px',
      left: '-90px',
      right: 'unset',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(344px * 1.8)',
      height: 'calc(92px * 1.8)',
      top: '-14px',
      right: '-32px',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(344px * 2)',
      height: 'calc(92px * 2)',
      top: '-60px',
      right: '-24px',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${commercialBgIconsImageBig})`,
      width: 'calc(975px * 0.8)',
      height: 'calc(464px * 0.8)',
      top: '-86px',
      right: '-68px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '975px',
      height: '464px',
      top: '-58px',
      right: '-60px',
    },
  },
  featureCommercialContainer: {
    position: 'relative',
    margin: '121px 0 138px',

    [theme.breakpoints.down('sm')]: {
      margin: '52px 0 46px',
    },

    '& > h1': {
      color: theme.palette.white.main,
      marginBottom: 0,

      '& > span': {
        display: 'inline-block',
        width: '30px',
        height: '0px',
        border: `3px solid ${theme.palette.primary.main}`,
        marginLeft: '10px',

        [theme.breakpoints.down('sm')]: {
          border: `2px solid ${theme.palette.primary.main}`,
        },
      },
    },

    '& > p': {
      color: theme.palette.white.main,
      marginTop: '26px',
      marginBottom: '45px',
      maxWidth: '468px',
      height: '162px',

      [theme.breakpoints.down('sm')]: {
        margin: '7px 0 20px',
        fontSize: '14px',
        lineHeight: '20px',
        height: 'auto',
      },
    },
  },

  crmProBackground: {
    backgroundColor: theme.palette.orange.main,
    position: 'relative',
    overflow: 'hidden',
  },
  crmProBuilding: {
    position: 'absolute',
    backgroundImage: `url(${crmProBuildingImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    left: '0px',

    [theme.breakpoints.only('xs')]: {
      width: 'calc(658px * 0.4)',
      height: 'calc(459px * 0.4)',
      bottom: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(658px * 0.5)',
      height: 'calc(459px * 0.5)',
      bottom: '10px',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(658px * 0.7)',
      height: 'calc(459px * 0.7)',
      bottom: '10px',
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(658px * 0.9)',
      height: 'calc(459px * 0.9)',
      bottom: '-88px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '658px',
      height: '459px',
      bottom: '-140px',
    },
  },
  crmProBackgroundIcons: {
    position: 'absolute',
    backgroundImage: `url(${crmProBgIconsImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    top: '-64px', // Margin top amount of parent container

    [theme.breakpoints.only('xs')]: {
      width: 'calc(599px * 0.22)',
      height: 'calc(600px * 0.22)',
      top: 0,
      left: '-150px',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(599px * 0.4)',
      height: 'calc(600px * 0.4)',
      top: '-36px',
      left: '-252px',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(599px * 0.7)',
      height: 'calc(600px * 0.7)',
      left: '-480px',
    },
    [theme.breakpoints.only('lg')]: {
      width: 'calc(599px * 0.9)',
      height: 'calc(459px * 0.9)',
      left: '-620px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '599px',
      height: '600px',
      left: '-737px',
    },
  },
  featureCrmProContainer: {
    position: 'relative',
    margin: '64px 0 350px',

    [theme.breakpoints.down('sm')]: {
      margin: '43px 0 32px',
    },

    '& > h1': {
      color: theme.palette.darkGray.main,
      marginBottom: 0,

      '& > span': {
        display: 'inline-block',
        width: '30px',
        height: '0px',
        border: `3px solid ${theme.palette.white.main}`,
        marginLeft: '10px',

        [theme.breakpoints.down('sm')]: {
          border: `2px solid ${theme.palette.white.main}`,
        },
      },
    },

    '& > p': {
      color: theme.palette.darkGray.main,
      marginTop: '26px',
      marginBottom: '28px',
      maxWidth: '468px',
      height: '81px',

      [theme.breakpoints.down('sm')]: {
        margin: '7px 0 0',
        fontSize: '14px',
        lineHeight: '20px',
        height: 'auto',
      },
    },

    '& > a': {
      marginTop: '40px',

      [theme.breakpoints.down('sm')]: {
        margin: '26px 0 0',
      },
    },
  },
  productMetricsTitle: {
    marginTop: '60px',
    marginBottom: '24px',
    color: theme.palette.darkGray.main,

    [theme.breakpoints.down('sm')]: {
      margin: '0 0 6px',
    },
  },
  crmProSmProductMetrics: {
    margin: '0 0 234px',
  },

  insightsBackground: {
    // Compensate for the shape on top
    marginTop: '-236px',
    padding: '148px 0 103px',

    backgroundImage: `url(${insightsBgImage})`,
    position: 'relative',
    backgroundSize: '100vw 100%',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('sm')]: {
      marginTop: '-160px',
      padding: '60px 0 40px',
      backgroundSize: 'cover',
    },
  },
  insightsTitle: {
    color: theme.palette.darkGray.main,
    textAlign: 'center',
    marginBottom: '40px',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      fontSize: '22px',
      lineHeight: '26px',
    },
  },
  insightsSeeAllButton: {
    position: 'absolute',
    left: 'calc(50% + 142px)',
    bottom: 0,

    [theme.breakpoints.down('sm')]: {
      left: 'calc(50% + 72px)',
      bottom: '-10px',
    },
  },
  mobileInsightCards: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    width: '100vw',
    overflowX: 'auto',

    '-webkit-overflow-scrolling': 'touch',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  testimonials: {
    backgroundColor: theme.palette.primary.main,
  },
});

export default style;
