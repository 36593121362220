import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import passwordComplexity from 'joi-password-complexity';

import { makeStyles } from '@material-ui/core/styles';
import {
  Card, CardHeader, CardContent,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import CustomInput from 'creative-components/CustomInput/CustomInput';
import Button from 'creative-components/CustomButtons/Button';

import AcknowledgeModal from 'components/AcknowledgeModal/AcknowledgeModal';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import PasswordVisibility from 'components/PasswordVisibility/PasswordVisibility';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';

import { authenticate } from 'utils/api';
import { minPasswordLength, maxPasswordLength } from 'utils/lib';

const complexityOptions = {
  min: 8,
  max: 16,
  lowerCase: 1,
  upperCase: 1,
  numeric: 1,
  symbol: 1,
  requirementCount: 4,
};

const useStyles = makeStyles(styles);

const passwordValidationChecks = [
  {
    description: 'At least 8 characters',
    errorName: 'passwordComplexity.tooShort',
  },
  {
    description: 'One lowercase character',
    errorName: 'passwordComplexity.lowercase',
  },
  {
    description: 'One uppercase character',
    errorName: 'passwordComplexity.uppercase',
  },
  {
    description: 'One number',
    errorName: 'passwordComplexity.numeric',
  },
  {
    description: 'One symbol',
    errorName: 'passwordComplexity.symbol',
  },
];

const PasswordValidationInfo = ({ description, valid }) => {
  const classes = useStyles();

  return (
    <div>
      {valid
        ? (
          <CheckCircleIcon
            fontSize="small"
            className={classes.passwordValidationValidIcon}
          />
        )
        : (
          <RadioButtonUncheckedIcon
            fontSize="small"
            color="disabled"
            className={classes.passwordValidationInvalidIcon}
          />
        )}
      <h4 className={classes.passwordValidationInfoText}>
        {description}
      </h4>
    </div>
  );
};

const ResetPasswordForm = ({
  heading, subheading, email, passwordResetToken,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { setCurrentAlert } = useAlertContext();
  const { LOGIN_ROUTE, onLogin } = useAuthDataContext();

  const [modalMessage, setModalMessage] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div>
      <AcknowledgeModal
        message={modalMessage}
        onClose={() => setModalMessage('')}
      />
      <Formik
        initialValues={{
          password: '', passwordConfirmation: '',
        }}
        onSubmit={async ({ password }) => {
          try {
            const { token, expiresInDays } = await authenticate(email, password, passwordResetToken);

            setCurrentAlert('success', 'Your password has been successfully changed');

            // Call the login function on the auth context
            const isRegistration = heading === 'Create Your Password';
            onLogin(token, expiresInDays, isRegistration, email);
          } catch (error) {
            setModalMessage('Unable to reset your password. Please contact customer support.');
            console.error('error', error);
          }
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string().max(maxPasswordLength).required('Password is required')
            .test(
              'complexity',
              'Password must be between 8 and 24 characters',
              (value) => !passwordComplexity(complexityOptions).validate(value).error,
            ),
          passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required(),
        })}
      >
        {(props) => {
          const {
            touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
          } = props;

          const passwordValidationInfos = [];
          const passwordValidation = passwordComplexity(complexityOptions).validate(values.password);

          const passwordErrors = passwordValidation.error ? passwordValidation.error.details.map(({ type }) => type) : [];

          passwordValidationChecks.forEach(({ description, errorName }) => {
            passwordValidationInfos.push(<PasswordValidationInfo
              key={errorName}
              description={description}
              valid={values.password.length > 0 && !passwordErrors.includes(errorName)}
            />);
          });

          return (
            <Form>
              {isSubmitting ? <LoadingIndicator modal /> : null}
              <Card>
                <CardHeader
                  title={heading}
                  subheader={subheading}
                  classes={{
                    root: classes.cardHeaderRoot,
                    title: classes.cardHeaderTitle,
                  }}
                />
                <div className={classes.cardHeaderLine} />
                <CardContent>
                  <Field
                    name="password"
                  >
                    {({ field }) => (
                      <CustomInput
                        labelText="Password"
                        inputProps={{
                          ...field,
                          type: showPassword ? 'text' : 'password',
                          endAdornment: (
                            <PasswordVisibility
                              showPassword={showPassword}
                              toggleShowPassword={toggleShowPassword}
                              className={classes.inputAdornmentIcon}
                            />
                          ),
                          onChange: (e) => setFieldValue(field.name, e.target.value.substring(0, 16), true),
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                        formControlProps={{
                          classes: {
                            root: classes.customInputDashboard,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <Field
                    name="passwordConfirmation"
                  >
                    {({ field }) => (
                      <CustomInput
                        labelText="Confirm password"
                        inputProps={{
                          ...field,
                          type: showPassword ? 'text' : 'password',
                          onChange: (e) => setFieldValue(field.name, e.target.value.substring(0, 16), true),
                          onKeyDown: (event) => {
                            if (event.key === 'Enter') {
                              // Same checks as submit button below
                              if (isValid && dirty && !isSubmitting) {
                                handleSubmit();
                              }
                            }
                          },
                        }}
                        error={touched[field.name] && errors[field.name] !== undefined}
                        formControlProps={{
                          classes: {
                            root: classes.customInputDashboard,
                          },
                        }}
                      />
                    )}
                  </Field>
                  <div className={classes.passwordValidationInfosContainer}>
                    {passwordValidationInfos}
                  </div>
                  <Button
                    className={classes.primaryAction}
                    type="submit"
                    color="primary"
                    round
                    block
                    disabled={!isValid || !dirty || isSubmitting}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </CardContent>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
