import { container, hexToRgb } from 'assets/jss/material-dashboard-pro-react';

import heroImage from 'assets/img/sign-up/hero.png';
import heroBgGraphic from 'assets/img/sign-up/hero-bg-graphic.svg';
import personImage from 'assets/img/sign-up/person.png';

const style = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  container: {
    ...container,
    position: 'relative',
  },

  hero: {
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  },
  heroOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.darkBlueGray.main,
    mixBlendMode: 'multiply',
    opacity: 0.7,
  },

  person: {
    position: 'absolute',
    bottom: '0px',
    background: `url(${personImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',

    [theme.breakpoints.only('xs')]: {
      top: '586px',
      right: '-10px',
      width: 'calc(329px * .4)',
      height: 'calc(475px * .4)',
    },
    [theme.breakpoints.only('sm')]: {
      top: '594px',
      right: '-12px',
      width: 'calc(329px * .5)',
      height: 'calc(475px * .5)',
    },
    [theme.breakpoints.only('md')]: {
      left: 'calc(50% - 200px)',
      width: 'calc(329px * .58)',
      height: 'calc(475px * .58)',
    },
    [theme.breakpoints.only('lg')]: {
      left: 'calc(50% - 232px)',
      width: 'calc(329px * .7)',
      height: 'calc(475px * .7)',
    },
    [theme.breakpoints.only('xl')]: {
      left: 'calc(50% - 312px)',
      width: '329px',
      height: '475px',
    },
  },
  heroGraphic: {
    position: 'absolute',
    background: `url(${heroBgGraphic})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',

    [theme.breakpoints.only('xs')]: {
      top: '176px',
      left: '0px',
      height: '442px',
    },
    [theme.breakpoints.only('sm')]: {
      top: '230px',
      left: '0px',
      height: '442px',
    },
    [theme.breakpoints.only('md')]: {
      top: '280px',
      left: '10px',
      height: '703px',
    },
    [theme.breakpoints.only('lg')]: {
      top: '280px',
      left: '10px',
      height: '703px',
    },
    [theme.breakpoints.only('xl')]: {
      top: '280px',
      left: '50px',
      height: '703px',
    },
  },

  title: {
    textAlign: 'center',
    padding: '94px 0 130px',

    // Add max height here so when text wraps it doesn't mess up margins/padding
    maxHeight: '284px',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      maxHeight: '300px',
      overflow: 'none',
      padding: '48px 0 86px',
      fontSize: '42px',
      lineHeight: '48px',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '28px',
      lineHeight: '33px',
      maxHeight: '178px',
      padding: '48px 0 64px',
    },
  },
  left: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '35px',
      // maxWidth: '382px',
    },
    [theme.breakpoints.only('md')]: {
      marginLeft: '62px',
      maxWidth: '382px',
    },
    [theme.breakpoints.only('lg')]: {
      marginLeft: '62px',
      maxWidth: '382px',
    },
    [theme.breakpoints.only('xl')]: {
      marginLeft: '102px',
      maxWidth: '432px',
    },
  },
  step: {
    '& > h3': {
      margin: '0 0 12px',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '28px',

      [theme.breakpoints.only('xs')]: {
        fontSize: '18px',
        lineHeight: '21px',
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
    '& > p': {
      fontSize: '20px',
      lineHeight: '30px',
      color: theme.palette.white.main,

      [theme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
      [theme.breakpoints.only('sm')]: {
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
  },
  step1: {
    // These step heights match up with background image bullet points
    height: '278px',

    [theme.breakpoints.only('xs')]: {
      maxWidth: '86%',
      height: '174px',
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '80%',
      height: '173px',
    },
  },
  step2: {
    height: '200px',

    [theme.breakpoints.only('xs')]: {
      maxWidth: '86%',
      height: '124px',
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '80%',
      height: '125px',
    },
  },
  step3: {
    height: '120px',

    [theme.breakpoints.only('xs')]: {
      maxWidth: '86%',
      height: '216px',
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '80%',
      height: '216px',
    },

    [theme.breakpoints.up('md')]: {
      maxWidth: '330px',
    },
  },
  right: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '60px',
    },
    [theme.breakpoints.only('xl')]: {
      marginRight: '74px',
    },
  },
  rightHeader: {
    textAlign: 'center',
    margin: '0 0 32px',

    '& h2': {
      margin: '0 0 10px',
    },
    '& div': {
      border: '2px solid #FFFFFF',
      width: '159px',
      height: 0,
      margin: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',

      '& div': {
        width: '87px',
        margin: 0,
      },
    },
  },

  missionStatement: {
    marginTop: '-63px',

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
});

export default style;
