import { infoColor } from 'assets/jss/material-dashboard-pro-react';

const style = {
  questionContainer: {
    marginTop: '62px',
  },
  question: {
    fontWeight: '500',
  },
};

export default style;
