// xl sizes
const imageWidth = '310px';
const imageHeight = '322px';

const lgImageWidth = `calc(${imageWidth} * 0.8)`;
const lgImageHeight = `calc(${imageHeight} * 0.8)`;

const mdImageWidth = `calc(${imageWidth} * 0.6)`;
const mdImageHeight = `calc(${imageHeight} * 0.6)`;

const smImageWidth = `calc(${imageWidth} * 0.5)`;
const smImageHeight = `calc(${imageHeight} * 0.5)`;

const xsImageWidth = `calc(${imageWidth} * 0.4)`;
const xsImageHeight = `calc(${imageHeight} * 0.4)`;

const style = (theme) => ({
  root: ({ positionVariant }) => ({
    position: 'relative',
    width: '100%',
    marginLeft: positionVariant === 'left' && '-28px',

    [theme.breakpoints.only('xs')]: {
      margin: 'auto',
      height: '360px',
      width: '270px',
    },
    [theme.breakpoints.only('sm')]: {
      margin: 'auto',
      height: '440px',
      width: '330px',
    },
    [theme.breakpoints.only('md')]: {
      height: '555px',
    },
    [theme.breakpoints.only('lg')]: {
      height: '755px',
    },
    [theme.breakpoints.only('xl')]: {
      height: '868px',
    },
  }),
  image: {
    objectFit: 'contain',
    filter: 'drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25))',

    [theme.breakpoints.only('xs')]: {
      width: xsImageWidth,
      height: xsImageHeight,
    },
    [theme.breakpoints.only('sm')]: {
      width: smImageWidth,
      height: smImageHeight,
    },
    [theme.breakpoints.only('md')]: {
      width: mdImageWidth,
      height: mdImageHeight,
    },
    [theme.breakpoints.only('lg')]: {
      width: lgImageWidth,
      height: lgImageHeight,
    },
    [theme.breakpoints.only('xl')]: {
      width: imageWidth,
      height: imageHeight,
    },
  },
  voicemails: {
    position: 'absolute',
    top: '0px',

    [theme.breakpoints.only('xs')]: {
      left: `calc(50% - ${xsImageWidth} / 2)`,
    },
    [theme.breakpoints.only('sm')]: {
      left: `calc(50% - ${smImageWidth} / 2)`,
    },
    [theme.breakpoints.only('md')]: {
      left: `calc(50% - ${mdImageWidth} / 2)`,
    },
    [theme.breakpoints.only('lg')]: {
      left: `calc(50% - ${lgImageWidth} / 2)`,
    },
    [theme.breakpoints.only('xl')]: {
      left: `calc(50% - ${imageWidth} / 2)`,
    },
  },
  letters: {
    position: 'absolute',
    left: '0px',

    [theme.breakpoints.only('xs')]: {
      top: `calc(50% - ${xsImageWidth} / 2)`,
    },
    [theme.breakpoints.only('sm')]: {
      top: `calc(50% - ${smImageWidth} / 2)`,
    },
    [theme.breakpoints.only('md')]: {
      top: `calc(50% - ${mdImageHeight} / 2)`,
    },
    [theme.breakpoints.only('lg')]: {
      top: `calc(50% - ${lgImageHeight} / 2)`,
    },
    [theme.breakpoints.only('xl')]: {
      top: `calc(50% - ${imageHeight} / 2)`,
    },
  },
  emails: {
    position: 'absolute',
    right: '0px',

    [theme.breakpoints.only('xs')]: {
      top: `calc(50% - ${xsImageWidth} / 2)`,
    },
    [theme.breakpoints.only('sm')]: {
      top: `calc(50% - ${smImageWidth} / 2)`,
    },
    [theme.breakpoints.only('md')]: {
      top: `calc(50% - ${mdImageHeight} / 2)`,
    },
    [theme.breakpoints.only('lg')]: {
      top: `calc(50% - ${lgImageHeight} / 2)`,
    },
    [theme.breakpoints.only('xl')]: {
      top: `calc(50% - ${imageHeight} / 2)`,
    },
  },
  postcards: {
    position: 'absolute',
    bottom: '0px',

    [theme.breakpoints.only('xs')]: {
      left: `calc(50% - ${xsImageWidth} / 2)`,
    },
    [theme.breakpoints.only('sm')]: {
      left: `calc(50% - ${smImageWidth} / 2)`,
    },
    [theme.breakpoints.only('md')]: {
      left: `calc(50% - ${mdImageWidth} / 2)`,
    },
    [theme.breakpoints.only('lg')]: {
      left: `calc(50% - ${lgImageWidth} / 2)`,
    },
    [theme.breakpoints.only('xl')]: {
      left: `calc(50% - ${imageWidth} / 2)`,
    },
  },

  numTouchesCard: {
    backgroundColor: theme.palette.gray.main,
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',

    '& p': {
      color: theme.palette.white.main,
      fontWeight: '500',
    },

    '& svg': {
      marginRight: '7px',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '8px',
      borderRadius: '8px',

      '& p': {
        fontSize: '16px',
        lineHeight: '20px',
      },

      '& svg': {
        fontSize: '20px',
        marginRight: '4px',
      },
    },
    [theme.breakpoints.only('md')]: {
      padding: '8px 14px',

      '& p': {
        fontSize: '16px',
        lineHeight: '28px',
      },

      '& svg': {
        fontSize: '24px',
      },
    },
    [theme.breakpoints.only('lg')]: {
      padding: '10px 16px',

      '& p': {
        fontSize: '20px',
        lineHeight: '28px',
      },

      '& svg': {
        fontSize: '32px',
      },
    },
    [theme.breakpoints.only('xl')]: {
      padding: '14px 20px',

      '& p': {
        fontSize: '28px',
        lineHeight: '34px',
      },

      '& svg': {
        fontSize: '42px',
      },
    },
  },
  numVoicemails: ({ positionVariant }) => ({
    position: 'absolute',

    // Position variants don't matter for xs/sm breakpoints
    [theme.breakpoints.only('xs')]: {
      top: '30px',
      right: '-21px',
    },
    [theme.breakpoints.only('sm')]: {
      top: '33px',
      right: '-6px',
    },
    [theme.breakpoints.only('md')]: {
      top: positionVariant === 'right' ? '60px' : '64px',
      right: positionVariant === 'right' ? '-6px' : '8px',
    },
    [theme.breakpoints.only('lg')]: {
      top: positionVariant === 'right' ? '110px' : '112px',
      right: positionVariant === 'right' ? '-6px' : '30px',
    },
    [theme.breakpoints.only('xl')]: {
      top: positionVariant === 'right' ? '110px' : '112px',
      right: positionVariant === 'right' ? '-6px' : '30px',
    },
  }),
  numLetters: ({ positionVariant }) => ({
    position: 'absolute',

    // Position variants don't matter for xs/sm breakpoints
    [theme.breakpoints.only('xs')]: {
      top: '122px',
      left: '-35px',
    },
    [theme.breakpoints.only('sm')]: {
      top: '146px',
      left: '-22px',
    },
    [theme.breakpoints.only('md')]: {
      top: positionVariant === 'right' ? '214px' : '176px',
      left: positionVariant === 'right' ? '-40px' : '-4px',
    },
    [theme.breakpoints.only('lg')]: {
      top: positionVariant === 'right' ? '280px' : '266px',
      left: positionVariant === 'right' ? '-50px' : '-4px',
    },
    [theme.breakpoints.only('xl')]: {
      top: positionVariant === 'right' ? '280px' : '266px',
      left: positionVariant === 'right' ? '-50px' : '-4px',
    },
  }),
  numEmails: ({ positionVariant }) => ({
    position: 'absolute',

    // Position variants don't matter for xs/sm breakpoints
    [theme.breakpoints.only('xs')]: {
      top: '191px',
      right: '-33px',
    },
    [theme.breakpoints.only('sm')]: {
      top: '234px',
      right: '-37px',
    },
    [theme.breakpoints.only('md')]: {
      top: positionVariant === 'right' ? '300px' : '303px',
      right: positionVariant === 'right' ? '-36px' : '-38px',
    },
    [theme.breakpoints.only('lg')]: {
      top: positionVariant === 'right' ? '446px' : '420px',
      right: positionVariant === 'right' ? '-22px' : '-38px',
    },
    [theme.breakpoints.only('xl')]: {
      top: positionVariant === 'right' ? '496px' : '480px',
      right: positionVariant === 'right' ? '-22px' : '-38px',
    },
  }),
  numPostcards: ({ positionVariant }) => ({
    position: 'absolute',

    // Position variants don't matter for xs/sm breakpoints
    [theme.breakpoints.only('xs')]: {
      top: '313px',
      left: '0px',
    },
    [theme.breakpoints.only('sm')]: {
      top: '394px',
      left: '24px',
    },
    [theme.breakpoints.only('md')]: {
      top: positionVariant === 'right' ? '500px' : '452px',
      left: positionVariant === 'right' ? '50px' : '30px',
    },
    [theme.breakpoints.only('lg')]: {
      top: positionVariant === 'right' ? '650px' : '610px',
      left: positionVariant === 'right' ? '50px' : '34px',
    },
    [theme.breakpoints.only('xl')]: {
      top: positionVariant === 'right' ? '750px' : '694px',
      left: positionVariant === 'right' ? '50px' : '34px',
    },
  }),
});

export default style;
