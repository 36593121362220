import {
  primaryColor,
  infoColor,
} from 'assets/jss/material-dashboard-pro-react';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

const style = (theme) => {
  const modalStylesWithTheme = modalStyle(theme);

  return ({
    ...modalStylesWithTheme,
    modalBody: {
      ...modalStylesWithTheme.modalBody,
      minWidth: '300px',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
    },
    storyBody: {
      fontSize: '15px',
    },
    footerLine: {
      marginTop: '20px',
      marginBottom: '10px',
    },
  });
};

export default style;
