import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import LoginForm from 'components/LoginForm/LoginForm';
import StartPasswordResetForm from 'components/ResetPasswordForm/StartPasswordResetForm';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';

const useStyles = makeStyles(styles);

const LoginPage = () => {
  const classes = useStyles();

  const [startingPasswordReset, setStartingPasswordReset] = React.useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        {startingPasswordReset ? (
          <StartPasswordResetForm
            setStartingPasswordReset={setStartingPasswordReset}
          />
        ) : (
          <LoginForm
            setStartingPasswordReset={setStartingPasswordReset}
          />
        )}
      </div>
    </div>
  );
};

export default LoginPage;
