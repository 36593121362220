import React from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import { Hidden, useMediaQuery } from '@material-ui/core';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';
import Button from 'creative-components/CustomButtons/Button';

import SupportContactCard from 'components/SupportContactCard/SupportContactCard';
import CommonQuestions from 'components/CommonQuestions/CommonQuestions';
import Testimonials from 'components/Testimonials/Testimonials';
import ProductFeature from 'components/Product/ProductFeature';

import styles from 'assets/jss/material-dashboard-pro-react/views/pricingPageStyle';
import { productMarketingInfo } from 'utils/productMarketingInfo';

const useStyles = makeStyles(styles);

const BulletPoint = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.pricingCardBulletPoint}>
      <Check />
      {text}
    </div>
  );
};

const PricingCardCost = ({ membershipFeePerMonth }) => {
  const classes = useStyles();

  return (
    <div>
      <span className={classes.pricingCardPriceAmount}>
        {`$${membershipFeePerMonth}`}
      </span>
      <span className={classes.pricingCardPriceAmountSlash}>
        /
      </span>
      <span className={classes.pricingCardPriceAmountTerm}>
        month
      </span>
      <div className={classes.pricingCardPriceDisclaimer}>
        Per agent
      </div>
    </div>
  );
};

// TODO: Typescript orientation: 'vertical' | 'horizontal'
const PricingCard = ({ orientation, eventTypeName }) => {
  const theme = useTheme();
  const classes = useStyles({ orientation });
  const isXsWidth = useMediaQuery(theme.breakpoints.only('xs'));

  const productInfo = productMarketingInfo[eventTypeName];

  return (
    <div className={classes.pricingCard}>
      <div style={{ flex: 1 }}>
        <h3>
          {eventTypeName}
          <span className={classes.pricingCardTitleOrangeAccent} />
        </h3>
        <p>{productInfo.pricingPageDescription}</p>
      </div>

      <div className={classes.pricingCardCostAndButtonContainer} style={{ flex: 1 }}>
        <PricingCardCost membershipFeePerMonth={productInfo.membershipFeePerMonth} />
        <Button
          color="primary"
          round
          outlined
          size={!isXsWidth ? 'lg' : null}
          fullWidth
          className={classes.pricingCardButton}
          component={Link}
          to={{
            pathname: '/sign-up',
            state: { eventTypeName },
          }}
        >
          Choose
        </Button>
      </div>

      <div className={classes.pricingCardFeatures} style={{ flex: 1 }}>
        <ProductFeature color="primary" text1="Commitment" text2="6" text3="/months" />
        <ProductFeature color="primary" text1="Mailing Fee" text2="$1" text3="/months" />
        <ProductFeature color="primary" text1="Min. # of Records" text2="100" />
      </div>

      <div style={{ flex: 1 }}>
        <BulletPoint text={`${productInfo.numLetters} Letters / month`} />
        <BulletPoint text={`${productInfo.numPostcards} Postcards / month`} />
        <BulletPoint text={`${productInfo.numEmails}  Emails / month`} />
        <BulletPoint text={`${productInfo.numVoicemails}  Voicemails / month`} />
        <BulletPoint text="24/7 Live Support" />
      </div>

    </div>
  );
};

const PricingPage = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <div className={classes.hero}>
        <div className={classes.heroOverlayColor} />

        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.header}>
            <h1>Pricing Plans</h1>
            <div />
          </GridItem>

          <Hidden smDown>
            <Hidden mdDown xlUp>
              <GridItem lg={1} />
            </Hidden>
            <GridItem md={6} lg={5} xl={3}>
              <PricingCard orientation="vertical" eventTypeName="Absentee Owners" />
            </GridItem>
            <GridItem md={6} lg={5} xl={3}>
              <PricingCard orientation="vertical" eventTypeName="NOD" />
            </GridItem>
            <Hidden mdDown xlUp>
              <GridItem lg={1} />
            </Hidden>

            <Hidden xlUp>
              <div className={classes.spacer} />
            </Hidden>

            <Hidden mdDown xlUp>
              <GridItem lg={1} />
            </Hidden>
            <GridItem md={6} lg={5} xl={3}>
              <PricingCard orientation="vertical" eventTypeName="Commercial" />
            </GridItem>
            <GridItem md={6} lg={5} xl={3}>
              <PricingCard orientation="vertical" eventTypeName="Full Farm" />
            </GridItem>
            <Hidden mdDown xlUp>
              <GridItem lg={1} />
            </Hidden>

            <GridItem md={12} className={classes.crmProCard}>
              <PricingCard orientation="horizontal" eventTypeName="CRM Pro" />
            </GridItem>
          </Hidden>

          <Hidden mdUp>
            <GridItem xs={1} />
            <GridItem xs={10}>
              <Carousel
                className={classes.pricingCardsCarousel}
                autoPlay={false}
                animation="slide"
                navButtonsAlwaysInvisible
                indicatorContainerProps={{
                  style: {
                    backgroundColor: theme.palette.grayScale6.main,
                    width: 'fit-content',
                    padding: '3px 0 4px 8px', // No padding on right b/c of 8px margin right below on icons
                    borderRadius: '8px',
                    margin: '32px auto 0',
                  },
                }}
                activeIndicatorIconButtonProps={{
                  style: { color: theme.palette.orange.main },
                }}
                indicatorIconButtonProps={{
                  style: {
                    color: theme.palette.grayScale4.main,
                    width: '12px',
                    height: '12px',
                    marginRight: '8px',
                  },
                }}
              >
                <PricingCard orientation="vertical" eventTypeName="Absentee Owners" />
                <PricingCard orientation="vertical" eventTypeName="NOD" />
                <PricingCard orientation="vertical" eventTypeName="Commercial" />
                <PricingCard orientation="vertical" eventTypeName="Full Farm" />
                <PricingCard orientation="vertical" eventTypeName="CRM Pro" />
              </Carousel>
            </GridItem>
            <GridItem xs={1} />
          </Hidden>

          <GridItem xs={12}>
            <div className={classes.brokerageAccount}>
              <Button
                color="orange"
                round
                size="lg"
                className={classes.brokerageAccountButton}
                component={Link}
                to="/products/brokerage-accounts"
              >
                Need a Brokerage Account?
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.commonQuestions}>
        <CommonQuestions color="lightGray" />
      </div>

      <div className={classes.testimonials}>
        <div className={classes.supportCard}>
          <SupportContactCard color="white" />
        </div>
        <Testimonials />
      </div>
    </div>
  );
};

export default PricingPage;
