import { grayColor } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  videoContainer: {
    borderRadius: '8px',

    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  productInfoTitle: {
    textAlign: 'center',
    fontSize: '32px',
    fontWeight: '400',
    paddingBottom: '20px',
  },
  productInfoRoot: {
    overflow: 'hidden',
    width: '100%',

    minHeight: '1400px',

    [theme.breakpoints.up('xl')]: {
      minHeight: '1900px',
    },
  },
  productInfo: {
    '& ul': {
      listStyle: 'none',
      paddingLeft: '0px',
    },
    '& > ul > li': {
      paddingTop: '4px',
      paddingBottom: '10px',

      [theme.breakpoints.down('xs')]: {
        paddingTop: '8px',
        paddingBottom: '30px',
      },
    },
  },
  productDescription: {
    // marginTop: '65px', // Took out b/c we removed touches list
    fontSize: '14px',
    lineHeight: '1.2',
    color: '#333333',
    textAlign: 'justify',
  },
  productDescriptionKey: {
    fontSize: '20px',
  },
  productDescriptionValue: {
    float: 'right',
    fontWeight: 500,
    fontSize: '16px',
  },
  productInfoTouchesList: {
    float: 'right',
    textAlign: 'right',
    fontWeight: 500,
    fontSize: '16px',
  },
  getStartedButton: {
    textAlign: 'center',
    padding: '60px 0 60px',
  },
  productTimeline: {
    marginBottom: '60px',
  },
  flyerContainer: {
    textAlign: 'center',
  },
  flyer: {
    objectFit: 'contain',
    minHeight: '200px',
    width: '100%',
    height: '100%',
  },
  disclaimerText: {
    fontSize: '12px',
    color: grayColor[2],

    [theme.breakpoints.down('sm')]: {
      padding: '80px 20px 0',
      textAlign: 'center',
    },
  },

  touchesContainer: {

  },
  touchInfo: {
    marginTop: '40px',
    marginBottom: '40px',
    textAlign: 'center',
  },
  touchInfoText: {
    color: '#606163',
    fontWeight: 600,
    fontSize: '22px',
  },
  touchInfoBadgeIcon: {
    width: '45px',
    height: '100%',
  },
});

export default style;
