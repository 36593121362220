import React from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import OurMissionStatement from 'components/Product/OurMissionStatement';
import FreeGuideForm from 'components/FreeGuideForm/FreeGuideForm';

import styles from 'assets/jss/material-dashboard-pro-react/views/closeLikeAProPageStyle';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles(styles);

const CloseLikeAProPage = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <div className={classes.hero}>
        <div className={classes.heroOverlay} />

        <GridContainer className={classes.container}>
          <Hidden smDown>
            <GridItem md={6}>
              <div className={classes.left}>
                <p>
                  Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam, ultricies sollicitudin erat facilisis eget. Vestibulum rhoncus dui vel eros laoreet consectetur. Vivamus eget elementum ligula, vitae ph
                </p>
              </div>
            </GridItem>
          </Hidden>
          <GridItem xs={12} md={6}>
            <div className={classes.right}>
              <div className={classes.rightHeader}>
                <h1>Close Like a Champ 🚀</h1>
                <p>- Free Guide -</p>
                <div />
              </div>
              <FreeGuideForm />
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <OurMissionStatement />
    </div>
  );
};

export default CloseLikeAProPage;
