import {
  Field,
  Form, Formik,
} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { ButtonBase } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import ActionModal from 'components/ActionModal/ActionModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import SaveButton, { ESaveButtonFormikStatus } from 'components/SaveButton/SaveButton';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { updateUser } from 'utils/api';
import {
  nameRegExp, phoneRegExp, showAPIErrorAlert, urlRegExp,
} from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  topMargin: {
    margin: '47px 0 0',
  },
  saveButtonContainer: {
    float: 'right',
    margin: '26px 0 0',
  },
}));

const PersonalDataForm = (props, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const { setCurrentAlert } = useAlertContext();
  const { userInfo, reloadUserInfo } = useUserInfoContext();

  const [showMultiAgentNotice, setShowMultiAgentNotice] = React.useState(false);

  return (
    <>
      {showMultiAgentNotice && (
        <ActionModal
          backgroundColor={theme.palette.offWhite.main}
          textColor={theme.palette.darkGray.main}
          icon={<InfoRoundedIcon />}
          onClose={() => setShowMultiAgentNotice(false)}
          title="Sharing an Account?"
          message={"If you're sharing this account with another agent and using more than one name, make sure to preview your marketing materials. Some grammar may need to be fixed to work with a group of people."}
          buttons={[
            <ButtonBase onClick={() => setShowMultiAgentNotice(false)}>Ok</ButtonBase>,
          ]}
        />
      )}

      <Formik
        innerRef={ref}
        initialValues={{
          firstName: userInfo.firstName,
          lastName: userInfo.lastName || '',
          email: userInfo.email,
          phoneNumber: userInfo.phoneNumber || '',
          // slogan: userInfo.agentInfo.slogan || '',
          website: userInfo.agentInfo.website || '',
          agentDreNumber: userInfo.agentInfo.agentDreNumber || '',
          marketingEmail: userInfo.agentInfo.marketingEmail || '',
          marketingPhoneNumber: userInfo.agentInfo.marketingPhoneNumber || '',
          touchEmail: userInfo.agentInfo.touchEmail?.replaceAll('@eleganceagents.com', '') || '',
          postcardTestimonial: userInfo.agentInfo.postcardTestimonial || '',
        }}
        onSubmit={async (values, { resetForm, setStatus }) => {
          const {
            firstName,
            lastName,
            phoneNumber,
            // slogan,
            website,
            agentDreNumber,
            marketingEmail,
            marketingPhoneNumber,
            touchEmail,
            postcardTestimonial,
          } = values;

          try {
            await updateUser({
              firstName,
              lastName,
              phoneNumber,
              agentInfo: {
                // slogan,
                website,
                agentDreNumber,
                marketingEmail,
                marketingPhoneNumber,
                touchEmail: `${touchEmail}@eleganceagents.com`, // Add email domain to the end
                postcardTestimonial,
              },
            });

            // Call resetForm to set the dirty var to false (used for SaveButton to detect changes again). Also
            // pass in the current values here to use that as the new initial state when resetting.
            resetForm({ values });

            // Check for "&" or "and" in the name fields, which usually indicates that 2 or more
            // agents are using a single account. Let them know that they should check the grammar
            // and verbiage for their touches.

            const checkPossibleTeam = (s) => s.includes('&') || s.toLowerCase().includes('and');
            if (checkPossibleTeam(firstName) || checkPossibleTeam(lastName)) {
              setShowMultiAgentNotice(true);
            }
          } catch (err) {
            setStatus(ESaveButtonFormikStatus.Error);
            console.error('error', err);
            showAPIErrorAlert(setCurrentAlert, err);
          }
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().required().matches(nameRegExp),
          lastName: Yup.string(),
          phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
          // slogan: Yup.string(),
          website: Yup.string().url().required().matches(urlRegExp),
          agentDreNumber: Yup.string().required(),
          marketingEmail: Yup.string().email().required(),
          marketingPhoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
          touchEmail: Yup.string().required('Your touch email is not valid'),
          postcardTestimonial: Yup.string().max(400),
        })}
      >
        {(props2) => {
          const {
            touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values, validateForm,
          } = props2;

          // Validate to show errors for new users
          React.useEffect(() => { validateForm(); }, []);

          return (
            <Form>
              <FormikOnError>
                <GridContainer justifyContent="center">
                  <GridItem xs={12} sm={10} lg={9} xl={7}>
                    <GridContainer justifyContent="center" className={classes.topMargin}>

                      <GridItem xs={12} md={6}>
                        <Field
                          name="firstName"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="First Name"
                              inputProps={{
                                ...field,
                                autoComplete: 'given-name',
                                placeholder: 'Enter name',
                                maxLength: 50,
                              }}
                              error={errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <Field
                          name="lastName"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="Last Name"
                              inputProps={{
                                ...field,
                                autoComplete: 'family-name',
                                placeholder: 'Enter name',
                                maxLength: 50,
                              }}
                              error={errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>

                      <GridItem xs={12} md={6}>
                        <Field
                          name="email"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="Email Address"
                              inputProps={{
                                ...field,
                                disabled: true, // Users can't edit their emails
                                autoComplete: 'email',
                              }}
                              error={errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <Field
                          name="phoneNumber"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="Phone Number (for text notifications)"
                              inputProps={{
                                ...field,
                                type: 'tel',
                                autoComplete: 'tel',
                                endAdornment: <InfoOutlinedIcon />,
                              }}
                              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                              error={errors[field.name] !== undefined}
                              tooltip="Used for internal communication, like Harvist account and campaign updates"
                            />
                          )}
                        </Field>
                      </GridItem>

                      {/* <GridItem xs={12} md={6}>
                        <Field
                          name="slogan"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="Your Slogan (Optional)"
                              inputProps={{
                                ...field,
                                placeholder: 'Knock knock',
                              }}
                              error={errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem> */}
                      <GridItem xs={12} md={6}>
                        <Field
                          name="website"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="Your Website"
                              inputProps={{
                                ...field,
                              }}
                              mask={(rawValue) => (['h', 't', 't', 'p', 's', ':', '/', '/', ...rawValue.split('').map(() => /./)])}
                              error={errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>

                      <GridItem xs={12} md={6}>
                        <Field
                          name="agentDreNumber"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="Your License Number"
                              inputProps={{
                                ...field,
                                placeholder: '#',
                              }}
                              error={errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>

                      <GridItem xs={12} md={6}>
                        <Field
                          name="marketingEmail"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="Marketing Email"
                              inputProps={{
                                ...field,
                              }}
                              error={errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>
                      <GridItem xs={12} md={6}>
                        <Field
                          name="marketingPhoneNumber"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="Marketing Phone Number"
                              inputProps={{
                                ...field,
                                type: 'tel',
                                autoComplete: 'tel',
                                endAdornment: <InfoOutlinedIcon />,
                              }}
                              mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                              tooltip="This phone number will be displayed on your marketing materials and used as the caller ID for voicemails on your behalf"
                              error={errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem>

                      <GridItem xs={12}>
                        <Field
                          name="touchEmail"
                        >
                          {({ field }) => (
                            <>
                              <CustomInputDashboard
                                labelText="Touch Email"
                                inputProps={{
                                  ...field,
                                  endAdornment: (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                      <span style={{ marginRight: '6px', fontWeight: 500 }}>@eleganceagents.com</span>
                                      <InfoOutlinedIcon />
                                    </div>
                                  ),
                                  value: field.value.replaceAll('@eleganceagents.com', ''),
                                }}
                                tooltip="This is the from address used when sending touch emails to your farm. It must end in '@eleganceagents.com'."
                                error={errors[field.name] !== undefined}
                              />
                            </>
                          )}
                        </Field>
                      </GridItem>

                      {/* NOTE: To square everything up */}
                      <GridItem xs={12} md={6} />

                      {/* TODO: Disabled for now on the frontend since its not very polished and doesn't support merge variables intuitively */}
                      {/* <GridItem xs={12}>
                        <Field
                          name="postcardTestimonial"
                        >
                          {({ field }) => (
                            <CustomInputDashboard
                              labelText="Postcard Testimonial"
                              inputProps={{
                                ...field,
                                multiline: true,
                                rows: 5,
                                endAdornment: <InfoOutlinedIcon />,
                              }}
                              helperText={(
                                <span>
                                  {`${values[field.name].length} / 400 characters`}
                                </span>
                              )}
                              tooltip="This will be displayed on some postcards as a customer testimonial. We will use a default one if one is not provided."
                              error={errors[field.name] !== undefined}
                            />
                          )}
                        </Field>
                      </GridItem> */}

                      <GridItem xs={12}>
                        <div className={classes.saveButtonContainer}>
                          <SaveButton onSave={reloadUserInfo} />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </FormikOnError>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default React.forwardRef(PersonalDataForm);
