const style = (theme) => ({
  root: {
    width: '100%',
    borderRadius: '13px',
    padding: '12px 24px',

    '& > p': {
      fontStyle: 'italic',
    },
  },
  profile: {
    marginTop: '14px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '& > img': {
      width: '32px',
      height: '32px',
      marginRight: '8px',
    },
  },
});

export default style;
