import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';

const useStyles = makeStyles((theme) => ({
  alert: {
    textAlign: 'left',
  },
}));

// TODO: TEXT COLOR ON ALERTS. THEME OVERRIDE AND CHANGE TO BLACK???
const SimpleAlert = ({ severity, message, autoHideDuration }) => {
  const classes = useStyles();
  const { clearAlert } = useAlertContext();

  // Don't close the alert if they clicked away from the snackbar. For example,
  // if I get an error on one page and then click to go to another page, I should
  // still see the alert on the next page, it shouldn't close it since I clicked away.
  const snackbarOnClose = (event, reason) => {
    if (reason !== 'clickaway') {
      clearAlert();
    }
  };

  const iconMapping = {
    error: <ErrorRoundedIcon fontSize="inherit" />,
    info: <InfoRoundedIcon fontSize="inherit" />,
    warning: <WarningRoundedIcon fontSize="inherit" />,
    success: <CheckCircleRoundedIcon fontSize="inherit" />,
  };

  // TODO: Can have an edge case where we create one alert, then create another before the first alert
  //       "expires", but the first alert expiring will remove the second alert too since we only ever
  //       store the most recent "current alert".
  return (
    <Snackbar open autoHideDuration={autoHideDuration} onClose={snackbarOnClose}>
      <MuiAlert
        iconMapping={iconMapping}
        elevation={4}
        variant="filled"
        severity={severity}
        onClose={clearAlert}
        className={classes.alert}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default SimpleAlert;
