import React from 'react';

import { useTheme } from '@material-ui/core/styles';

import ProductLayout2 from 'components/Product/ProductLayout2';

import heroImage from 'assets/img/products/new/hero/full-farm-hero.png';

const FullFarmPage = () => {
  const theme = useTheme();

  return (
    <ProductLayout2
      eventTypeName="Full Farm"
      heroImage={heroImage}
      heroColorVariant="gray"
      productTermsBgColor={theme.palette.gray.main}
      section1BgVariant="offWhite"
      section3BgVariant="darkGray"
    />
  );
};

export default FullFarmPage;
