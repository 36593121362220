const style = (theme) => ({
  root: {
    position: 'relative',

    [theme.breakpoints.up('xl')]: {
      // width: '651px',
      // height: '868px',
    },

    '& > h2': {
      color: theme.palette.darkGray.main,
    },

    '& > h2 > span': {
      marginLeft: '20px',
      color: theme.palette.gray.main,
      fontWeight: 'normal',
      paddingBottom: '9px',
      boxShadow: `0px -6px 0px -1px ${theme.palette.orange.main} inset`, // -1px on the spread because of it leaking onto the vertical

      [theme.breakpoints.down('sm')]: {
        boxShadow: `0px -4px 0px -1px ${theme.palette.orange.main} inset`, // -1px on the spread because of it leaking onto the vertical
      },
      [theme.breakpoints.only('xs')]: {
        marginLeft: 0,
        lineHeight: '40px',
      },
    },
  },
  descriptionText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      display: 'inherit',
    },

    '& span': {
      width: '50px',
    },
    '& p': {
      flex: 1,
      fontSize: '16px',
      lineHeight: '150%',
      color: theme.palette.gray.main,
    },
  },
  temp: {
    height: '118px',
  },
});

export default style;
