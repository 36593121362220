import React from 'react';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import * as Yup from 'yup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';
import Card from 'creative-components/Card/Card';
import CardHeader from 'creative-components/Card/CardHeader';
import CardIcon from 'creative-components/Card/CardIcon';
import CardBody from 'creative-components/Card/CardBody';
import Button from 'creative-components/CustomButtons/Button';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import FeedbackQuestion from 'components/Feedback/FeedbackQuestion';
import FeedbackResponseInput from 'components/Feedback/FeedbackResponseInput';
import { longTextResponseCharacterLimit } from 'components/Feedback/ResponseType/FeedbackResponseLongText';

import { getFeedbackTopics, getQuestionsForFeedbackTopicType, submitFeedback } from 'utils/api';

import styles from 'assets/jss/material-dashboard-pro-react/views/feedbackPageStyle';

const useStyles = makeStyles(styles);

const FeedbackPage = () => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);

  const [feedbackTopics, setFeedbackTopics] = React.useState(null);
  const [topicQuestions, setTopicQuestions] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      const { topics } = await getFeedbackTopics();

      setFeedbackTopics(topics);
    })();
  }, []);

  let render;

  if (feedbackSubmitted) {
    render = (
      <h4 className={classes.feedbackSubmittedText}>
        Thank you for taking the time to send us feedback!
      </h4>
    );
  } else if (feedbackTopics && feedbackTopics.length > 0) {
    render = (
      <Formik
        validateOnChange={false}
        initialValues={{
          topicSelectionIndex: -1,
          responses: [],
        }}
        onSubmit={async ({ topicSelectionIndex, responses }, { setFieldValue }) => {
          try {
            const topicSelected = feedbackTopics[topicSelectionIndex];

            if (!topicQuestions) {
              showLoadingIndicatorModal();

              const { questions } = await getQuestionsForFeedbackTopicType(topicSelected.type);

              // Initalizes the responses with arrays of empty strings, and pass false to skip validation to not show errors initially
              setFieldValue('responses', questions.map(({ responseTypes }) => responseTypes.map(() => '')), false);
              setTopicQuestions(questions);

              return;
            }

            showLoadingIndicatorModal();

            await submitFeedback(topicSelected.type, topicSelected.name, topicSelected.metadata, topicQuestions, responses);

            setFeedbackSubmitted(true);

            setCurrentAlert('success', 'Your feedback has been submitted.');
          } catch (error) {
            if (error.response && error.response.data.error) {
              setCurrentAlert('error', error.response.data.error);
            } else {
              setCurrentAlert('error', 'Something went wrong, please try again.');
            }

            console.error('error', error);
          } finally {
            hideLoadingIndicatorModal();
          }
        }}
        validationSchema={Yup.object().shape({
          topicSelectionIndex: Yup.number().required(),
          responses: Yup.array().of(Yup.array().of(Yup.string().max(longTextResponseCharacterLimit))), // Responses are optional
        })}
      >
        {(props) => {
          const {
            touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
          } = props;

          return (
            <Form>
              <FormikOnError>
                {isSubmitting ? <LoadingIndicator modal /> : null}
                <GridContainer justifyContent="center">
                  <GridItem lg={2} xl={3} />
                  <GridItem xs={12} lg={8} xl={6}>
                    {!topicQuestions ? (
                      <Field
                        name="topicSelectionIndex"
                      >
                        {({ field }) => (
                          <>
                            <h4 className={classes.topicTitle}>
                              Choose a topic:
                            </h4>
                            {feedbackTopics.map((topic, index) => (
                              <React.Fragment key={index}>
                                <FormControlLabel
                                  control={(
                                    <Radio
                                      {...field}
                                      checked={field.value === index}
                                      onChange={() => setFieldValue(field.name, index, true)}
                                      icon={(
                                        <FiberManualRecordIcon
                                          className={classes.radioUnchecked}
                                        />
                                      )}
                                      checkedIcon={(
                                        <FiberManualRecordIcon
                                          className={classes.radioChecked}
                                        />
                                       )}
                                      classes={{
                                        checked: classes.radio,
                                        root: classes.radioRoot,
                                      }}
                                    />
                                  )}
                                  classes={{
                                    label: classes.label,
                                    root: classes.labelRoot,
                                  }}
                                  label={topic.name}
                                />
                                <br />
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      </Field>
                    ) : (
                      <FieldArray
                        name="responses"
                        render={(arrayHelpers) => (
                          <>
                            <h3 className={classes.topicTitle}>
                              {feedbackTopics[values.topicSelectionIndex].name}
                            </h3>
                            <h4 className={classes.instructionsText}>
                              Please provide responses for the following:
                            </h4>
                            {topicQuestions.map(({ question, responseTypes }, index) => (
                              <React.Fragment key={index}>
                                <FeedbackQuestion question={question} />
                                <FeedbackResponseInput
                                  responseIndex={index}
                                  responseTypes={responseTypes}
                                />
                              </React.Fragment>
                            ))}
                          </>
                        )}
                      />
                    )}
                  </GridItem>
                  <GridItem lg={2} xl={3} />
                  <GridItem xs={12} lg={8} xl={6} className={classes.buttonContainer}>
                    {topicQuestions && (
                      <Button
                        className={classes.backButton}
                        color="primary"
                        onClick={() => {
                          setTopicQuestions(null);
                          setFieldValue('responses', [], false);
                        }}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      className={classes.nextButton}
                      color="info"
                      disabled={!topicQuestions
                        ? values.topicSelectionIndex === -1
                        : isSubmitting}
                      // : !isValid || !dirty || isSubmitting}
                      onClick={handleSubmit}
                    >
                      {!topicQuestions
                        ? 'Next'
                        : 'Submit'}
                    </Button>
                  </GridItem>
                </GridContainer>
              </FormikOnError>
            </Form>
          );
        }}
      </Formik>
    );
  } else if (feedbackTopics) {
    render = (<h4 className={classes.noTopicsText}>Sorry, there are no topics to submit feedback for at this time.</h4>);
  } else {
    render = (<LoadingIndicator />);
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <FeedbackIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Share Your Feedback
              </h4>
            </CardHeader>
            <CardBody>
              {render}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default FeedbackPage;
