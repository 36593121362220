import React from 'react';

import { SvgIcon, useTheme } from '@material-ui/core';

const PostcardIcon = (props) => {
  const theme = useTheme();

  return (
    <SvgIcon width="50" height="50" viewBox="0 0 50 50" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M10 8H40C41.5913 8 43.1174 8.59702 44.2426 9.65973C45.3679 10.7224 46 12.1638 46 13.6667V36.3333C46 37.8362 45.3679 39.2776 44.2426 40.3403C43.1174 41.403 41.5913 42 40 42H10C8.4087 42 6.88258 41.403 5.75736 40.3403C4.63214 39.2776 4 37.8362 4 36.3333V13.6667C4 12.1638 4.63214 10.7224 5.75736 9.65973C6.88258 8.59702 8.4087 8 10 8Z" fill={theme.palette.orange.main} />
      <path d="M34.3333 14H37.6667C38.2855 14 38.879 14.2458 39.3166 14.6834C39.7542 15.121 40 15.7145 40 16.3333V19.6667C40 20.2855 39.7542 20.879 39.3166 21.3166C38.879 21.7542 38.2855 22 37.6667 22H34.3333C33.7145 22 33.121 21.7542 32.6834 21.3166C32.2458 20.879 32 20.2855 32 19.6667V16.3333C32 15.7145 32.2458 15.121 32.6834 14.6834C33.121 14.2458 33.7145 14 34.3333 14Z" fill="white" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 27.6155H25" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11 34.6155H25" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export default PostcardIcon;
