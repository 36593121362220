import { infoColor } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  responseBase: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  toggleButton: {
    marginRight: '24px',
    textTransform: 'none',
  },
  activeToggleButton: {

  },
  shortInputContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
    },
  },
  longInputContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
    },
  },
});

export default style;
