import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from 'creative-components/CustomButtons/Button';
import Card from 'creative-components/Card/Card';
import CardBody from 'creative-components/Card/CardBody';
import CardIcon from 'creative-components/Card/CardIcon';
import CardHeader from 'creative-components/Card/CardHeader';
import Table from 'creative-components/Table/Table';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import {
  cardTitle, successColor, dangerColor,
} from 'assets/jss/material-dashboard-pro-react';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const useStyles = makeStyles(styles);

export default function CRMImportsTable({ agentCustomerImports }) {
  const classes = useStyles();

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (!agentCustomerImports) return;

    setData(agentCustomerImports.map((agentCustomerImport) => {
      let status;

      switch (agentCustomerImport.status) {
        case 'IMPORTING':
          status = (
            <CircularProgress color="primary" />
          );
          break;
        case 'ERRORED':
          status = (
            <ErrorIcon htmlColor={dangerColor[0]} />
          );
          break;
        case 'COMPLETED':
          status = (
            <DoneIcon htmlColor={successColor[0]} />
          );
          break;
        default:
          status = agentCustomerImport.status;
          break;
      }

      return [
        moment(agentCustomerImport.createdAt).format('MMMM D, YYYY, h:mm A'),
        status,
        agentCustomerImport.numberOfRows,
        agentCustomerImport.numberOfRowsImported,
        agentCustomerImport.numberOfRowsDuplicate,
        // moment(agentCustomerImport.updatedAt).format('MMMM D, YYYY, h:mm A'),
      ];
    }));
  }, [agentCustomerImports]);

  return (
    <>
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <ImportExportIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Your Contact Imports</h4>
        </CardHeader>
        <CardBody>
          {agentCustomerImports !== null
            ? (
              <Table
                tableHeaderColor="primary"
                // tableHead={['Import Started', 'Status', 'Total # Rows', '# Rows Imported', '# Rows Duplicate', 'Last Updated At']}
                tableHead={['Import Started', 'Status', 'Total # Rows', '# Rows Imported', '# Rows Duplicate']}
                tableData={data}
              />
            ) : (<LoadingIndicator />)}
        </CardBody>
      </Card>
    </>
  );
}
