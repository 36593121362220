import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CreateIcon from '@material-ui/icons/Create';
import GroupIcon from '@material-ui/icons/Group';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';
import Card from 'creative-components/Card/Card';
import CardHeader from 'creative-components/Card/CardHeader';
import CardIcon from 'creative-components/Card/CardIcon';
import CardBody from 'creative-components/Card/CardBody';
import Button from 'creative-components/CustomButtons/Button';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import EditAgentCustomerModal from 'components/CRMTable/EditAgentCustomerModal';
import CRMTable from 'components/CRMTable/CRMTable';
import CRMImportsTable from 'components/CRMImportsTable/CRMImportsTable';
import CRMImportModal from 'components/CRMImportModal/CRMImportModal';
import ProductInfo from 'components/ProductInfo/ProductInfo';

import { subscribeCrmPro, getAgentCustomers, getAgentCustomerImports } from 'utils/api';

import styles from 'assets/jss/material-dashboard-pro-react/views/crmProStyle';

const useStyles = makeStyles(styles);

const SignupCRMPro = () => {
  const classes = useStyles();
  const { reloadUserInfo } = useUserInfoContext();
  const { setCurrentAlert } = useAlertContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [imageOpen, setImageOpen] = React.useState(null);

  const subscribe = async () => {
    showLoadingIndicatorModal();

    try {
      await subscribeCrmPro({});
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Fake pause for no loading indicator flicker

      reloadUserInfo();

      setCurrentAlert('success', 'You have successfully subscribed and now have access to CRM Pro!');
    } catch (error) {
      console.error('error', error);

      setCurrentAlert('error', 'An error occurred, please report this issue!');
    }

    hideLoadingIndicatorModal();
  };

  return (
    <div>
      {imageOpen ? (
        <Backdrop className={classes.backdropContainer} open onClick={() => setImageOpen(null)}>
          <img src={imageOpen} alt="" className={classes.backdropImage} />
        </Backdrop>
      ) : null}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <GroupIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Subscribe to Access CRM Pro!
              </h4>
            </CardHeader>
            <CardBody>
              {/* <h5>
                Click the "Schedule Onboarding" button below to apply to use this product. Remember, you'll need to finish the commitment to any active campaign
                although you may run campaigns with additional products simultaneously.
                </h5>
                <br />
                <br />
                <br />
              <br /> */}
              <br />
              <ProductInfo product="CRM Pro" showGetStartedButton={false} setImageOpen={setImageOpen} />
              <div className={classes.center}>
                <br />
                <br />
                <Button
                  color="info"
                  onClick={subscribe}
                >
                  Subscribe
                </Button>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

// eslint-disable-next-line import/no-mutable-exports
const CRMProPage = () => {
  const classes = useStyles();
  const { userInfo } = useUserInfoContext();

  const [agentCustomers, setAgentCustomers] = React.useState(null);

  const [forceImportsRefresh, setForceImportsRefresh] = React.useState(null); // Used to refresh the agent customer imports
  const [agentCustomerImports, setAgentCustomerImports] = React.useState(null);

  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  const [importModal, setImportModal] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (!userInfo || !userInfo.agentInfo.isCrmProEnabled) return;

      const { agentCustomers: agentCustomersReturned } = await getAgentCustomers();
      setAgentCustomers(agentCustomersReturned);
    })();
  }, [userInfo]);

  React.useEffect(() => {
    (async () => {
      if (!userInfo || !userInfo.agentInfo.isCrmProEnabled) return;

      const { agentCustomerImports: agentCustomerImportsReturned } = await getAgentCustomerImports();
      setAgentCustomerImports(agentCustomerImportsReturned);
    })();
  }, [forceImportsRefresh, userInfo]);

  if (!userInfo) {
    return (<LoadingIndicator />);
  }

  // If they don't have CRM Pro enabled (they're not subscribed to the product), show them a signup page
  if (!userInfo.agentInfo.isCrmProEnabled) {
    return (<SignupCRMPro />);
  }

  return (
    <div>
      {isCreateModalOpen ? (
        <EditAgentCustomerModal
          addAgentCustomer={(newAgentCustomer) => setAgentCustomers([...agentCustomers, newAgentCustomer])}
          onCancel={() => setIsCreateModalOpen(false)}
        />
      ) : null}
      {importModal ? (
        <CRMImportModal
          onClose={() => setImportModal(false)}
          setForceImportsRefresh={() => setForceImportsRefresh(Date.now())}
        />
      ) : null}
      <GridContainer>
        <GridItem xs={12}>
          <div>
            {/* <input
              type="file"
              accept="text/csv"
              onChange={handleCsvChange}
              ref={fileInput}
              style={{ display: 'none' }}
            />
            <Button
              color="primary"
              className={classes.importAgentCustomersButton}
              onClick={() => fileInput.current.click()}
            >
              <CreateIcon />
              {' '}
              Import Contacts
            </Button> */}
            <Button
              color="warning"
              className={classes.importAgentCustomersButton}
              onClick={() => setImportModal(true)}
            >
              <CreateIcon />
              {' '}
              Import Contacts
            </Button>
          </div>
          <Button
            color="info"
            className={classes.createAgentCustomerButton}
            onClick={() => setIsCreateModalOpen(true)}
          >
            <CreateIcon />
            {' '}
            Add New Contact
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <CRMTable
            agentCustomers={agentCustomers}
            setAgentCustomers={setAgentCustomers}
          />
        </GridItem>
        <GridItem xs={12}>
          <CRMImportsTable
            agentCustomerImports={agentCustomerImports}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

// // Disable CRM Pro for prod until release
// if (process.env.REACT_APP_BACKEND_URL !== 'https://axwell.harvist.com' && process.env.REACT_APP_BACKEND_URL !== 'http://localhost:4000') {
//   CRMProPage = () => {
//     const classes = useStyles();

//     return (
//       <div>
//         <GridContainer>
//           <GridItem xs={12}>
//             <Card>
//               <CardBody>
//                 <h3>Coming Soon!</h3>
//               </CardBody>
//             </Card>
//           </GridItem>
//         </GridContainer>
//       </div>
//     );
//   };
// }

export default CRMProPage;
