import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';

import Button from 'creative-components/CustomButtons/Button';

import styles from 'assets/jss/material-dashboard-pro-react/components/horizontalTimelineModalStyle';

const useStyles = makeStyles(styles);

const HorizontalTimelineModal = ({ story, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open
      scroll="body"
      maxWidth="sm"
      keepMounted
      onClose={onClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <CloseIcon className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{story.title}</h4>
      </DialogTitle>
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <div className={classes.storyBody}>
          {story.body}
        </div>
        {story.footer ? (
          <>
            <hr className={classes.footerLine} />
            <div>
              {story.footer}
            </div>
          </>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default HorizontalTimelineModal;
