import React from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import styles from 'assets/jss/material-dashboard-pro-react/components/feedbackResponseInputStyle';

const useStyles = makeStyles(styles);

const FeedbackResponseToggleButtons = ({
  responseIndex, responseTypesArrayHelpers, responseTypeIndex, availableOptions,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <>
      {availableOptions.map((option) => {
        const isOptionSelected = values.responses[responseIndex][responseTypeIndex] === option;

        return (
          <Button
            key={option}
            className={classNames({
              [classes.toggleButton]: true,
              [classes.activeToggleButton]: isOptionSelected,
            })}
            round
            color={isOptionSelected ? 'primary' : 'gray'}
            onClick={() => responseTypesArrayHelpers.replace(responseTypeIndex, option)}
          >
            {option}
          </Button>
        );
      })}
    </>
  );
};

export default FeedbackResponseToggleButtons;
