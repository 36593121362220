import React from 'react';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/ourMissionStatementStyle';

const useStyles = makeStyles(styles);

// TODO: For typescript: showBackgroundImage: bool
const OurMissionStatement = ({ showBackgroundImage }) => {
  const classes = useStyles();

  return (
    <div className={cx({
      [classes.root]: true,
      [classes.rootBgImage]: showBackgroundImage,
    })}
    >
      {showBackgroundImage && (
        <div className={classes.rootBgImageOverlay} />
      )}
      <GridContainer className={classes.container}>
        <GridItem xs={12}>
          <h2>Our Mission</h2>
          <p>
            To create an automated farming outreach platform that provides you with more opportunity, effective results and less stress.
          </p>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default OurMissionStatement;
