import { container } from 'assets/jss/material-dashboard-pro-react';

import heroImage from 'assets/img/close-like-a-pro/hero.png';

const style = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  container: {
    ...container,
    position: 'relative',
  },
  hero: {
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  },
  heroOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.primary.main,
    mixBlendMode: 'soft-light',
  },

  left: {
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '13px',
    marginTop: '457px',
    maxWidth: '493px',

    [theme.breakpoints.up('xl')]: {
      marginLeft: '84px',
    },
    '& p': {
      padding: '28px',
      color: theme.palette.darkGray.main,
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '30px',
    },
  },
  right: {
    marginBottom: '92px',

    [theme.breakpoints.up('xl')]: {
      marginRight: '74px',
    },
  },
  rightHeader: {
    textAlign: 'center',
    margin: '74px 0 31px',

    [theme.breakpoints.down('sm')]: {
      margin: '32px 0 25px',
    },

    '& h1': {
      color: theme.palette.black.main,

      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
      [theme.breakpoints.only('md')]: {
        fontSize: '36px',
      },
      [theme.breakpoints.only('lg')]: {
        fontSize: '42px',
      },
    },
    '& p': {
      color: theme.palette.black.main,
      margin: '10px 0 10px',
      fontWeight: '500',
      fontSize: '22px',
      lineHeight: '26px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '30px',
      },
    },
    '& div': {
      border: '3px solid #FFFFFF',
      width: '159px',
      height: 0,
      margin: 'auto',

      [theme.breakpoints.down('sm')]: {
        border: '2px solid #FFFFFF',
        width: '116px',
      },
    },
  },
});

export default style;
