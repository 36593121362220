const style = (theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '20px',
  },
  expansionPanel: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:before': {
      display: 'none !important',
    },
  },
  expansionPanelExpanded: {
    margin: '0 !important',
  },
  expansionPanelSummary: (props) => ({
    minHeight: 'auto !important',
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.palette.grayScale6.main}`,
    padding: '25px 10px 5px 0px',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    color: props.color === 'darkGray' ? theme.palette.darkGray.main : theme.palette.white.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }),
  expansionPanelSummaryExpanded: {
    color: theme.palette.primary.main,
    '& $expansionPanelSummaryExpandIcon': {
      [theme.breakpoints.up('md')]: {
        top: 'auto !important',
      },
      transform: 'rotate(180deg)',
      [theme.breakpoints.down('sm')]: {
        top: '10px !important',
      },
    },
  },
  expansionPanelSummaryContent: {
    margin: '0 38px 0 0 !important',
  },
  expansionPanelSummaryExpandIcon: {
    [theme.breakpoints.up('md')]: {
      top: 'auto !important',
    },
    transform: 'rotate(0deg)',
    color: 'inherit',
    position: 'absolute',
    right: '20px',
    [theme.breakpoints.down('sm')]: {
      top: '10px !important',
    },
  },
  expansionPanelSummaryExpandIconExpanded: {},
  title: {
    fontFamily: 'Roboto',
    fontSize: '19px',
    lineHeight: '20px',
    fontWeight: 'bold',
    marginTop: '0',
    marginBottom: '15px',
    color: 'inherit',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  expansionPanelDetails: (props) => ({
    padding: 0,
    fontSize: '14px',
    lineHeight: '21px',
    color: props.color === 'darkGray' ? theme.palette.darkGray.main : theme.palette.white.main,

    '& > p': {
      margin: '20px 0 25px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        lineHeight: '20px',
      },
    },
  }),
});

export default style;
