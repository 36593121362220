import { infoColor, cardTitle, warningColor } from 'assets/jss/material-dashboard-pro-react';

import customCheckboxRadioSwitchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

const style = (theme) => ({
  ...customCheckboxRadioSwitchStyles(theme),
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  info: {
    marginBottom: '18px',
  },
  label: {
    ...customCheckboxRadioSwitchStyles(theme).label,
    color: '#000000',
  },
  backButton: {
    float: 'left',
  },
  nextButton: {
    float: 'right',
  },
  topicTitle: {
    marginBottom: '20px',
  },
  instructionsText: {
    marginBottom: '32px',
    color: warningColor[0],
    fontWeight: '400',
  },
  noTopicsText: {
    textAlign: 'center',
  },
  feedbackSubmittedText: {
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: '100px',
  },
});

export default style;
