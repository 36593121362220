import React from 'react';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/testimonialsStyle.js';

import jenAvatar from 'assets/img/testimonials/jen.png';
import { useInterval } from 'utils/hooks';

const useStyles = makeStyles(styles);

const carouselInterval = 5000;

const testimonials = [
  {
    name: 'Jen',
    company: 'Acme Inc',
    avatar: jenAvatar,
    text: 'This is crazy!!!',
  },
  {
    name: 'Jimmy',
    company: 'Acme Inc',
    avatar: jenAvatar,
    text: 'Ever since I started using this platform, my numbers were crazy.',
  },
  {
    name: 'John',
    company: 'Acme Inc',
    avatar: jenAvatar,
    text: 'Ever since I started using this platform, my numbers went through the f’n roof!',
  },
  {
    name: 'Jaquisha',
    company: 'Acme Inc',
    avatar: jenAvatar,
    text: 'My numbers went so high that I had to start rejecting leads!',
  },
];

// TODO: ANIMATION???
const Testimonials = () => {
  const classes = useStyles();

  const [index, setIndex] = React.useState(0);

  useInterval(() => {
    setIndex((index + 1) % testimonials.length);
  }, carouselInterval);

  const dots = [];

  for (let i = 0; i < testimonials.length; i++) {
    dots.push(<div
      key={i}
      className={classNames({
        [classes.dot]: true,
        [classes.activeDot]: index === i,
      })}
    />);
  }

  const {
    name, company, avatar, text,
  } = testimonials[index];

  return (
    <div className={classes.root}>
      <GridContainer className={classes.container} alignItems="center">
        <GridItem xs={3} md={4} className={classes.left}>
          <svg viewBox="0 0 290 321" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.avatarImage}>
            <path d="M263.751 259.971C279.302 251.6 289 235.366 289 217.705V125.549C289 107.888 279.302 91.6537 263.751 83.2829L173.124 34.5001C158.92 26.8545 141.826 26.8545 127.622 34.5001L36.9954 83.2829C21.4445 91.6537 11.7463 107.888 11.7463 125.549V217.705C11.7463 235.366 21.4445 251.6 36.9954 259.971L127.622 308.754C141.826 316.399 158.92 316.399 173.124 308.754L263.751 259.971Z" fill="#F04528" stroke="#F80B46" strokeWidth="2" strokeMiterlimit="10" />
            <mask id="mask0_1040:15552" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="6" width="279" height="288">
              <path d="M253.005 238.478C268.556 230.108 278.254 213.873 278.254 196.213V104.056C278.254 86.3954 268.556 70.1612 253.005 61.7904L162.378 13.0076C148.174 5.36199 131.08 5.362 116.876 13.0076L26.2491 61.7904C10.6981 70.1612 1 86.3954 1 104.056V196.213C1 213.873 10.6981 230.108 26.2491 238.478L116.876 287.261C131.08 294.907 148.174 294.907 162.378 287.261L253.005 238.478Z" fill="#C4C4C4" stroke="#F80B46" strokeMiterlimit="10" />
            </mask>
            <g mask="url(#mask0_1040:15552)">
              <path d="M253.005 238.478C268.556 230.108 278.254 213.873 278.254 196.213V104.056C278.254 86.3954 268.556 70.1612 253.005 61.7904L162.378 13.0076C148.174 5.36199 131.08 5.362 116.876 13.0076L26.2491 61.7904C10.6981 70.1612 1 86.3954 1 104.056V196.213C1 213.873 10.6981 230.108 26.2491 238.478L116.876 287.261C131.08 294.907 148.174 294.907 162.378 287.261L253.005 238.478Z" stroke="#F80B46" strokeWidth="2" strokeMiterlimit="10" />
            </g>
            <clipPath id="avatar-clip">
              <path d="M253.005 238.478C268.556 230.108 278.254 213.873 278.254 196.213V104.056C278.254 86.3954 268.556 70.1612 253.005 61.7904L162.378 13.0076C148.174 5.36199 131.08 5.362 116.876 13.0076L26.2491 61.7904C10.6981 70.1612 1 86.3954 1 104.056V196.213C1 213.873 10.6981 230.108 26.2491 238.478L116.876 287.261C131.08 294.907 148.174 294.907 162.378 287.261L253.005 238.478Z" />
            </clipPath>
            <image href={avatar} clipPath="url(#avatar-clip)" width="279" height="288" preserveAspectRatio="xMinYMin slice" />
          </svg>
        </GridItem>
        <GridItem xs={9} md={8} className={classes.right}>
          <div className={classes.textContainer}>
            <p className={classes.subtitle}>
              Trusted by the World’s Best Agents
            </p>
            <p className={classes.text}>
              {`“${text}”`}
            </p>
            <p className={classes.bio}>
              {`${name}, ${company}`}
            </p>
            <div className={classes.dots}>
              {dots}
            </div>
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Testimonials;
