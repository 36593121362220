import { container, hexToRgb } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  container: {
    ...container,
    position: 'relative',
  },

  hero: {
    position: 'relative',
  },
  heroBackground: {
    position: 'absolute',
    top: '-96px', // Home navbar is 96px in height
    left: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('sm')]: {
      bottom: '57px',
    },
    [theme.breakpoints.only('md')]: {
      bottom: '70px',
    },
    [theme.breakpoints.up('lg')]: {
      bottom: '116px',
    },

    '& div': {
      height: '100%',
      width: '100%',
    },
  },
  headerTextContainer: {
    [theme.breakpoints.only('md')]: {
      marginBottom: '50px',
    },

    '& > h1 > span': {
      display: 'inline-block',
      width: '30px',
      height: '0px',
      border: `3px solid ${theme.palette.orange.main}`,
      marginLeft: '14px',
    },

    '& > a': {
      color: `${theme.palette.white.main} !important`,
    },

    '& > h1': {
      [theme.breakpoints.down('sm')]: {
        margin: '22px 0 0',
        fontSize: '28px',
        lineHeight: '33px',
      },
      [theme.breakpoints.only('md')]: {
        margin: '70px 0 0',
        fontSize: '40px',
      },
      [theme.breakpoints.up('lg')]: {
        margin: '70px 0 0',
      },
    },

    '& > p': {
      [theme.breakpoints.only('md')]: {
        maxWidth: '561px',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '561px',
      },
    },
  },
  heroText1: {
    color: theme.palette.white.main,
    fontSize: '24px',
    lineHeight: '140%',
    marginTop: '18px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  heroText2: {
    color: theme.palette.white.main,
    fontSize: '16px',
    lineHeight: '140%',
    marginTop: '36px',
    marginBottom: '43px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  headerVideoContainer: {
    border: `2px solid rgba(${hexToRgb(theme.palette.primary.main)}, 0.66)`,
    borderRadius: '16px',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      margin: '36px 0 16px',
      height: '160px',
    },
    [theme.breakpoints.only('md')]: {
      margin: '80px 0 130px',
      height: '230px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '20px',
      height: '374px',
    },

    '& > iframe': {
      borderRadius: '16px',

      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  headerProductInfo: {
    [theme.breakpoints.up('md')]: {
      marginTop: '23px',
    },
  },

  section1: {
    backgroundSize: '100vw 100%',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      backgroundPosition: '25%',
      marginTop: '-110px',
      padding: '130px 0 83px',
    },
    [theme.breakpoints.only('md')]: {
      backgroundSize: 'cover',
      marginTop: '-110px',
      padding: '130px 0 160px',
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '-110px',
      padding: '130px 0 200px',
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '-110px',
      padding: '130px 0 90px',
    },
  },
  section1TextColorBgGray: {
    '& > h2': {
      color: theme.palette.white.main,
    },
    '& > h2 > span': {
      border: `3px solid ${theme.palette.primary.main}`,
    },
    '& > p': {
      color: theme.palette.white.main,
    },
  },
  section1TextColorBgOffWhite: {
    '& > h2': {
      color: theme.palette.darkGray.main,
    },
    '& h2 span': {
      border: `3px solid ${theme.palette.orange.main}`,
    },
    '& p': {
      color: theme.palette.darkGray.main,
    },
  },

  section1TextContainer: {
    '& > h2 > span': {
      display: 'inline-block',
      width: '281px',
      height: '0px',
      marginLeft: '28px',
    },
    '& > p': {
      fontSize: '24px',
      lineHeight: '150%',
    },

    [theme.breakpoints.down('sm')]: {
      '& h2': {
        margin: '30px 0 0',
        fontSize: '22px',
        lineHeight: '26px',
        display: 'flex',
        alignItems: 'end',
      },
      '& h2 span': {
        width: '100%',
        flex: 1,
        border: `2px solid ${theme.palette.orange.main}`,
      },
      '& p': {
        margin: '24px 0 0',
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    [theme.breakpoints.only('md')]: {
      '& > h2': {
        margin: '50px 0 0',
        fontSize: '28px',
      },
      '& > h2 > span': {
        width: '140px',
      },
      '& > p': {
        margin: '40px 0 0',
        fontSize: '20px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& > h2': {
        margin: '50px 0 0',
      },
      '& > h2 > span': {
        width: '281px',
      },
      '& > p': {
        margin: '46px 0 0',
        maxWidth: '638px',
      },
    },
  },
  section1TouchesContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '100px',
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '50px',
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '54px',
    },
  },

  section2: {
    position: 'relative',
  },
  section2Building: {
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    right: '0px',

    // TODO: ???
    [theme.breakpoints.only('xs')]: {
      width: 'calc(562px * 0.3)',
      height: 'calc(456px * 0.3)',
      bottom: '-120px',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(562px * 0.5)',
      height: 'calc(456px * 0.5)',
      bottom: '-160px',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(562px * 0.8)',
      height: 'calc(456px * 0.8)',
      bottom: '-320px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '562px',
      height: '456px',
      bottom: '-374px',
    },
  },
  section2Container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '150px',
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '170px',
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '56px',
    },
  },
  section2Button: {
    margin: '80px 0 100px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      margin: '30px 0 80px',
    },
  },

  section3Background: {
    position: 'relative',
    backgroundSize: '100vw 100%',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    margin: '50px 0 -2px', // -2px to get rid of white line

    [theme.breakpoints.down('sm')]: {
      backgroundPosition: '20%',
    },
    [theme.breakpoints.only('md')]: {
      padding: '120px 0 200px',
    },
    [theme.breakpoints.down('lg')]: {
      backgroundSize: 'cover',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '120px 0',
    },
  },
  section3Header: {
    marginBottom: '53px',
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      margin: '82px 0 48px',
      textAlign: 'center',
    },

    '& > h2 > span': {
      display: 'inline-block',
      width: '30px',
      height: '0px',
      border: `2px solid ${theme.palette.orange.main}`,
      marginLeft: '14px',
    },
  },
  section3Points: {
    '& > h3': {
      fontSize: '40px',
      lineHeight: '60px',
    },
    '& > h3 > span': {
      display: 'inline-block',
      width: '30px',
      height: '0px',
      border: `2px solid ${theme.palette.orange.main}`,
      marginLeft: '14px',
    },
    '& > p': {
      marginTop: '18px',
      color: 'white',
      fontSize: '24px',
      lineHeight: '150%',

      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '20px 0 40px',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '360px',
      },
    },
  },
  section3Body: {
    '& > p': {
      marginTop: '75px',
      height: '241px',
      color: 'white',
      fontSize: '20px',
      lineHeight: '30px',

      [theme.breakpoints.up('md')]: {
        maxWidth: '526px',
      },
    },
  },

  supportContactCard: {
    margin: '75px 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      margin: '0 0 40px',
    },
  },

  metricsBackground: {
    padding: '60px 0',
    backgroundColor: theme.palette.gray.main,
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      paddingTop: '40px 0 60px',
    },
  },
  metricsText: {
    '& > h2': {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        margin: '0 0 28px',
      },
    },
  },

  testimonials: {
    backgroundColor: theme.palette.offWhite.main,
  },
});

export default style;
