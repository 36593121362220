import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import moment from 'moment';
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import withWidth from '@material-ui/core/withWidth';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';

import Card from 'creative-components/Card/Card';
import CardBody from 'creative-components/Card/CardBody';
import CardHeader from 'creative-components/Card/CardHeader';
import CardIcon from 'creative-components/Card/CardIcon';
import Button from 'creative-components/CustomButtons/Button';
import ReactTable from 'creative-components/ReactTable/ReactTable';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import EditAgentCustomerModal from 'components/CRMTable/EditAgentCustomerModal';
import { useDataFilterContext } from 'components/DataMap/DataFilterProvider';
import DecisionModal from 'components/DecisionModal/DecisionModal';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { deleteAgentCustomers, getCRMProCurrentYearTouchTriggers } from 'utils/api';
import { stringifyAddressObject } from 'utils/lib';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const useStyles = makeStyles(styles);

const CRMTable = ({ agentCustomers, setAgentCustomers, width }) => {
  const classes = useStyles();
  const { crmProEventType } = useDataFilterContext();
  const { userInfo } = useUserInfoContext();
  const { setCurrentAlert } = useAlertContext();

  const tableRef = React.createRef();

  const [infoModal, setInfoModal] = React.useState(null);
  const [selectedRecords, setSelectedRecords] = React.useState([]);
  const [isConfirmDelete, setIsConfirmDelete] = React.useState(false);

  const [data, setData] = React.useState([]);
  const [columnsToRender, setColumnsToRender] = React.useState([]);

  const [touchTriggers, setTouchTriggers] = React.useState(null);

  const removeAgentCustomers = async (agentCustomerIds) => {
    try {
      await deleteAgentCustomers(agentCustomerIds);

      setCurrentAlert('success', `Successfully deleted ${agentCustomerIds.length} record(s).`);
    } catch (error) {
      if (error.response && error.response.data.error) {
        setCurrentAlert('error', error.response.data.error);
      } else {
        setCurrentAlert('error', 'Something went wrong, please try again.');
      }

      console.error('error', error);
    }

    setAgentCustomers([...agentCustomers.filter((obj) => !agentCustomerIds.includes(obj._id))]);

    setIsConfirmDelete(false);
  };

  React.useEffect(() => {
    // The # of columns we render depends on the screen width
    let columns = [
      {
        Header: 'Primary Contact Name',
        accessor: 'primaryContactName',
      },
      {
        Header: 'Secondary Contact Name',
        accessor: 'secondaryContactName',
      },
      {
        Header: 'Primary Contact Address',
        accessor: 'primaryContactAddress',
      },
      // {
      //   Header: 'Customer Type',
      //   accessor: 'customerType',
      // },
    ];

    const persistentColumns = [
      {
        Header: 'Date Created',
        accessor: 'createdAt',
        Cell: ({ cell: { value } }) => moment(value).format('MMM D, YYYY'),
        sortType: 'datetime',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        disableFilters: true,
        disableSortBy: true,
      },
    ];

    switch (width) {
      case 'xs':
      case 'sm':
      case 'md':
        // First column
        columns = [...columns.splice(0, 1), ...persistentColumns];
        break;
      default:
        // All columns
        columns = [...columns, ...persistentColumns];
        break;
    }

    setColumnsToRender(columns);
  }, [width]);

  React.useEffect(() => {
    (async () => {
      const { touchTriggers: returnedTouchTriggers } = await getCRMProCurrentYearTouchTriggers();

      // Only show CRM Pro touches for the current year and remove all the birthday touch triggers
      const currentYear = moment().year();
      const filteredTouchTriggers = returnedTouchTriggers.filter(({ sendAt }) => moment(sendAt).year() === currentYear)
        .filter(({ description }) => !description.toLowerCase().includes('birthday'));

      // Add a custom birthday touch trigger with general info just for the touch timeline
      const someBirthdayTouchTrigger = returnedTouchTriggers.filter(({ description }) => description.toLowerCase().includes('birthday'))[0];

      // Super edge case if no birthday touch trigger exists yet for this year
      if (someBirthdayTouchTrigger) {
        setTouchTriggers([
          ...filteredTouchTriggers,
          someBirthdayTouchTrigger,
        ]);
      } else {
        setTouchTriggers(filteredTouchTriggers);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (agentCustomers === null) return;

    setData(agentCustomers.map((customer) => ({
      _id: customer._id,
      primaryContactName: `${customer.primaryContact.firstName} ${customer.primaryContact.lastName || ''}`,
      primaryContactAddress: customer.primaryContact.address && stringifyAddressObject(customer.primaryContact.address),
      secondaryContactName: customer.secondaryContact ? `${customer.secondaryContact.firstName} ${customer.secondaryContact.lastName || ''}` : 'NONE',
      // customerType: capitalize(customer.customerType),
      createdAt: moment(customer.createdAt).toDate(),
      actions: (
        <div>
          <Tooltip
            title="Info"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              justIcon
              round
              simple
              onClick={() => {
                setInfoModal(() => (
                  <EditAgentCustomerModal
                    agentCustomer={customer}
                    addAgentCustomer={(newAgentCustomer) => setAgentCustomers([newAgentCustomer, ...agentCustomers.filter((obj) => obj._id !== customer._id)])}
                    onCancel={() => setInfoModal(null)}
                  />
                ));
              }}
              color="info"
            >
              <EditIcon />
            </Button>
          </Tooltip>
          {' '}
          <Tooltip
            title="Delete"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              justIcon
              round
              simple
              onClick={() => {
                setSelectedRecords([customer._id]);
                setIsConfirmDelete(true);
              }}
              color="danger"
            >
              <DeleteForeverIcon />
            </Button>
          </Tooltip>
        </div>
      ),
    })));
  }, [agentCustomers]);

  return (
    <>
      {isConfirmDelete && selectedRecords.length > 0
        ? (
          <DecisionModal
            message={`Are you sure you want to remove ${selectedRecords.length} record(s)?`}
            onYes={() => removeAgentCustomers(selectedRecords)}
            onNo={() => setIsConfirmDelete(false)}
            yesColor="danger"
            noColor="transparent"
          />
        )
        : null}
      {infoModal}
      <Card>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <GroupIcon />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Your Contacts</h4>
        </CardHeader>
        <CardBody>
          <h5 className={classes.sectionHeader}><b>Timeline</b></h5>
          {crmProEventType && touchTriggers && agentCustomers ? (
            <>
              {/* <OldTouchTimeline
                agent={userInfo}
                eventType={crmProEventType}
                touchTriggers={touchTriggers}
              /> */}
              <br />
              <br />
              <br />
              <ReactTable
                ref={tableRef}
                columns={columnsToRender}
                data={data}
                selectableRows
                renderRightColumnComponentOnlyWhenRowsSelected
                rightColumnComponent={(
                  <Tooltip
                    title="Delete Selected"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => {
                        if (!tableRef.current || tableRef.current.getSelectedFlatRows().length === 0) return;

                        setSelectedRecords(tableRef.current.getSelectedFlatRows().map(({ original }) => original._id));
                        setIsConfirmDelete(true);
                      }}
                    >
                      Delete Selected
                    </Button>
                  </Tooltip>
                )}
              />
            </>
          ) : (<LoadingIndicator />)}
        </CardBody>
      </Card>
    </>
  );
};

export default withWidth()(CRMTable);
