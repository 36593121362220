import React from 'react';

import { useTheme } from '@material-ui/core/styles';

import ProductLayout2 from 'components/Product/ProductLayout2';

import heroImage from 'assets/img/products/new/hero/nod-hero.png';

const NODPage = () => {
  const theme = useTheme();

  return (
    <ProductLayout2
      eventTypeName="NOD"
      heroImage={heroImage}
      heroColorVariant="gray"
      productTermsBgColor={theme.palette.darkGray.main}
      section1BgVariant="gray"
      section3BgVariant="darkGray"
    />
  );
};
export default NODPage;
