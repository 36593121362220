import cx from 'classnames';
import moment from 'moment';
import React from 'react';

import { ButtonBase, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import CampaignAPNsReduceModal from 'components/CampaignInfoCards/CampaignAPNsReduceModal';
import CampaignCostsTooltip from 'components/CampaignInfoCards/CampaignCostsTooltip';

import { numberWithCommas } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  infoCard: {
    margin: '20px auto 0',
    padding: '10px 24px',
    position: 'relative',
    maxWidth: '300px',
    height: '70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.black.main,

    '& > h4': {
      font: 'inherit',
      fontWeight: 'bold',
      marginBottom: '4px',
    },
    '& > p': {
      font: 'inherit',
      fontWeight: 'normal',
    },
  },
  numberOfApnsInfoCard: {
    '& svg': {
      marginLeft: '10px',
      verticalAlign: 'middle',
    },
    '& > button': {
      cursor: 'pointer',
      position: 'absolute',
      bottom: '10px',
      right: '12px',
      textDecoration: 'underline',
      fontFamily: 'Overpass Mono',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.orange.main,
    },
  },
  pricingInfoCard: {
    '& > p': {
      fontSize: '24px',
      lineHeight: '26px',
    },
    '& > span': {
      cursor: 'grab',
      position: 'absolute',
      bottom: '10px',
      right: '12px',
      textDecoration: 'underline',
      fontFamily: 'Overpass Mono',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      color: theme.palette.orange.main,
    },
  },
}));

function CampaignInfoCards({
  startDate, endDate, campaignCosts, valuesAreEstimates, apnsTotalSelectedCount, apnsFarmingStoppedCount, canReduceCosts,

  // Optional, used for create campaign page
  apnAvailableCounts, apnSelectedCounts, setApnSelectedCounts, setApnsTotalSelectedCount, apnsTotalInAreaCount,
}) {
  const classes = useStyles();

  const [reduceAPNsModalOpen, setReduceAPNsModalOpen] = React.useState(false);

  let cardTitleSuffix = '';

  if (valuesAreEstimates) cardTitleSuffix = ' (est.)';

  return (
    <GridContainer justifyContent="center" alignContent="center">
      {apnAvailableCounts && Object.keys(apnAvailableCounts).length !== 0 && reduceAPNsModalOpen && (
        <CampaignAPNsReduceModal
          onClose={() => setReduceAPNsModalOpen(false)}
          apnAvailableCounts={apnAvailableCounts}
          apnSelectedCounts={apnSelectedCounts}
          setApnSelectedCounts={setApnSelectedCounts}
          setApnsTotalSelectedCount={setApnsTotalSelectedCount}
        />
      )}

      <GridItem xs={12} sm={6} xl={3}>
        <div className={classes.infoCard} style={{ backgroundColor: '#F2FFF0' }}>
          <h4>Campaign Start Date</h4>
          <p>{startDate ? moment(startDate).format('MMMM Do, YYYY') : 'TBD'}</p>
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} xl={3}>
        <div className={classes.infoCard} style={{ backgroundColor: '#D5FCCF' }}>
          <h4>Campaign End Date</h4>
          <p>{endDate ? moment(endDate).format('MMMM Do, YYYY') : 'TBD'}</p>
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} xl={3}>
        <div className={cx(classes.infoCard, classes.numberOfApnsInfoCard)} style={{ backgroundColor: '#D6F5FF' }}>
          <h4>{`Number of APNs${cardTitleSuffix}`}</h4>
          <p>
            {apnsTotalInAreaCount !== undefined && (
              apnsTotalSelectedCount < apnsTotalInAreaCount ? (
                <span>
                  {`${numberWithCommas(apnsTotalSelectedCount)} Selected`}
                  <Tooltip
                    placement="top"
                    disableFocusListener
                    disableTouchListener
                    title={`There are a total of ${numberWithCommas(apnsTotalInAreaCount)} APNs in the selected Farm Areas, some of which may be farmed by other users and be unavailable to you, or excluded from your campaign due to your APN limit`}
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                </span>
              )
                : `${numberWithCommas(apnsTotalSelectedCount)} Selected`
            )}

            {!valuesAreEstimates && (
              <span>
                {`${numberWithCommas(apnsTotalSelectedCount)} Total, ${numberWithCommas(apnsTotalSelectedCount - apnsFarmingStoppedCount)} Active`}
                <Tooltip
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  title={`The total # of APNs less the ${apnsFarmingStoppedCount} lead(s) you've disabled farming on`}
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </span>
            )}
          </p>
          {canReduceCosts && apnsTotalSelectedCount > 0 && Object.keys(apnAvailableCounts).length !== 0 && (<ButtonBase onClick={() => setReduceAPNsModalOpen(true)}>Reduce?</ButtonBase>)}
        </div>
      </GridItem>
      <GridItem xs={12} sm={6} xl={3}>
        <div className={cx(classes.infoCard, classes.pricingInfoCard)} style={{ backgroundColor: '#B3EDFF' }}>
          <h4>{`Campaign Cost${cardTitleSuffix}`}</h4>
          {campaignCosts && (
            <>
              <p>{`$${numberWithCommas((campaignCosts.totalMonthlyCost / 100).toFixed(2))}/mo`}</p>
              <span>
                <CampaignCostsTooltip text="Breakdown" campaignCosts={campaignCosts} />
              </span>
            </>
          )}
        </div>
      </GridItem>
    </GridContainer>
  );
}

export default CampaignInfoCards;
