import cookies from 'js-cookie';
import JwtDecode from 'jwt-decode';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import analyticsClient from 'utils/analytics';
import { getBrokerage } from 'utils/api';

export const LOGIN_ROUTE = '/login';
const DASHBOARD_ROUTE = '/dashboard'; // Trailing slash required for dashboard route layout to render correctly
export const ADS_DASHBOARD_ROUTE = '/dashboard/ad-campaigns'; // Trailing slash required for dashboard route layout to render correctly
const LOGOUT_ROUTE = '/login';
const AGENT_SIGNUP_ROUTE = '/signup';
const CRM_PRO_ROUTE = '/dashboard/crm-pro';

const cookieDomain = window.location.hostname !== 'localhost' ? process.env.REACT_APP_COOKIE_DOMAIN : '';

// Used to prevent wrong usage between diff envs on same domains
const cookiePrefix = process.env.REACT_APP_COOKIE_PREFIX;

interface AuthTokenType {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  isAssumedUser: string;
}

interface AuthDataType {
  user: AuthTokenType | null;
}

// TODO: ADD TYPES
export const AuthDataContext = React.createContext<any>(null);

// @ts-ignore Types are wrong here
export const getAuthToken = () => cookies.get(`${cookiePrefix}-token`, { domain: cookieDomain });

// Used to explicitly delete the auth token
export const clearAuthToken = () => {
  cookies.remove(`${cookiePrefix}-token`, { domain: cookieDomain });
  cookies.remove(`${cookiePrefix}-parent-token`, { domain: cookieDomain });
};

const getAuthTokenUserData = (): AuthDataType => {
  const {
    id, firstName, lastName, role, isAssumedUser,
  } = JwtDecode<AuthTokenType>(getAuthToken());

  return {
    user: {
      id, firstName, lastName, role, isAssumedUser,
    },
  };
};

const initialAuthData = { user: null };

const AuthDataProvider = (props: any) => {
  const history = useHistory();
  const location = useLocation();
  const { mutate } = useSWRConfig();

  // Used to delay PublicRoute and PrivateRoute redirection until auth data loads
  const [isAuthLoaded, setIsAuthLoaded] = React.useState(false);

  const [authData, setAuthData] = React.useState<AuthDataType>(initialAuthData);

  React.useEffect(() => {
    if (getAuthToken()) {
      setAuthData(getAuthTokenUserData());
    }

    // If they have no token, do nothing

    setIsAuthLoaded(true);
  }, []);

  const onLogout = () => {
    // Clear SWR cache
    // cache.clear();
    mutate(
      (key: any) => true, // which cache keys are updated
      undefined, // update cache data to `undefined`
      { revalidate: false }, // do not revalidate
    );

    clearAuthToken();
    setAuthData(initialAuthData);
  };

  /**
   * Callback for logging in a user using their auth token
   *
   * @param {String} token - JWT
   * @param {String} expiresInDays - Number of days the token (cookie) should expire in
   */
  const onLogin = (token: string, expiresInDays: number, isRegistration = false, registrationEmail = null, redirect = true) => {
    // Check if a user is being assumed
    const { isAssumedUser } = JwtDecode<any>(token);
    if (isAssumedUser) {
      // Save the previous auth token for when they exit the assumed user

      // NOTE: Expiration gets reset when impersonating users, but nothing we can do about it due to browser
      //       cookie limitations. The backend will catch the expired token in these cases since it will still
      //       be saved on the frontend.
      cookies.set(`${cookiePrefix}-parent-token`, getAuthToken(), { expires: expiresInDays, domain: cookieDomain });
    }

    cookies.set(`${cookiePrefix}-token`, token, { expires: expiresInDays, domain: cookieDomain });

    const authTokenUserData = getAuthTokenUserData();
    setAuthData(authTokenUserData);

    if (redirect) {
      let redirectPath = location.pathname;

      // Always redirect to some part of the dashboard after logging in
      if (!redirectPath.includes('dashboard')) {
        redirectPath = DASHBOARD_ROUTE;
      }

      history.replace(redirectPath);
    }

    // Track GTM events for agent/brokerage registration completion
    if (isRegistration) {
      analyticsClient.track('user_registration_completed', {
        agentdata_user_id: authTokenUserData.user!.id,
        role: authTokenUserData.user!.role,
        email: registrationEmail,
      });

      // If this is a brokerage admin logging in, check to see if they are the initial
      // brokerage admin for this brokerage, which will mean they are completing the
      // registration for this brokerage sign-up
      if (authTokenUserData.user!.role === 'Brokerage Admin') {
        // Do inside async function to not block the login
        (async () => {
          // Fetch the user to see if they have a brokerage
          const { brokerage } = await getBrokerage();

          // Make sure this is the only admin on the brokerage
          if (brokerage.admins.length === 1) {
            analyticsClient.track('brokerage_registration_completed', {
              brokerage_id: brokerage._id,
            });
          }
        })();
      }
    }

    // Track GTM event
    if (!isAssumedUser) {
      analyticsClient.track('login', {
        agentdata_user_id: authTokenUserData.user!.id,
        role: authTokenUserData.user!.role,
      });
    }
  };

  const onExitAssumedUser = () => {
    // Clear SWR cache
    // cache.clear();
    mutate(
      (key: any) => true, // which cache keys are updated
      undefined, // update cache data to `undefined`
      { revalidate: false }, // do not revalidate
    );

    // @ts-ignore Types are wrong here
    const parentJwtToken = cookies.get(`${cookiePrefix}-parent-token`, { domain: cookieDomain });

    // Edge case with multiple browser tabs of assumed users open
    if (!parentJwtToken) {
      onLogout();
      return;
    }

    cookies.set(`${cookiePrefix}-token`, parentJwtToken, { expires: 7, domain: cookieDomain });
    cookies.remove(`${cookiePrefix}-parent-token`, { domain: cookieDomain });

    const authTokenUserData = getAuthTokenUserData();
    setAuthData(authTokenUserData);

    history.push(DASHBOARD_ROUTE);
  };

  const authDataValue = React.useMemo(() => ({
    ...authData,
    isAuthLoaded,
    onLogin,
    onLogout,
    onExitAssumedUser,
    // TODO: MOVE THESE INTO SOME SORT OF CONSTANTS FILE?S
    LOGIN_ROUTE,
    DASHBOARD_ROUTE,
    LOGOUT_ROUTE,
    AGENT_SIGNUP_ROUTE,
    CRM_PRO_ROUTE,
    isAdmin: authData.user && authData.user.role === 'Admin',
    isAccountManager: authData.user && authData.user.role === 'Account Manager',
    isTitleRep: authData.user && authData.user.role === 'Title Rep',
    isBrokerageAdmin: authData.user && authData.user.role === 'Brokerage Admin',
    isBrokerageAgent: authData.user && authData.user.role === 'Brokerage Agent',
    isAnyAgent: authData.user && ['Brokerage Agent', 'Agent'].includes(authData.user.role),
  }), [authData, isAuthLoaded]);

  return <AuthDataContext.Provider value={authDataValue} {...props} />;
};

export const useAuthDataContext = () => React.useContext(AuthDataContext);

export default AuthDataProvider;
