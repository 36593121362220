import { useAdCampaignAdPreview } from 'data/ad-campaign';
import { useCampaigns } from 'data/campaign';
import {
  Field, FieldArray, FieldProps, Form, Formik, FormikProps,
} from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ECampaignStatus } from 'types/campaign';
import * as Yup from 'yup';

import {
  Box, ButtonBase, Chip, Grid, Slider, TextField,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LibraryAddCheckRoundedIcon from '@material-ui/icons/LibraryAddCheckRounded';
import { Autocomplete } from '@material-ui/lab';

import Button from 'creative-components/CustomButtons/Button';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import ActionModal from 'components/ActionModal/ActionModal';
import AdPreview from 'components/AdPreview';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { ADS_DASHBOARD_ROUTE, useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import DashboardSection from 'components/DashboardSection/DashboardSection';
import DataMap from 'components/DataMap/DataMap';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import MultiZipcodesInput from 'components/MultiZipcodesInput/MultiZipcodesInput';
import PaymentMethodCard from 'components/PaymentMethodCard/PaymentMethodCard';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import analyticsClient from 'utils/analytics';
import { createAdCampaign } from 'utils/api/ad-campaigns';
import { numberWithCommas, showAPIErrorAlert, urlRegExp } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  map: {
    height: '95vh',
    maxHeight: '800px',
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0 40px',

    '& > div:first-child': { // Zipcodes input
      marginBottom: '20px',
    },
  },
  infoCards: {
    margin: '0 0 50px',
  },
  settingInput: {
    maxWidth: '400px',
  },
  autoCompleteSelectText: {
    fontWeight: 500,
  },
  spacerSmall: {
    marginTop: '70px',
  },
  spacerBig: {
    marginTop: '140px',
  },
  createCampaignButton: {
    float: 'right',
  },
  confirmPopUp: {
    minWidth: '500px',
  },
  paymentMethodContainer: {
    borderRadius: '12px',
    padding: '18px',
    backgroundColor: theme.palette.primary.light,
  },
  labelText: {
    color: theme.palette.darkGray.main,
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '6px',
    fontWeight: 600,
  },
  helperText: {
    marginTop: '6px',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.lightGray.main,
  },
}));

interface FormValues {
  name: string;
  callToActionLink: string;
  budget: number;
  zipCodes: string[];
  customAudienceCampaigns: any[];
}

const maxNumberOfZipcodes = 10;
const budgetMinimum = 20000;
const budgetMaximum = 200000;

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { userInfo, reloadUserInfo } = useUserInfoContext();
  const { user } = useAuthDataContext();
  const { setCurrentAlert } = useAlertContext() as any;
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext() as any;

  const { campaigns, isLoadingCampaigns } = useCampaigns(undefined, [ECampaignStatus.Active]);
  const { adPreview } = useAdCampaignAdPreview();

  const [showPaymentMethodModal, setShowPaymentMethodModal] = React.useState(false);

  if (!userInfo) return <LoadingIndicator />;

  const isBillingInfoProvided = userInfo.agentInfo.stripe.paymentMethodInfo && userInfo.agentInfo.stripe.paymentMethodInfo.last4;

  return (
    <div className={classes.root}>
      <Formik<FormValues>
        initialValues={{
          name: '',
          callToActionLink: '',
          budget: budgetMinimum,
          zipCodes: [],
          customAudienceCampaigns: [],
        }}
        onSubmit={async (values) => {
          const {
            name, callToActionLink, budget, zipCodes, customAudienceCampaigns,
          } = values;
          showLoadingIndicatorModal();

          try {
            // Make sure they have a payment method on file
            if (!isBillingInfoProvided) {
              setShowPaymentMethodModal(true);
              return;
            }

            if (!zipCodes.length) {
              setCurrentAlert('warning', 'Please select zip codes for your digital ad\'s audience!');
              return;
            }

            await createAdCampaign({
              name,
              callToActionLink,
              budget,
              customAudienceCampaignIds: customAudienceCampaigns.map((campaign: any) => campaign._id),
              zipCodes,
            });

            // Track GTM event
            await analyticsClient.track('ad_campaign_creation_completed', {
              agentdata_user_id: user.id,
              role: user.role,
              brokerage_id: userInfo.agentInfo.brokerage?._id ?? null,
            });

            // Go to the dashboard
            history.push(ADS_DASHBOARD_ROUTE);

            setCurrentAlert('success', 'Your ad has been created! Pending ad network approval, your ad will start running soon.');
          } catch (err) {
            console.error('error', err);
            showAPIErrorAlert(setCurrentAlert, err);
          } finally {
            hideLoadingIndicatorModal();
          }
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          callToActionLink: Yup.string().url().required().matches(urlRegExp),
          budget: Yup.number().integer().min(budgetMinimum).required(),
          zipCodes: Yup.array().of(Yup.string()).required().min(1),
          customAudienceCampaigns: Yup.array().of(Yup.object()).required(),
        })}
      >
        {({
          isSubmitting, setFieldValue, values, handleSubmit,
        }: FormikProps<FormValues>) => (
          <Form>
            <FormikOnError>
              {showPaymentMethodModal && (
                <ActionModal
                  maxWidth="sm"
                  backgroundColor={theme.palette.offWhite.main}
                  textColor={theme.palette.darkGray.main}
                  icon={<LibraryAddCheckRoundedIcon />}
                  onClose={() => setShowPaymentMethodModal(false)}
                  title="Start Your Ad"
                  message={(
                    <div>
                      Please provide a payment method.
                      <br />
                      <br />
                      <div className={classes.paymentMethodContainer}>
                        <PaymentMethodCard
                          billingName={`${userInfo.firstName} ${userInfo.lastName}`}
                          paymentMethodInfo={userInfo.agentInfo.stripe.paymentMethodInfo}
                          reloadCallback={reloadUserInfo}
                        />
                      </div>
                    </div>
                  )}
                  buttons={[
                    <ButtonBase disabled={!isBillingInfoProvided} onClick={() => handleSubmit()}>Create My Ad</ButtonBase>,
                  ]}
                />
              )}

              <DashboardSection sectionName="Create Your Digital Ad" />

              <FieldArray
                name="zipCodes"
                render={(arrayHelpers) => (
                  <div className={classes.map}>
                    <MultiZipcodesInput
                      fieldName={arrayHelpers.name}
                      labelText="Type zip codes you want your digital ad to target and press enter"
                      arrayHelpers={arrayHelpers}
                      maxNumberOfZipcodes={maxNumberOfZipcodes}
                    />
                    <DataMap
                      highlightZipcodes={values.zipCodes}
                      zipcodesFormLabel="Select the Zip Codes you want your ad to target"
                    />
                    <div className={classes.helperText}>
                      Our Ad partners target audiences within a 15-mile radius of the zip codes selected.
                    </div>
                  </div>
                )}
              />

              <FieldArray
                name="customAudienceCampaigns"
                render={() => (
                  <>
                    <Autocomplete
                      style={{ maxWidth: '600px' }}
                      classes={{
                        loading: classes.autoCompleteSelectText,
                        option: classes.autoCompleteSelectText,
                        noOptions: classes.autoCompleteSelectText,
                      }}
                      loading={isLoadingCampaigns}
                      multiple
                      filterSelectedOptions
                      value={values.customAudienceCampaigns}
                      onChange={(event, newValue) => setFieldValue('customAudienceCampaigns', newValue, true)}
                      options={campaigns ?? []}
                      renderTags={(tags, getTagProps) => (
                        tags.map((campaign, index) => (
                          <Chip
                            color="secondary"
                            key={campaign._id}
                            label={`${campaign.name} (${campaign.subscribedEvents.length} Prospects)`}
                            {...getTagProps({ index })}
                          />
                        ))
                      )}
                      getOptionLabel={(campaign) => `${campaign.name} (${campaign.subscribedEvents.length} Prospects)`}
                      renderInput={(params) => (
                        <>
                          <div className={classes.labelText}>
                            Include Campaign Audiences
                          </div>
                          <TextField
                            {...params}
                            InputProps={{
                              style: {
                                fontWeight: 500,
                                paddingTop: '6.5px',
                                paddingBottom: '6.5px',
                              },
                              ...params.InputProps,
                            }}
                            variant="outlined"
                            placeholder="Campaign"
                          />
                          <div className={classes.helperText}>
                            Select any of your active campaigns to include in your digital ad's audience
                          </div>
                        </>
                      )}
                    />
                  </>
                )}
              />

              <div className={classes.spacerSmall} />

              <DashboardSection sectionName="Customization" />

              <Grid container style={{ marginTop: '30px' }}>
                <Grid item xs={12} md={6}>
                  <Field name="name">
                    {({ field, meta }: FieldProps) => (
                      <>
                        <CustomInputDashboard
                          helperText="Choose a name for you to refer to this ad by"
                          labelText="My Ad Name"
                          inputProps={{
                            ...field,
                            placeholder: 'Name',
                          }}
                          className={classes.settingInput}
                          error={meta.touched && !!meta.error}
                        />
                      </>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="callToActionLink">
                    {({ field, meta }: FieldProps) => (
                      <CustomInputDashboard
                        helperText="Choose the website your audience is redirected to when they engage with your ad"
                        labelText="Call to Action Link"
                        inputProps={{
                          ...field,
                          placeholder: 'Enter URL',
                        }}
                        className={classes.settingInput}
                        error={meta.touched && !!meta.error}
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Field name="budget">
                    {({ field, meta }: FieldProps) => (
                      <div className={classes.settingInput}>
                        <Box className={classes.labelText} display="flex" alignItems="center">
                          Select your monthly ad budget spend
                          <Tooltip
                            style={{ marginLeft: '10px' }}
                            placement="top"
                            disableFocusListener
                            disableTouchListener
                            // TODO: ???
                            title="Our ad partners recommend a minimum monthly budget of $300 for the best results"
                          >
                            <InfoOutlinedIcon />
                          </Tooltip>
                        </Box>
                        <Slider
                          valueLabelDisplay="off"
                          step={1000}
                          min={budgetMinimum}
                          max={budgetMaximum}
                          value={field.value}
                          onChange={(event, newValue) => setFieldValue(field.name, newValue, true)}
                        />
                        <span style={{
                          color: theme.palette.primary.main,
                          fontWeight: 600,
                        }}
                        >
                          {`$${numberWithCommas(field.value / 100)}`}
                        </span>
                      </div>
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '40px' }}>
                  <div className={classes.labelText}>Ad Preview</div>
                  <AdPreview imageData={adPreview} />
                </Grid>
              </Grid>

              <div className={classes.spacerSmall} />

              <Button
                round
                color="primary"
                disabled={isSubmitting}
                type="submit"
              >
                Create My Ad
              </Button>
            </FormikOnError>
          </Form>
        )}
      </Formik>
    </div>
  );
};
