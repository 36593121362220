import React from 'react';
import qs from 'qs';

import { makeStyles } from '@material-ui/core/styles';

import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import ResetPasswordForm from 'components/ResetPasswordForm/ResetPasswordForm';

import { lookupPasswordResetToken } from 'utils/api';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';

const useStyles = makeStyles(styles);

const ResetPasswordPage = ({
  history, location, heading, subheading = '',
}) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { token: passwordResetToken } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { LOGIN_ROUTE } = useAuthDataContext();

  // The email being used to register, returned from the backend
  const [email, setEmail] = React.useState('');

  React.useEffect(() => {
    (async () => {
      try {
        const { email: emailReturned } = await lookupPasswordResetToken(passwordResetToken);

        setEmail(emailReturned);
      } catch (error) {
        if (error.response.data.error) {
          if (error.response.data.error.includes('User not found')) {
            // The pasword reset token provided was invalid, so show an error and redirect to homepage
            setCurrentAlert('error', 'That password reset token is invalid!');

            // Redirect to the homepage
            history.replace('/');
          } else if (error.response.data.error.includes('Token already used')) {
            // The password reset token provided was already used, so redirect to login
            history.replace(LOGIN_ROUTE);
          }
        }
      }
    })();
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <ResetPasswordForm
          heading={heading}
          subheading={subheading}
          email={email}
          passwordResetToken={passwordResetToken}
        />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
