import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { supportPhoneNumber, supportPhoneNumberFormatted } from 'utils/lib';

import styles from 'assets/jss/material-dashboard-pro-react/components/supportContactCardStyle';
import CallConnectingIcon from 'components/CustomIcons/CallConnectingIcon';

const useStyles = makeStyles(styles);

// TODO: FOR FUTURE TYPESCRIPT: color: 'orange' | 'white'
const SupportContactCard = ({ color }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.root}>
      <p className={classes.questionsText}>
        Have questions? Give us a call and we’ll walk through it.
      </p>
      <a href={`tel:${supportPhoneNumber}`}>
        <div className={classes.supportNumberContainer}>
          <CallConnectingIcon className={classes.supportNumberIcon} />
          <p className={classes.supportNumberText}>{supportPhoneNumberFormatted}</p>
        </div>
      </a>
    </div>
  );
};

export default SupportContactCard;
