/* eslint-disable camelcase */
import React from 'react';
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import log from 'loglevel';

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CustomInput from 'creative-components/CustomInput/CustomInput';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import styles from 'assets/jss/material-dashboard-pro-react/components/addressAutocompleteStyle';

const useStyles = makeStyles(styles);

// Should be used as the input component for the main address field,
// but also requires fields for address2, city, state, zip, and location.
//
// Uses valueName for the prefix for all of the field names in the form.
// E.g. companyAddress1, companyAddress2, etc...
const AddressAutocomplete = ({
  valueName, inputProps, setFieldValue, initialValue, disabled = false, ...props
}) => {
  const classes = useStyles();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      // types: ['address'],
      types: ['geocode'],
      componentRestrictions: {
        country: 'us',
      },
    },
  });

  React.useEffect(() => {
    if (initialValue) {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(initialValue, false);
    }
  }, [initialValue]);

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = async (suggestion) => {
    log.debug('suggestion', suggestion);
    const { address_components, geometry: { location: { lat, lng } } } = await getDetails({ placeId: suggestion.place_id });

    let streetNumber = '';
    let route = '';

    let address2 = '';

    let city = '';
    let state = '';
    let zipcode = '';

    log.debug(address_components);

    address_components.forEach(({ long_name, short_name, types }) => {
      if (types.includes('street_number')) {
        streetNumber = short_name;
      } else if (types.includes('route')) {
        route = short_name;
      } else if (types.includes('subpremise')) {
        address2 = short_name;
      // } else if (types.includes('locality')) {
      } else if (!city && types.includes('political')) {
        // Sometimes we get two political type results, so pick whichever one
        // is in the "official" description shown when searching

        // Also, pick either the long or short name, depending on which is in the address string Google gives (the "official" description)
        if (suggestion.description.includes(short_name)) {
          city = short_name;
        } else if (suggestion.description.includes(long_name)) {
          city = long_name;
        }
      } else if (types.includes('administrative_area_level_1')) {
        state = short_name;
      } else if (types.includes('postal_code')) {
        zipcode = short_name;
      }
    });

    // Combine the street number and street to create address1
    const address1 = `${streetNumber}${streetNumber ? ' ' : ''}${route}`;

    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(address1, false);
    clearSuggestions();

    // NOTE: These field names MUST be exact in the form inputs!
    setFieldValue(`${valueName}.address1`, address1, true);
    setFieldValue(`${valueName}.address2`, address2, true);
    setFieldValue(`${valueName}.city`, city, true);
    setFieldValue(`${valueName}.state`, state, true);
    setFieldValue(`${valueName}.zipcode`, zipcode, true);
    setFieldValue(`${valueName}.location`, { lat: lat(), lng: lng() }, true);
  };

  const renderSuggestions = () => (
    <List>
      {data.map((suggestion) => {
        const {
          id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;

        return (
          <ButtonBase
            key={`${main_text}${secondary_text}`}
            disableRipple
            disableTouchRipple
            onClick={() => handleSelect(suggestion)}
          >
            <ListItem button dense>
              <ListItemText primary={main_text} secondary={secondary_text} />
            </ListItem>
          </ButtonBase>
        );
      })}
    </List>
  );

  // TODO: Just use dashboard one everywhere once WP site is live?
  // Two different styled inputs depending on main page vs dashboard
  let InputComponent = CustomInput;
  if (window.location.pathname.startsWith('/dashboard')) InputComponent = CustomInputDashboard;

  return (
    <span ref={ref}>
      {window.location.pathname.startsWith('/dashboard')}
      <InputComponent
        {...props}
        inputProps={{
          ...inputProps,
          value,
          disabled: !ready || disabled,
          onChange: handleInput,
        }}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && renderSuggestions()}
    </span>
  );
};

export default AddressAutocomplete;
