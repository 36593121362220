import MomentUtils from '@date-io/moment';
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createBrowserHistory } from 'history';
import AgentLandingLayout from 'layouts/AgentLanding';
import DashboardLayout from 'layouts/Dashboard';
import HomeLayout from 'layouts/Home';
import UnsubscribeLayout from 'layouts/Unsubscribe';
import log from 'loglevel';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import { SWRConfig } from 'swr';

import 'assets/scss/material-dashboard-pro-react.scss';

import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import AlertProvider from 'components/AlertProvider/AlertProvider';
import AuthDataProvider from 'components/AuthDataProvider/AuthDataProvider';
import LoadingIndicatorProvider from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { apiClient } from 'utils/api';
import theme from 'utils/theme';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const hist = createBrowserHistory();

log.setDefaultLevel(process.env.REACT_APP_LOGGER_LEVEL);

// Only enabled in prod, for now
if (process.env.REACT_APP_SENTRY_IO_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_IO_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.3, // Reduce later if we're getting too much data

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

const App = () => {
  const isAgentPostcardLandingSubdomain = window.location.origin.match('://homeprice.');

  let routes;

  if (isAgentPostcardLandingSubdomain) {
    routes = (
      <Route path="/" component={AgentLandingLayout} />
    );
  } else {
    routes = (
      <Switch>
        <Route path="/dashboard" component={DashboardLayout} />
        <Route path="/unsubscribe" component={UnsubscribeLayout} />
        <Route path="/" component={HomeLayout} />
      </Switch>
    );
  }

  return (
    <Router history={hist}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Elements stripe={stripePromise}>
            <AlertProvider>
              <SWRConfig
                value={{
                  revalidateOnFocus: false, // Can revisit this in the future
                  fetcher: (url, params) => apiClient.get(url, { params }).then((res) => {
                    log.debug('called', url, 'with params', { params }, 'and got', res.data);
                    return res.data;
                  }),
                // onError: (error, key) => {
                //   // TODO: Check for error, copy showAPIErrorAlert logic, maybe use React Hot Toast instead of our own alerts?
                // },
                }}
              >
                <AuthDataProvider>
                  <LoadingIndicatorProvider>
                    {routes}
                  </LoadingIndicatorProvider>
                </AuthDataProvider>
              </SWRConfig>
            </AlertProvider>
          </Elements>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
