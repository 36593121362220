import React from 'react';
import { Link } from 'react-router-dom';

import {
  makeStyles, useTheme, useMediaQuery, Hidden,
} from '@material-ui/core';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import ProductMetrics from 'components/Product/ProductMetrics';
import ProductTerms from 'components/Product/ProductTerms';
import ProductTouches from 'components/Product/ProductTouches';
import ProductSequence from 'components/Product/ProductSequence';
import SupportContactCard from 'components/SupportContactCard/SupportContactCard';
import Testimonials from 'components/Testimonials/Testimonials';

import { productMarketingInfo, whyChooseUsText } from 'utils/productMarketingInfo';

import styles from 'assets/jss/material-dashboard-pro-react/components/productLayout1Style';

const useStyles = makeStyles(styles);

const ProductLayout1 = ({
  eventTypeName, heroImage, heroColor, productTermsBgColor,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isXsWidth = useMediaQuery(theme.breakpoints.only('xs'));

  const productInfo = productMarketingInfo[eventTypeName];

  return (
    <div className={classes.root}>
      <div className={classes.hero}>
        <div className={classes.heroBackground} style={{ backgroundImage: `url(${heroImage})` }}>
          <div style={{ background: heroColor }} />
        </div>

        <GridContainer className={classes.container}>
          <GridItem xs={12} md={6} className={classes.headerTextContainer}>
            <h1>
              {eventTypeName}
              <span />
            </h1>
            <p className={classes.heroText1}>
              {productInfo.heroText1}
            </p>
            <p className={classes.heroText2}>
              {productInfo.heroText2}
            </p>
            <Button
              color="primary"
              round
              outlined
              size={!isXsWidth ? 'lg' : null}
              component={Link}
              to={{
                pathname: '/sign-up',
                state: { eventTypeName },
              }}
            >
              Get Started - Today!
            </Button>
          </GridItem>
          <GridItem xs={12} md={6}>
            <div className={classes.headerVideoContainer}>
              <iframe
                className={classes.videoContainer}
                src={`https://www.youtube-nocookie.com/embed/${productInfo.youtubeVideoId}?autoplay=0&modestbranding=1&rel=0`}
                title={eventTypeName}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className={classes.headerProductInfo}>
              <ProductTerms
                numCommitmentMonths={productInfo.numCommitmentMonths}
                membershipFeePerMonth={productInfo.membershipFeePerMonth}
                mailOutFeePerPiece={productInfo.mailOutFeePerPiece}
                minimumNumRecords={productInfo.minimumNumRecords}
                bgColor={productTermsBgColor}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.section1}>
        <GridContainer className={classes.container}>
          <GridItem xs={12} md={6} className={classes.section1TextContainer}>
            <h2>
              What's Included:
              <span />
            </h2>
            {productInfo.whatsIncludedText}
          </GridItem>
          <GridItem xs={12} md={6} className={classes.section1TouchesContainer}>
            <ProductTouches
              positionVariant="right"
              numVoicemails={productInfo.numVoicemails}
              numLetters={productInfo.numLetters}
              numEmails={productInfo.numEmails}
              numPostcards={productInfo.numPostcards}
            />
          </GridItem>
        </GridContainer>
      </div>

      <GridContainer className={classes.container}>
        <GridItem xs={12} className={classes.section2Container}>
          <ProductSequence />
          <Hidden smDown>
            <div className={classes.section2Button}>
              <Button
                color="orange"
                round
                outlined
                size={!isXsWidth ? 'lg' : null}
                component={Link}
                to={{
                  pathname: '/sign-up',
                  state: { eventTypeName },
                }}
              >
                Get Started - Today!
              </Button>
            </div>
          </Hidden>
        </GridItem>
      </GridContainer>

      <div className={classes.section3}>
        <div className={classes.section3OrangeDots} />
        <div className={classes.section3Building} />

        <div className={classes.supportContactCard}>
          <SupportContactCard color="white" />
        </div>

        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.section3Header}>
            <h2>
              Why Choose Us
              <span />
            </h2>
          </GridItem>

          {whyChooseUsText.points.map((text, i) => (
            <React.Fragment key={i}>
              <Hidden mdUp><GridItem xs={1} /></Hidden>
              <GridItem xs={10} md={4} className={classes.section3Points}>
                <h3>
                  {`0${i + 1}`}
                  <span />
                </h3>
                <p>
                  {text}
                </p>
              </GridItem>
              <Hidden mdUp><GridItem xs={1} /></Hidden>
            </React.Fragment>
          ))}

          <Hidden mdUp>
            <div className={classes.section3SmSpacer} />
          </Hidden>
          <Hidden smDown>
            <GridItem md={6} className={classes.section3Body}>
              {whyChooseUsText.body}
            </GridItem>
            <GridItem md={6} />
          </Hidden>
        </GridContainer>
      </div>

      <div className={classes.metricsBackground}>
        <GridContainer className={classes.container} alignItems="center">
          <GridItem xs={12} md={4} lg={5} className={classes.metricsText}>
            <h2>
              We love numbers
            </h2>
          </GridItem>
          <Hidden mdUp><GridItem xs={1} /></Hidden>
          <GridItem xs={10} md={8} lg={7}>
            <ProductMetrics
              metrics={productInfo.metrics}
              metricColor="white"
              valueColor={theme.palette.grayScale8.main}
            />
          </GridItem>
          <Hidden mdUp><GridItem xs={1} /></Hidden>
        </GridContainer>
      </div>

      <div className={classes.testimonials}>
        <Testimonials />
      </div>
    </div>
  );
};

export default ProductLayout1;
