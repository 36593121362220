import { container, hexToRgb } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  root: {
    padding: '72px 0',

    [theme.breakpoints.down('sm')]: {
      padding: '50px 0',
    },
  },
  container,
  left: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  avatarImage: {
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '290px',
      height: '321px',
    },
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textContainer: {
    marginLeft: '80px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '8px',
    },
  },
  subtitle: {
    fontSize: '20px',
    lineHeight: '30px',
    margin: '0',
    marginLeft: '8px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  text: {
    fontWeight: '500',
    fontStyle: 'italic',
    marginTop: '16px',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '120%',
      height: '65px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '32px',
      lineHeight: '132%',
      height: '165px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '42px',
      lineHeight: '132%',
      height: '165px',
    },
  },
  bio: {
    fontSize: '20px',
    lineHeight: '30px',
    marginTop: '10px',
    marginBottom: '18px',
    marginLeft: '8px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  dots: {
    marginLeft: '8px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  dot: {
    display: 'inline-block',
    width: '11px',
    height: '11px',
    marginRight: '11px',
    borderRadius: '50%',
    backgroundColor: `rgba(${hexToRgb(theme.palette.white.main)}, .5)`,

    [theme.breakpoints.down('sm')]: {
      width: '8px',
      height: '8px',
    },
  },
  activeDot: {
    backgroundColor: theme.palette.orange.main,
  },
});

export default style;
