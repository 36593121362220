import React from 'react';

const mailOutFeePerPiece = 1; // $1.00, used on marketing pages

const productMarketingInfo = {
  'Absentee Owners': {
    numCommitmentMonths: 6,
    membershipFeePerMonth: 75,
    minimumNumRecords: 100,
    mailOutFeePerPiece,
    numVoicemails: 2,
    numLetters: 2,
    numEmails: 2,
    numPostcards: 4,
    youtubeVideoId: 'dQw4w9WgXcQ',
    pricingPageDescription: 'These touches are specifically designed to inform those owners of the benefits.',
    heroText1: 'The numbers prove that absentee owners are the most likely type of homeowner to sell.',
    heroText2: 'Statistically, there\'s a 10-20% more chance they are ready because the process of renting out a property may be getting tiresome, or they have held this investment and are ready to cash out.',
    whatsIncludedText: (
      <p>
        Our Harma Data Absentee Owner Campaign gives you exclusive access to the zip code you choose (upon availability), focuses on your choice of single-family residential, condominiums, townhouses, and 2-4 unit investment properties, and will send personalized postcards, letters, emails, and voicemails on your behalf.
        <br />
        <br />
        These touches are specifically designed to inform those owners of the benefits of selling their property and trigger an action plan to contact you.
        <br />
        <br />
        If you are interested in using this product, we suggest you concentrate on an area you know well, and have the skillset and scripts to convert these leads into sellers.
      </p>
    ),
    metrics: [
      {
        metric: 'Mailers Sent/m',
        value: '304,023',
      },
      {
        metric: 'Conversions/m',
        value: '13,402',
      },
      {
        metric: 'Leads Served',
        value: '54,992',
      },
      {
        metric: 'Hours Saved/m',
        value: '3194',
      },
    ],
  },
  NOD: {
    numCommitmentMonths: 6,
    membershipFeePerMonth: 75,
    minimumNumRecords: 100,
    mailOutFeePerPiece,
    numVoicemails: 2,
    numLetters: 2,
    numEmails: 2,
    numPostcards: 4,
    youtubeVideoId: 'dQw4w9WgXcQ',
    pricingPageDescription: 'These touches are specifically designed to inform those owners of the benefits.',
    heroText1: 'The numbers prove that absentee owners are the most likely type of homeowner to sell.',
    heroText2: 'Statistically, there\'s a 10-20% more chance they are ready because the process of renting out a property may be getting tiresome, or they have held this investment and are ready to cash out.',
    whatsIncludedText: (
      <p>
        Our Harma Data Absentee Owner Campaign gives you exclusive access to the zip code you choose (upon availability), focuses on your choice of single-family residential, condominiums, townhouses, and 2-4 unit investment properties, and will send personalized postcards, letters, emails, and voicemails on your behalf.
        <br />
        <br />
        These touches are specifically designed to inform those owners of the benefits of selling their property and trigger an action plan to contact you.
        <br />
        <br />
        If you are interested in using this product, we suggest you concentrate on an area you know well, and have the skillset and scripts to convert these leads into sellers.
      </p>
    ),
    metrics: [
      {
        metric: 'Mailers Sent/m',
        value: '304,023',
      },
      {
        metric: 'Conversions/m',
        value: '13,402',
      },
      {
        metric: 'Leads Served',
        value: '54,992',
      },
      {
        metric: 'Hours Saved/m',
        value: '3194',
      },
    ],
  },
  Commercial: {
    numCommitmentMonths: 6,
    membershipFeePerMonth: 75,
    minimumNumRecords: 100,
    mailOutFeePerPiece,
    numVoicemails: 2,
    numLetters: 2,
    numEmails: 2,
    numPostcards: 4,
    youtubeVideoId: 'dQw4w9WgXcQ',
    pricingPageDescription: 'These touches are specifically designed to inform those owners of the benefits.',
    heroText1: 'The numbers prove that absentee owners are the most likely type of homeowner to sell.',
    heroText2: 'Statistically, there\'s a 10-20% more chance they are ready because the process of renting out a property may be getting tiresome, or they have held this investment and are ready to cash out.',
    whatsIncludedText: (
      <p>
        Our Harma Data Absentee Owner Campaign gives you exclusive access to the zip code you choose (upon availability), focuses on your choice of single-family residential, condominiums, townhouses, and 2-4 unit investment properties, and will send personalized postcards, letters, emails, and voicemails on your behalf.
        <br />
        <br />
        These touches are specifically designed to inform those owners of the benefits of selling their property and trigger an action plan to contact you.
        <br />
        <br />
        If you are interested in using this product, we suggest you concentrate on an area you know well, and have the skillset and scripts to convert these leads into sellers.
      </p>
    ),
    metrics: [
      {
        metric: 'Mailers Sent/m',
        value: '304,023',
      },
      {
        metric: 'Conversions/m',
        value: '13,402',
      },
      {
        metric: 'Leads Served',
        value: '54,992',
      },
      {
        metric: 'Hours Saved/m',
        value: '3194',
      },
    ],
  },
  'CRM Pro': {
    numCommitmentMonths: 6,
    membershipFeePerMonth: 75,
    minimumNumRecords: 100,
    mailOutFeePerPiece,
    numVoicemails: 2,
    numLetters: 2,
    numEmails: 2,
    numPostcards: 4,
    youtubeVideoId: 'dQw4w9WgXcQ',
    pricingPageDescription: 'These touches are specifically designed to inform those owners of the benefits.',
    heroText1: 'The numbers prove that absentee owners are the most likely type of homeowner to sell.',
    heroText2: 'Statistically, there\'s a 10-20% more chance they are ready because the process of renting out a property may be getting tiresome, or they have held this investment and are ready to cash out.',
    whatsIncludedText: (
      <p>
        Our Harma Data Absentee Owner Campaign gives you exclusive access to the zip code you choose (upon availability), focuses on your choice of single-family residential, condominiums, townhouses, and 2-4 unit investment properties, and will send personalized postcards, letters, emails, and voicemails on your behalf.
        <br />
        <br />
        These touches are specifically designed to inform those owners of the benefits of selling their property and trigger an action plan to contact you.
        <br />
        <br />
        If you are interested in using this product, we suggest you concentrate on an area you know well, and have the skillset and scripts to convert these leads into sellers.
      </p>
    ),
    metrics: [
      {
        metric: 'Mailers Sent/m',
        value: '304,023',
      },
      {
        metric: 'Conversions/m',
        value: '13,402',
      },
      {
        metric: 'Leads Served',
        value: '54,992',
      },
      {
        metric: 'Hours Saved/m',
        value: '3194',
      },
    ],
  },
  'Full Farm': {
    numCommitmentMonths: 6,
    membershipFeePerMonth: 75,
    minimumNumRecords: 100,
    mailOutFeePerPiece,
    numVoicemails: 2,
    numLetters: 2,
    numEmails: 2,
    numPostcards: 4,
    youtubeVideoId: 'dQw4w9WgXcQ',
    pricingPageDescription: 'These touches are specifically designed to inform those owners of the benefits.',
    heroText1: 'The numbers prove that absentee owners are the most likely type of homeowner to sell.',
    heroText2: 'Statistically, there\'s a 10-20% more chance they are ready because the process of renting out a property may be getting tiresome, or they have held this investment and are ready to cash out.',
    whatsIncludedText: (
      <p>
        Our Harma Data Absentee Owner Campaign gives you exclusive access to the zip code you choose (upon availability), focuses on your choice of single-family residential, condominiums, townhouses, and 2-4 unit investment properties, and will send personalized postcards, letters, emails, and voicemails on your behalf.
        <br />
        <br />
        These touches are specifically designed to inform those owners of the benefits of selling their property and trigger an action plan to contact you.
        <br />
        <br />
        If you are interested in using this product, we suggest you concentrate on an area you know well, and have the skillset and scripts to convert these leads into sellers.
      </p>
    ),
    metrics: [
      {
        metric: 'Mailers Sent/m',
        value: '304,023',
      },
      {
        metric: 'Conversions/m',
        value: '13,402',
      },
      {
        metric: 'Leads Served',
        value: '54,992',
      },
      {
        metric: 'Hours Saved/m',
        value: '3194',
      },
    ],
  },
};

const whyChooseUsText = {
  points: [
    'Don’t have the time to send out direct mail to your audience?',
    'Having trouble finding an audience that gives you ideas?',
    'Tired of keeping your marketing materials fresh and up to date?',
  ],
  body: (
    <p>
      Agent Data uses a touch system to reach out to your clients and/or farm on your behalf. A touch is a marketing piece used in your campaign that is sent from you when you sign up for one of our products.
      <br />
      <br />
      We use data from previous campaigns to distinguish which touches yield the best return on your investment and may or may not alter the duration of a campaign, number of touches, type of touch, and content in these touches for any of our products based on this data.
    </p>
  ),
};

export { productMarketingInfo, whyChooseUsText };
