import React from 'react';
import cx from 'classnames';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import SupportContactCard from 'components/SupportContactCard/SupportContactCard';
import Testimonials from 'components/Testimonials/Testimonials';
import ProductMetrics from 'components/Product/ProductMetrics';

import { whyChooseUsText } from 'utils/productMarketingInfo';

import styles from 'assets/jss/material-dashboard-pro-react/views/caseStudiesPageStyle';

// TODO: TEMP
import companyLogo from 'assets/img/case-studies/building.png';

const useStyles = makeStyles(styles);

// TODO: Later we add a dropdown to "Case Studies" in the navbar for different companies?

// TODO: typescript: color: 'orange' | 'white'
const CaseStudiesPage = () => {
  const color = 'orange';
  const theme = useTheme();
  const classes = useStyles({ color });

  const companyName = 'Acme Inc.';
  const companyDescription = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, bu';
  const tags = ['Case Study', 'Absentee Owner'];
  const companyMetrics = [
    {
      metric: 'Mailers Sent/m',
      value: '304,023',
    },
    {
      metric: 'Conversions/m',
      value: '13,402',
    },
    {
      metric: 'Leads Served',
      value: '54,992',
    },
    {
      metric: 'Hours Saved/m',
      value: '3194',
    },
  ];
  const challengeText = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, PageMaker including versions of Lorem Ipsum.since the 1500s, PageMaker including versions of Lorem Ipsum.since the 1500s, PageMaker including versions of .';
  const solutionText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis tincidunt id aliquet risus feugiat. Dignissim diam quis enim lobortis scelerisque. Pellentesque adipiscing commodo elit at imperdiet dui accumsan. Lectus vestibulum mattis ullamcorper velit. Id diam vel quam elementum pulvinar etiam non quam lacus. A erat nam at lectus urna duis. Vel pretium lectus quam id leo. Quis auctor elit sed vulputate mi sit amet mauris commodo. Viverra adipiscing at in tellus integer feugiat scelerisque. Maecenas volutpat blandit aliquam etiam erat velit scelerisque in dictum. Elementum nisi quis eleifend quam adipiscing vitae proin sagittis. Imperdiet proin fermentum leo vel orci. Adipiscing diam donec adipiscing tristique risus nec feugiat. Risus in hendrerit gravida rutrum.

  Sit amet volutpat consequat mauris. At elementum eu facilisis sed odio morbi. Ornare suspendisse sed nisi lacus sed viverra tellus in hac. Volutpat ac tincidunt vitae semper quis lectus. Nisl pretium fusce id velit ut tortor pretium viverra. Dignissim diam quis enim lobortis scelerisque fermentum dui faucibus in. Accumsan lacus vel facilisis volutpat est velit egestas dui id. Congue eu consequat ac felis donec et odio pellentesque. Cum sociis natoque penatibus et magnis dis parturient montes nascetur. Lobortis elementum nibh tellus molestie nunc non blandit massa. Purus non enim praesent elementum facilisis leo vel fringilla est. Amet nisl suscipit adipiscing bibendum. Quam nulla porttitor massa id neque aliquam vestibulum morbi. Donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Cursus in hac habitasse platea dictumst quisque. Sit amet aliquam id diam maecenas ultricies mi eget.

  Vehicula ipsum a arcu cursus vitae congue mauris rhoncus. Netus et malesuada fames ac turpis egestas. Volutpat est velit egestas dui id ornare arcu odio ut. Lobortis mattis aliquam faucibus purus. Libero volutpat sed cras ornare arcu dui. Nec feugiat nisl pretium fusce. Erat nam at lectus urna duis convallis convallis tellus id. Nunc faucibus a pellentesque sit amet. Purus viverra accumsan in nisl. Augue mauris augue neque gravida in fermentum et sollicitudin.`;

  const [isSolutionTextExpanded, setIsSolutionTextExpanded] = React.useState(false);

  // TODO: TRANSPARENT NAVBAR HERE for all sizes
  return (
    <div className={classes.root}>

      <div className={classes.hero}>
        <div className={classes.heroOverlay} />

        <GridContainer className={classes.container}>
          <GridItem xs={12} md={6} className={classes.headerLeft}>
            <div className={classes.companyLogo}>
              <img alt={companyName} src={companyLogo} />
            </div>
            <Hidden smDown>
              <div className={classes.tagsList}>
                {tags.map((tag) => (
                  <span key={tag}>{tag}</span>
                ))}
              </div>
            </Hidden>
          </GridItem>
          <GridItem xs={12} md={6} className={classes.headerRight}>
            <h1>{companyName}</h1>
            <p>{companyDescription}</p>
            <h3>What success looks like:</h3>
            <div className={classes.productMetrics}>
              <ProductMetrics
                metrics={companyMetrics}
                metricColor="white"
                valueColor={theme.palette.grayScale8.main}
              />
            </div>
            <Hidden mdUp>
              <div className={classes.tagsList}>
                {tags.map((tag) => (
                  <span key={tag}>{tag}</span>
                ))}
              </div>
            </Hidden>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.studyBackground}>
        <GridContainer className={classes.container}>
          <GridItem md={12}>
            <div className={cx(classes.studyCard, classes.studyChallenge)}>
              <h4>Challenge:</h4>
              <p>{challengeText}</p>
              <div>
                <div />
              </div>
            </div>
          </GridItem>
          <GridItem md={12}>
            <div className={cx(classes.studyCard, classes.studySolution)}>
              <h4>Solution:</h4>
              {isSolutionTextExpanded ? (
                <p>{solutionText}</p>
              ) : (
                <>
                  <p style={{ maxHeight: '72px' }}>{solutionText}</p>
                  <Button
                    color="primary"
                    simple
                    underlined
                    className={classes.brokerageAccountButton}
                    onClick={() => setIsSolutionTextExpanded(true)}
                  >
                    Read More
                  </Button>
                </>
              )}
            </div>
          </GridItem>
        </GridContainer>

        <div className={classes.supportCard}>
          <SupportContactCard color="white" />
        </div>
      </div>

      <div className={classes.testimonials}>
        <Testimonials />
      </div>

      <div className={classes.whyChooseUs}>
        <div className={classes.whyChooseUsOrangeDots} />
        <div className={classes.whyChooseUsBuilding} />

        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.whyChooseUsHeader}>
            <h2>
              Why Choose Us
              <span />
            </h2>
          </GridItem>

          {whyChooseUsText.points.map((text, i) => (
            <GridItem xs={12} md={4} className={classes.whyChooseUsPoints} key={i}>
              <h3>
                {`0${i + 1}`}
                <span />
              </h3>
              <p>
                {text}
              </p>
            </GridItem>
          ))}

          <Hidden smDown>
            <GridItem md={6} className={classes.whyChooseUsBody}>
              {whyChooseUsText.body}
            </GridItem>
            <GridItem md={6} />
          </Hidden>

        </GridContainer>
      </div>
    </div>
  );
};

export default CaseStudiesPage;
