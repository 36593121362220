import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import SupportContactCard from 'components/SupportContactCard/SupportContactCard';
import CommonQuestions from 'components/CommonQuestions/CommonQuestions';
import Testimonials from 'components/Testimonials/Testimonials';
import ProductFeature from 'components/Product/ProductFeature';

import styles from 'assets/jss/material-dashboard-pro-react/views/brokerageAccountsPageStyle';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles(styles);

const BulletPoint = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.bulletPointText}>
      <Check />
      {text}
    </div>
  );
};

const BrokerageAccountsPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <div className={classes.hero}>
        <div className={classes.heroOverlayColor} />

        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.header}>
            <h1>Brokerage Accounts</h1>
            <div />
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.detailsContainer}>
              <h3>
                What You'll Get
                <span />
              </h3>
              <div className={classes.detailsInfo}>
                <div style={{ flexGrow: 2 }}>
                  <ProductFeature color="orange" text1="Commitment" text2="6" text3="/months" />
                  <ProductFeature color="orange" text1="Mailing Fee" text2="$1" text3="/months" />
                  <ProductFeature color="orange" text1="Min. # of Records" text2="100" />
                </div>
                <Hidden mdUp><br /></Hidden>
                <div style={{ flexGrow: 1 }}>
                  <BulletPoint text="2 Letters / month" />
                  <BulletPoint text="2 Postcards / month" />
                  <BulletPoint text="2 Emails / month" />
                  <BulletPoint text="2 Voicemails / month" />
                  <BulletPoint text="24/7 Live Support" />
                </div>
                <Hidden mdUp><br /></Hidden>
                <div style={{ flexGrow: 1 }}>
                  <BulletPoint text="2 Letters / month" />
                  <BulletPoint text="2 Postcards / month" />
                  <BulletPoint text="2 Emails / month" />
                  <BulletPoint text="2 Voicemails / month" />
                  <BulletPoint text="24/7 Live Support" />
                </div>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consec tetur adipiscing elit. Nam condimentum tempus diam, ultricies sollicitudin erat facilisis eget. Vestibulum rhoncus dui vel eros laoreet consectetur. Vivamus eget elementum ligula, vitae pharetra quam. Nullam at ligula sed metu.
              </p>

              <div className={classes.person} />

              <div className={classes.supportCard}>
                <SupportContactCard color="orange" />
              </div>
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <CommonQuestions color="white" />

      <div className={classes.testimonials}>
        <Testimonials />
      </div>
    </div>
  );
};

export default BrokerageAccountsPage;
