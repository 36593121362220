import { container } from 'assets/jss/material-dashboard-pro-react';

import heroImage from 'assets/img/brokerage-accounts/hero.png';
import personImage from 'assets/img/brokerage-accounts/person.png';

const style = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  container: {
    ...container,
    position: 'relative',
  },
  hero: {
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  heroOverlayColor: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.darkBlueGray.main,
    mixBlendMode: 'hard-light',
  },

  header: {
    '& > h1': {
      margin: '64px 0 0',
      textAlign: 'center',
      fontSize: '51px',
      lineHeight: '60px',
      color: theme.palette.white.main,

      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
    '& > div': {
      margin: '6px auto 0',
      width: '281px',
      height: '0px',
      border: `2px solid ${theme.palette.primary.main}`,

      [theme.breakpoints.down('sm')]: {
        width: '180px',
      },
    },
  },

  detailsContainer: {
    position: 'relative',
    background: 'linear-gradient(180deg, #A2E7F6 0%, #FFFFFF 58.85%)',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
    borderRadius: '16px',
    margin: '67px 25px 143px',
    padding: '66px 100px',

    [theme.breakpoints.down('sm')]: {
      padding: '40px 16px',
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.only('md')]: {
      padding: '50px 50px 300px',
    },

    '& > h3': {
      margin: '0 0 24px',
      color: theme.palette.darkGray.main,
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'bold',
    },
    '& > h3 > span': {
      display: 'inline-block',
      width: '24px',
      height: '0px',
      border: `2px solid ${theme.palette.orange.main}`,
      marginLeft: '10px',
    },
    '& > p': {
      color: theme.palette.darkGray.main,
      fontSize: '20px',
      lineHeight: '30px',
      margin: '48px 0 0',

      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        fontSize: '14px',
        lineHeight: '20px',
        margin: '28px 0 228px',
      },
      [theme.breakpoints.only('md')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '520px',
      },
    },
  },
  detailsInfo: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '720px',
    },
  },
  bulletPointText: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.darkGray.main,
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '12px',
    '& > svg': {
      color: `${theme.palette.orange.main} !important`,
      marginRight: '4px',
    },
  },

  person: {
    background: `url(${personImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'absolute',

    [theme.breakpoints.down('sm')]: {
      left: 'calc(50% - calc(402px * 0.6) / 2)', // Subtract half of width
      bottom: '36px',
      width: 'calc(402px * 0.6)',

      // Cut off head
      height: 'calc(451px * 0.45)',
      backgroundSize: 'cover',
    },
    [theme.breakpoints.only('md')]: {
      left: '258px',
      top: '456px',
      width: 'calc(402px * 0.6)',
      height: 'calc(451px * 0.6)',
    },
    [theme.breakpoints.only('lg')]: {
      right: '50px',
      top: '235px',
      width: 'calc(402px * 0.6)',
      height: 'calc(451px * 0.6)',
    },
    [theme.breakpoints.only('xl')]: {
      right: '63px',
      top: '110px',
      width: '402px',
      height: '451px',
    },
  },
  supportCard: {
    position: 'absolute',
    right: '0px',
    bottom: '-72px', // Half the height of the support card

    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      width: '100%',
      left: '0px',
      right: '0px',
      display: 'flex',
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '-52px', // Half the height of the support card
    },
  },

  testimonials: {
    backgroundColor: theme.palette.primary.main,
  },
});

export default style;
