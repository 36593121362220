import _ from 'lodash';
import React from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';

import { Tooltip, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';
import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import AddressForm, { initialAddress } from 'components/AddressAutocomplete/AddressForm';

import { stringifyAddressObject } from 'utils/lib';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '800px',
    // margin: 'auto',
  },
  title: {
    margin: '30px 0 6px',
    fontWeight: 'bold',
  },
  subTitle: {
    fontSize: '14px',
    color: theme.palette.gray.main,
    fontWeight: 500,
  },
  addressesChipsList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: '18px 0 10px',
  },
  addressesChip: {
    margin: theme.spacing(0.5),
    fontSize: '14px',
  },
}));

const ExtraMailOutAddressesInput = ({ setExtraMailOutsCount }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const {
    values, touched, errors, setFieldValue, setTouched, isValid,
  } = useFormikContext();

  // console.log('touched', touched);
  // console.log('errors', errors);

  // We don't use the formik dirty function because if chips change,
  // (the parent extraMailOutAddresses value), we don't want the form
  // to be considered dirty. We basically check if they touched one of
  // the actual fields here
  // const customDirty = Object.keys(touched).length !== 0 && !touched.extraMailOutAddresses; // TODO: Can't reproduce why this was here
  const customDirty = Object.keys(touched).length !== 0;

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>
        Want to receive the same marketing touches yourself or send them to other people? Add their info here!
      </h4>
      <h5 className={classes.subTitle}>
        The cost of mail-outs for each additional address will be added to your monthly price.
      </h5>
      <FieldArray
        name="extraMailOutAddresses"
        render={(arrayHelpers) => {
          const toggleSelect = (o) => {
            const oIndex = values.extraMailOutAddresses.indexOf(o);

            if (oIndex === -1) {
              arrayHelpers.push(o);
              setExtraMailOutsCount(values.extraMailOutAddresses.length + 1);
            } else {
              arrayHelpers.remove(oIndex);
              setExtraMailOutsCount(values.extraMailOutAddresses.length - 1);
            }
          };

          return (
            <>
              <ul className={classes.addressesChipsList}>
                {values.extraMailOutAddresses.map(((o, index) => (
                  <li key={index}>
                    <Chip
                      icon={(
                        <Tooltip
                          placement="top"
                          disableFocusListener
                          disableTouchListener
                          title={(
                            <>
                              {o.email && (
                              <>
                                {`Email: ${o.email}`}
                                <br />
                              </>
                              )}
                              {o.phoneNumber && (
                              <>
                                {`Phone Number: ${o.phoneNumber}`}
                                <br />
                              </>
                              )}
                              {stringifyAddressObject(o.address)}
                            </>
                          )}
                        >
                          <InfoOutlinedIcon />
                        </Tooltip>
                      )}
                      color="primary"
                      label={`${o.firstName} ${o.lastName}`}
                      onDelete={() => toggleSelect(o)}
                      className={classes.addressesChip}
                    />
                  </li>
                )))}
              </ul>

              <GridContainer>
                <GridItem xs={12} md={6}>
                  <Field
                    name="firstName"
                  >
                    {({ field }) => (
                      <CustomInputDashboard
                        labelText="First Name"
                        margin="normal"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          ...field,
                          autoComplete: 'given-name',
                          maxLength: 50,
                        }}
                        error={customDirty && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Field
                    name="lastName"
                  >
                    {({ field }) => (
                      <CustomInputDashboard
                        labelText="Last Name"
                        margin="normal"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          ...field,
                          autoComplete: 'family-name',
                          maxLength: 50,
                        }}
                        error={customDirty && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Field
                    name="email"
                  >
                    {({ field }) => (
                      <CustomInputDashboard
                        labelText="Email (Optional)"
                        margin="normal"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          ...field,
                          autoComplete: 'off',
                        }}
                        error={customDirty && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Field
                    name="phoneNumber"
                  >
                    {({ field }) => (
                      <CustomInputDashboard
                        labelText="Phone Number (Optional)"
                        margin="normal"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          ...field,
                          type: 'tel',
                          autoComplete: 'off',
                        }}
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        error={customDirty && errors[field.name] !== undefined}
                      />
                    )}
                  </Field>
                </GridItem>
              </GridContainer>
              <AddressForm
                // Change key everytime we add/remove an address to reset the AddressAutocomplete field, since it has its own internal value
                key={values.extraMailOutAddresses.length}
                classes={classes}
                valueName="address"
                addressDisplayName="Address"
                initialValue=""
                showErrorsWhen="formDirty"
                customDirty={customDirty}
              />

              <Button
                round
                color="orange"
                // disabled={customDirty && !isValid}
                disabled={!customDirty}
                onClick={() => {
                  // The current typed address is stored in the formik 'address' field

                  if (!values.address.location.lat) { // Make sure they actually selected an address
                    setCurrentAlert('warning', 'Please enter an address!');
                  } else if (!values.firstName || !values.lastName) { // Make sure they entered a name
                    setCurrentAlert('warning', 'Please enter a name!');
                  } else if (values.extraMailOutAddresses.filter((o) => _.isEqual(o, values.address)).length === 0) {
                    toggleSelect({
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      phoneNumber: values.phoneNumber,
                      address: values.address,
                    });

                    setFieldValue('firstName', '', true);
                    setFieldValue('lastName', '', true);
                    setFieldValue('email', '', true);
                    setFieldValue('phoneNumber', '', true);
                    setFieldValue('address', { ...initialAddress }, true);
                    setTouched({}, false);
                  }
                }}
              >
                Add Recipient
              </Button>
            </>
          );
        }}
      />
    </div>
  );
};

export default ExtraMailOutAddressesInput;
