import styles from 'assets/jss/material-dashboard-pro-react/modalStyle';
import PropTypes from 'prop-types';
import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

const useStyles = makeStyles(styles);

const ConfirmModal = ({ message, onConfirm, onClose }) => {
  const classes = useStyles();
  // Maintain internal error message to prevent flicker bug when closing
  const [m, setM] = React.useState('');

  if (m !== message && message !== '') setM(message);

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={message !== ''}
      keepMounted
      onClose={onClose}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogContent
        id="modal-slide-description"
        className={classes.modalBody}
      >
        <h5>{m}</h5>
      </DialogContent>
      <DialogActions
        className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
      >
        <Button onClick={onClose} color="transparent">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="danger">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmModal;
