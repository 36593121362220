import React from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';
import Button from 'creative-components/CustomButtons/Button';

import HorizontalTimeline from 'components/HorizontalTimeline/HorizontalTimeline';

import { getIconForTouchType } from 'utils/lib';

import styles from 'assets/jss/material-dashboard-pro-react/components/productInfoStyle';
import { infoColor } from 'assets/jss/material-dashboard-pro-react';

import absFlyer from 'assets/img/products/abs-flyer.jpg';
import commercialFlyer from 'assets/img/products/commercial-flyer.jpg';
import nodsFlyer from 'assets/img/products/nods-flyer.jpg';
import crmProFlyer from 'assets/img/products/crm-pro-flyer.jpg';

const useStyles = makeStyles(styles);

const mailingFee = '$1 / piece';

const createTimelineEntries = (touchesArray) => touchesArray.map(({ title, touchType }) => {
  const badgeColor = 'white';
  const badgeIconColor = infoColor[0];
  const badgeBorderColor = 'info';
  const largeBadge = false;

  const badgeIcon = getIconForTouchType(touchType);

  return {
    badgeColor,
    badgeBorderColor,
    badgeIcon,
    badgeIconColor,
    blinkBadge: false,
    largeBadge,
    title,
    titleColor: badgeColor,
    disableModal: true,
  };
});

const productDescriptions = {
  'Absentee Owners': {
    commitment: '6 months',
    membershipFee: '$75 / month',
    recordsLimitText: 'Minimum Number of Records:',
    recordsLimitValue: '100',
    touches: [
      {
        text: '4 Postcards',
        touchType: 'POSTCARD',
      },
      {
        text: '2 Letters',
        touchType: 'LETTER',
      },
      {
        text: '2 Emails',
        touchType: 'EMAIL',
      },
      {
        text: '2 Voicemails',
        touchType: 'VOICEMAIL',
      },
    ],
    totalNumTouches: 10,
    youtubeVideoId: '3JeftLPSG_0',
    flyerImage: absFlyer,
    description: (
      <p>
        The numbers prove that absentee owners are the most likely type of homeowner to sell. Statistically, there's a 10-20% more chance they are ready because the process of renting out a property may be getting tiresome, or they have held this investment and are ready to cash out.
        <br />
        <br />
        Our Harvist Absentee Owner Campaign gives you exclusive access to the zip code you choose (upon availability), focuses on your choice of single-family residential, condominiums, townhouses, and 2-4 unit investment properties, and will send personalized postcards, letters, emails, and voicemails on your behalf. These touches are specifically designed to inform those owners of the benefits of selling their property and trigger an action plan to contact you.
        <br />
        <br />
        If you are interested in using this product, we suggest you concentrate on an area you know well, and have the skillset and scripts to convert these leads into sellers.
      </p>
    ),
    timelineEntries: createTimelineEntries([
      {
        title: 'Email #1',
        touchType: 'EMAIL',
      },
      {
        title: 'Postcard #1',
        touchType: 'POSTCARD',
      },
      {
        title: 'Letter #1',
        touchType: 'LETTER',
      },
      {
        title: 'Voicemail #1',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Postcard #2',
        touchType: 'POSTCARD',
      },
      {
        title: 'Email #2',
        touchType: 'EMAIL',
      },
      {
        title: 'Letter #2',
        touchType: 'LETTER',
      },
      {
        title: 'Postcard #3',
        touchType: 'POSTCARD',
      },
      {
        title: 'Voicemail #2',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Postcard #4',
        touchType: 'POSTCARD',
      },
    ]),
  },
  NOD: {
    commitment: '9 months',
    membershipFee: '$30 / month',
    recordsLimitText: 'Maximum Number of Records:',
    recordsLimitValue: '100',
    recordsLimitDisclaimer: 'The number of NOD records may fluctuate during your campaign. Any newly recorded NODs for 3 months from the start of your campaign will be automatically added to your farm.',
    touches: [
      {
        text: '7 Postcards',
        touchType: 'POSTCARD',
      },
      {
        text: '4 Letters',
        touchType: 'LETTER',
      },
      {
        text: '4 Emails',
        touchType: 'EMAIL',
      },
      {
        text: '3 Voicemails',
        touchType: 'VOICEMAIL',
      },
    ],
    totalNumTouches: 18,
    youtubeVideoId: 'KpE02ke9Auo',
    flyerImage: nodsFlyer,
    description: (
      <p>
        Every week, new notice of defaults will be recorded in your county. Although some owners might make their payment and get into good standing, statistics show that over 70% of people who've received an NOD end up selling, foreclosing, or making other adjustments such as filing for bankruptcy or transferring title.
        <br />
        <br />
        Our Harvist NOD Campaign gives you exclusive access to the zip code you choose (upon availability), focuses on your choice of single-family residential, condominiums, townhouses, and 2-4 unit investment properties, and will send personalized postcards, letters, emails, and voicemails on your behalf. These touches will inform those owners that you're the local expert when it comes to NODs and Short Sales and that you understand their situation and can explain their options. Our NOD product is designed to trigger a specific action plan to get these homeowners to contact you.
        <br />
        <br />
        If you are interested in using this product, we suggest you concentrate on an area you know well, and have the skillset and scripts to work with distressed property owners.
      </p>
    ),
    timelineEntries: createTimelineEntries([
      {
        title: 'Email #1',
        touchType: 'EMAIL',
      },
      {
        title: 'Postcard #1',
        touchType: 'POSTCARD',
      },
      {
        title: 'Letter #1',
        touchType: 'LETTER',
      },
      {
        title: 'Voicemail #1',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Postcard #2',
        touchType: 'POSTCARD',
      },
      {
        title: 'Email #2',
        touchType: 'EMAIL',
      },
      {
        title: 'Letter #2',
        touchType: 'LETTER',
      },
      {
        title: 'Postcard #3',
        touchType: 'POSTCARD',
      },
      {
        title: 'Voicemail #2',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Postcard #4',
        touchType: 'POSTCARD',
      },
      {
        title: 'Email #3',
        touchType: 'EMAIL',
      },
      {
        title: 'Letter #3',
        touchType: 'LETTER',
      },
      {
        title: 'Postcard #5',
        touchType: 'POSTCARD',
      },
      {
        title: 'Voicemail #3',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Postcard #6',
        touchType: 'POSTCARD',
      },
      {
        title: 'Email #4',
        touchType: 'EMAIL',
      },
      {
        title: 'Letter #4',
        touchType: 'LETTER',
      },
      {
        title: 'Postcard #7',
        touchType: 'POSTCARD',
      },
    ]),
  },
  Commercial: {
    commitment: '12 months',
    membershipFee: '$99 / month',
    recordsLimitText: 'Minimum Number of Records:',
    recordsLimitValue: '100',
    touches: [
      {
        text: '8 Postcards',
        touchType: 'POSTCARD',
      },
      {
        text: '6 Letters',
        touchType: 'LETTER',
      },
      {
        text: '6 Emails',
        touchType: 'EMAIL',
      },
      {
        text: '4 Voicemails',
        touchType: 'VOICEMAIL',
      },
    ],
    totalNumTouches: 24,
    youtubeVideoId: 'oIryE1Zm3tc',
    flyerImage: commercialFlyer,
    description: (
      <p>
        We noticed that the new generation of apartment owners aren't responding as well to old-school methods such as cold calling. Commercial agents are finding they need new creative ways to approach lead generation to find fresh leads.
        <br />
        <br />
        Our Harvist NOD Campaign gives you exclusive access to the area of your choice (upon availability) and allows you to choose from either 4-10 unit or 10+ unit apartment buildings. From there, we will send personalized postcards, letters, emails, and voicemails on your behalf. These touches will inform property owners that you're the expert when it comes to apartment buildings in their area and provides them with informative information while triggering a specific action plan to get these investors to contact you.
        <br />
        <br />
        If you are interested in using this product, we suggest you concentrate on an area you know well, have the skillset to work with apartment building owners, understand CAP rates, can calculate building operating expenses, have sold or been active in the area, and have practiced scripts so you can convert these leads into sales.
      </p>
    ),
    timelineEntries: createTimelineEntries([
      {
        title: 'Email #1',
        touchType: 'EMAIL',
      },
      {
        title: 'Postcard #1',
        touchType: 'POSTCARD',
      },
      {
        title: 'Letter #1',
        touchType: 'LETTER',
      },
      {
        title: 'Postcard #2',
        touchType: 'POSTCARD',
      },
      {
        title: 'Email #2',
        touchType: 'EMAIL',
      },
      {
        title: 'Voicemail #1',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Letter #2',
        touchType: 'LETTER',
      },
      {
        title: 'Postcard #3',
        touchType: 'POSTCARD',
      },
      {
        title: 'Email #3',
        touchType: 'EMAIL',
      },
      {
        title: 'Postcard #4',
        touchType: 'POSTCARD',
      },
      {
        title: 'Letter #3',
        touchType: 'LETTER',
      },
      {
        title: 'Voicemail #2',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Email #4',
        touchType: 'EMAIL',
      },
      {
        title: 'Postcard #5',
        touchType: 'POSTCARD',
      },
      {
        title: 'Letter #4',
        touchType: 'LETTER',
      },
      {
        title: 'Postcard #6',
        touchType: 'POSTCARD',
      },
      {
        title: 'Email #5',
        touchType: 'EMAIL',
      },
      {
        title: 'Voicemail #3',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Letter #5',
        touchType: 'LETTER',
      },
      {
        title: 'Postcard #7',
        touchType: 'POSTCARD',
      },
      {
        title: 'Email #6',
        touchType: 'EMAIL',
      },
      {
        title: 'Postcard #8',
        touchType: 'POSTCARD',
      },
      {
        title: 'Letter #6',
        touchType: 'LETTER',
      },
      {
        title: 'Voicemail #4',
        touchType: 'VOICEMAIL',
      },
    ]),
  },
  'CRM Pro': {
    commitment: '12 months',
    membershipFee: '$25 / month',
    recordsLimitText: 'Minimum Number of Records:',
    recordsLimitValue: '100',
    touches: [
      {
        text: '4 Postcards',
        touchType: 'POSTCARD',
      },
      {
        text: '4 Emails',
        touchType: 'EMAIL',
      },
      {
        text: '2 Voicemails',
        touchType: 'VOICEMAIL',
      },
    ],
    totalNumTouches: 10,
    youtubeVideoId: 'Ysgw5Vtx14s',
    flyerImage: crmProFlyer,
    description: (
      <p>
        Did you know that only 47% of people list their home with the same agent who helped them buy it? The #1 source of referrals after providing good service is past clients, but many agents don't nurture a relationship with their clients after a transaction. Most agents can't manage a database or don't have the creativity to implement a strategic approach to keep in touch.
        <br />
        <br />
        Our Harvist CRM Pro Campaign is geared toward your sphere of influence and past clients. We will send voicemail messages from you just saying hi and checking in, personalized emails that sound as if they were specifically written for your client, and a campaign of postcards including a Birthday, New Year, 4th of July, and Halloween Postcard merged with their name on the marketing piece. Our proven method of nurturing the client relationship should increase your warm leads by 10% and get you closer to that next transaction.
        <br />
        <br />
        If you are interested in using this product, we suggest you compile and upload a list of as many of your friends, family, leads, and past clients before beginning and continue to add more people to your database as your campaign progresses.
      </p>
    ),
    timelineEntries: createTimelineEntries([
      {
        title: 'New Year Postcard',
        touchType: 'POSTCARD',
      },
      {
        title: 'Voicemail #1',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Email #1',
        touchType: 'EMAIL',
      },
      {
        title: 'Voicemail #2',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Email #2',
        touchType: 'EMAIL',
      },
      {
        title: '4th of July Postcard',
        touchType: 'POSTCARD',
      },
      {
        title: 'Voicemail #3',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Email #3',
        touchType: 'EMAIL',
      },
      {
        title: 'Halloween Postcard',
        touchType: 'POSTCARD',
      },
      {
        title: 'Voicemail #4',
        touchType: 'VOICEMAIL',
      },
      {
        title: 'Email #4',
        touchType: 'EMAIL',
      },
      {
        title: 'Birthday Postcard',
        touchType: 'POSTCARD',
      },
    ]),
  },
};

const TouchInfo = ({ text, touchType }) => {
  const classes = useStyles();

  const BadgeIcon = getIconForTouchType(touchType);

  return (
    <div className={classes.touchInfo}>
      <BadgeIcon htmlColor={infoColor[0]} className={classes.touchInfoBadgeIcon} />
      <h5 className={classes.touchInfoText}>{text}</h5>
    </div>
  );
};

const ProductInfo = ({ product, setImageOpen, showGetStartedButton = true }) => {
  const classes = useStyles();

  const productInfo = productDescriptions[product];

  return (
    <GridContainer justifyContent="center" alignItems="flex-start" className={classes.productInfoRoot}>
      <GridItem xs={12} md={6}>
        <div
          className="video"
          style={{
            position: 'relative',
            paddingBottom: '56.25%' /* 16:9 */,
            paddingTop: 25,
            height: 0,
          }}
        >
          <iframe
            className={classes.videoContainer}
            src={`https://www.youtube-nocookie.com/embed/${productInfo.youtubeVideoId}?autoplay=0&modestbranding=1&rel=0`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </GridItem>
      <GridItem xs={12} md={6}>
        <div className={classes.productInfo}>
          <ul>
            <li>
              <span className={classes.productDescriptionKey}>Commitment:</span>
              <span className={classes.productDescriptionValue}>{productInfo.commitment}</span>
            </li>
            <li>
              <span className={classes.productDescriptionKey}>Membership Fee:</span>
              <span className={classes.productDescriptionValue}>{productInfo.membershipFee}</span>
            </li>
            <li>
              <span className={classes.productDescriptionKey}>Postcard & Letter Mailing Fee:</span>
              <span className={classes.productDescriptionValue}>{mailingFee}</span>
            </li>
            <li>
              <span className={classes.productDescriptionKey}>{productInfo.recordsLimitText}</span>
              <span className={classes.productDescriptionValue}>{productInfo.recordsLimitValue}</span>
            </li>
            {productInfo.recordsLimitDisclaimer ? (
              <li>
                {productInfo.recordsLimitDisclaimer}
              </li>
            ) : null}
            {/* <li>
              <span className={classes.productDescriptionKey}>Number of Touches:</span>
              <span className={classes.productDescriptionValue}>{`${productInfo.totalNumTouches} Total`}</span>
              <br />
              <ul className={classes.productInfoTouchesList}>
                {productInfo.touches.map((touch) => (
                  <li key={touch}><em>{touch}</em></li>
                ))}
              </ul>
            </li> */}
          </ul>
          <br />
          <div className={classes.productDescription}>
            {productInfo.description}
          </div>
        </div>
      </GridItem>
      {showGetStartedButton ? (
        <GridItem xs={12}>
          <div className={classes.getStartedButton}>
            <NavLink to="/interest-application">
              <Button color="primary" size="lg">
                Get Started
              </Button>
            </NavLink>
          </div>
        </GridItem>
      ) : null}
      <GridItem xs={12} className={classes.productTimeline}>
        <HorizontalTimeline stories={productInfo.timelineEntries} />
      </GridItem>
      <GridItem xs={12} className={classes.flyerContainer}>
        {/* <img className={classes.flyer} src={productInfo.flyerImage} alt={product} /> */}
        <ButtonBase
          onClick={() => setImageOpen(productInfo.flyerImage)}
        >
          <img className={classes.flyer} src={productInfo.flyerImage} alt={product} />
        </ButtonBase>
      </GridItem>
      <GridItem xs={12}>
        <GridContainer justifyContent="center" className={classes.touchesContainer}>
          {productInfo.touches.map(({ text, touchType }) => (
            <GridItem xs={12} sm={6} md={3} key={touchType}>
              <TouchInfo text={text} touchType={touchType} />
            </GridItem>
          ))}
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default ProductInfo;
