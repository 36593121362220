/* eslint-disable camelcase */
import React from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';

import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Paper, Popper } from '@material-ui/core';

import CustomInput from 'creative-components/CustomInput/CustomInput';

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 100,
    maxWidth: '280px',
    marginTop: '-20px', // To move closer to the CustomInput element
  },
}));

const CityStateAutocomplete = React.forwardRef(({
  inputProps, fieldName, setFieldValue, ...props
}, ref) => {
  const classes = useStyles();
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      // types: ['(cities)'],
      types: ['geocode'],
      componentRestrictions: {
        country: 'us',
      },
    },
  });

  React.useImperativeHandle(ref, () => ({
    resetValue() {
      setValue('');
    },
  }));

  const divRef = React.useRef();

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleSelect = ({ description, ...rest }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();

    // Manually set the Formik field value since we overwrite onChange in inputProps
    setFieldValue(fieldName, description, true);
  };

  const renderSuggestions = () => (
    <Popper
      open
      placement="bottom-start"
      anchorEl={divRef.current}
      className={classes.popper}
    >
      <Paper elevation={2}>
        {data.map((suggestion) => {
          const {
            id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            <ButtonBase
              key={`${main_text}${secondary_text}`}
              disableRipple
              disableTouchRipple
              onClick={handleSelect(suggestion)}
              className={classes.fullWidth}
            >
              <ListItem button dense>
                <ListItemText primary={main_text} secondary={secondary_text} />
              </ListItem>
            </ButtonBase>
          );
        })}
      </Paper>
    </Popper>
  );

  return (
    <div ref={divRef}>
      <CustomInput
        {...props}
        inputProps={{
          ...inputProps,
          value,
          disabled: !ready,
          onChange: handleInput,
        }}
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      {status === 'OK' && renderSuggestions()}
    </div>
  );
});

export default CityStateAutocomplete;
