import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
// import { useIntercom } from 'react-use-intercom';
import { openPopupWidget } from 'react-calendly';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';

import Button from 'creative-components/CustomButtons/Button';
import CustomInput from 'creative-components/CustomInput/CustomInput';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import CityStateAutocomplete from 'components/CityStateAutocomplete/CityStateAutocomplete';

import { getEventTypes, submitInterestApplication, submitBrokerageInterest } from 'utils/api';
import {
  nameRegExp, phoneRegExp, showAPIErrorAlert, supportEmail,
} from 'utils/lib';

import styles from 'assets/jss/material-dashboard-pro-react/components/signUpFormStyle';

const useStyles = makeStyles(styles);

const SignUpForm = ({ initialSelectedEventTypeName }) => {
  const classes = useStyles();
  const theme = useTheme();
  // const intercom = useIntercom();
  const { setCurrentAlert } = useAlertContext();
  const { onLogout } = useAuthDataContext();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const formRef = React.useRef();
  const cityStateAutocompleteRef = React.useRef(null);

  const [isSubmitted, setIsSubmitted] = React.useState(false);

  /* Disabled eventInterestedIn field */
  // const [availableEventTypes, setAvailableEventsTypes] = React.useState([]);
  // const [crmEventType, setCrmEventType] = React.useState(null);

  // React.useEffect(() => {
  //   (async () => {
  //     // Get event types separately from DataFilterProvider since this is not a dashboard page
  //     const { eventTypes } = await getEventTypes(true);

  //     /* const eventTypesWithInfo = eventTypes.map((eventType) => {
  //       // Add sold out text next to the NOD product
  //       if (eventType.name === 'NOD') {
  //         return ({
  //           ...eventType,
  //           description: (
  //             <>
  //             {`${eventType.name} - ${eventType.campaignLengthInMonths} Month Commitment ($${(eventType.subscriptionPrice / 100).toFixed(2)}/mo)`}
  //             <strong>SOLD OUT</strong>
  //             </>
  //             ),
  //           });
  //         }

  //         return ({
  //           ...eventType,
  //           description: `${eventType.name} - ${eventType.campaignLengthInMonths} Month Commitment ($${(eventType.subscriptionPrice / 100).toFixed(2)}/mo)`,
  //         });
  //       }); */

  //     // // Disable CRM Pro for prod until release
  //     // if (process.env.REACT_APP_BACKEND_URL !== 'https://axwell.harvist.com' && process.env.REACT_APP_BACKEND_URL !== 'http://localhost:4000') {
  //     //   setAvailableEventsTypes(eventTypesWithInfo.filter((e) => !isCrmProEventType(e)));
  //     // } else {
  //     //   setAvailableEventsTypes(eventTypesWithInfo);
  //     // }

  //     // Disable CRM Pro and Commercial starting from 08-23-21
  //     // setAvailableEventsTypes(eventTypesWithInfo.filter((e) => !isCrmProEventType(e) && !isCommercialEventType(e)));

  //     const eventTypesWithInfo = eventTypes.map((eventType) => ({
  //       ...eventType,
  //       description: eventType.name,
  //     }));

  //     setAvailableEventsTypes(eventTypesWithInfo);

  //     if (formRef.current && initialSelectedEventTypeName) {
  //       const initialEventTypeArr = eventTypes.filter((e) => e.name === initialSelectedEventTypeName);
  //       if (initialEventTypeArr.length > 0) {
  //         formRef.current.setFieldValue('eventInterestedIn', initialEventTypeArr[0]._id, true);
  //       }
  //     }

  //     /* const filteredCrmEventType = eventTypes.filter((e) => isCrmProEventType(e))[0];
  //     setCrmEventType(filteredCrmEventType); */
  //   })();
  // }, []);
  /* Disabled eventInterestedIn field */

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        firstName: '',
        email: '',
        phoneNumber: '',
        region: '',
        companyName: '',
        // eventInterestedIn: '',
      }}
      onSubmit={async ({
        firstName, email, phoneNumber, region, companyName,
        // eventInterestedIn,
      }) => {
        showLoadingIndicatorModal();
        try {
          // Its possible a user is logged in and went back to the application. Not a normal flow, but possible, so force a logout here.
          onLogout();

          /* const { passwordResetUrl } = await submitInterestApplication(
            firstName, '', email, '', region,
            // firstName, lastName, email, phoneNumber, region, zipcodesInterestedIn, eventInterestedIn, propertyTypesInterestedIn,
          ); */

          // 04/29/2022 - Changing to a brokerage-only model
          await submitBrokerageInterest({
            firstName, companyName, email, phoneNumber, region,
          });

          setIsSubmitted(true);
        } catch (err) {
          console.error(err);
          showAPIErrorAlert(setCurrentAlert, err);
        }

        hideLoadingIndicatorModal();
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required().matches(nameRegExp),
        email: Yup.string().email().required(),
        phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
        region: Yup.string().required(),
        companyName: Yup.string().required(),
        // eventInterestedIn: Yup.string().required(),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values, resetForm,
        } = props;

        /* const onConnectNow = async () => {
          intercom.show();
        };

        const onScheduleForLater = async () => {
          openPopupWidget({
            url: `${calendlyBaseUrl}/onboarding`,
            prefill: {
              name: values.firstName,
              email: values.email,
            },
          });
        }; */

        // NOTE 04/29/2022: Change to brokerage only
        /* const submittedContent = (
          <div className={classes.submittedInfo}>
            <h2>Great!</h2>
            <h3>Next Step &gt;</h3>
            <div className={classes.submittedInfoRocket}>
              🚀
            </div>
            <div className={classes.submittedInfoInstructions}>
              Now go
              <span> check your email </span>
              to
              <span> schedule your farm </span>
              and setup your account.
            </div>
            <div className={classes.submittedInfoDisclaimer}>
              *If you don't see it, check your spam folder, or
              {' '}
              <ButtonBase onClick={() => {
                resetForm();
                cityStateAutocompleteRef.current.resetValue();
                setIsSubmitted(false);
              }}
              >
                click here
              </ButtonBase>
              {' '}
              to try a different email.
              {' '}
              <ButtonBase
                href={`mailto:${supportEmail}`}
                component="a"
              >
                Contact support
              </ButtonBase>
              {' '}
              if all else fails.
            </div>
            // <div className={classes.submittedInfoButtons}>
            //       <ButtonBase onClick={onConnectNow} className={classes.connectNowButton}>Connect Now</ButtonBase>
            //       <ButtonBase onClick={onScheduleForLater} className={classes.scheduleForLaterButton}>Schedule for Later</ButtonBase>
            //     </div>
          </div>
        ); */

        const submittedContent = (
          <div className={classes.submittedInfo}>
            <h2>Great!</h2>
            <div className={classes.submittedInfoRocket}>
              🚀
            </div>
            <div className={classes.submittedInfoInstructions}>
              Hang tight, one of our specialists we will be in touch!
            </div>
          </div>
        );

        return (
          <Form className={classes.root}>
            {isSubmitted && submittedContent}

            <GridContainer style={{ visibility: isSubmitted ? 'hidden' : 'visible' }}>
              {/* <GridItem xs={12}>
                <CustomSelect
                  labelText="Test"
                  items={[
                    { value: 'val1', name: 'Text 1' },
                    { value: 'val2', name: 'Text 2' },
                    { value: 'val3', name: 'Text 3' },
                    { value: 'val4', name: 'Text 4' },
                    { value: 'val5', name: 'Text 5' },
                  ]}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomSelect
                  labelText="Test w/ error"
                  items={[
                    { value: 'val1', name: 'Text 1' },
                    { value: 'val2', name: 'Text 2' },
                    { value: 'val3', name: 'Text 3' },
                    { value: 'val4', name: 'Text 4' },
                    { value: 'val5', name: 'Text 5' },
                  ]}
                  error
                />
              </GridItem> */}
              <GridItem xs={12}>
                <Field
                  name="firstName"
                >
                  {({ field }) => (
                    <CustomInput
                      labelText="First Name"
                      inputProps={{
                        ...field,
                        autoComplete: 'given-name',
                        maxLength: 50,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="companyName"
                >
                  {({ field }) => (
                    <CustomInput
                      labelText="Brokerage Name"
                      inputProps={{
                        ...field,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="email"
                >
                  {({ field }) => (
                    <CustomInput
                      labelText="Work Email"
                      inputProps={{
                        ...field,
                        autoComplete: 'email',
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="phoneNumber"
                >
                  {({ field }) => (
                    <CustomInput
                      labelText="Phone Number"
                      inputProps={{
                        ...field,
                        type: 'tel',
                        autoComplete: 'tel',
                      }}
                      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="region"
                >
                  {({ field }) => (
                    <CityStateAutocomplete
                      ref={cityStateAutocompleteRef}
                      fieldName={field.name}
                      setFieldValue={setFieldValue}
                      labelText="Where are you based?"
                      inputProps={{
                        ...field,
                        autoComplete: 'forceoff',
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              {/* <GridItem xs={12}>
                <Field
                  name="eventInterestedIn"
                >
                  {({ field }) => (
                    <CustomSelect
                      labelText="Product Selected"
                      items={availableEventTypes.map(({ _id, description }) => ({ value: _id, name: description }))}
                      inputProps={{
                        ...field,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem> */}
              <GridItem xs={12}>
                <ButtonBase
                  className={classes.button}
                  disableRipple
                  disableTouchRipple
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Let's Go!
                </ButtonBase>
              </GridItem>
              <GridItem xs={12}>
                <div className={classes.text}>
                  <p>
                    <span>No Credit card required.</span>
                    <br />
                    Get started in seconds.
                  </p>
                  <div />
                  <p>
                    <span>
                      Or call us at 424-273-0335
                    </span>
                    <br />
                    to get started right now.
                  </p>
                </div>
              </GridItem>
            </GridContainer>

            {/* <div className={classes.brokerageAccount}>
              <Button
                color="orange"
                simple
                underlined
                className={classes.brokerageAccountButton}
                component="a"
                href={`mailto:${supportEmail}`}
                // to="/products/brokerage-accounts"
              >
                Need a Brokerage Account?
              </Button>
            </div> */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignUpForm;
