import { grayColor } from 'assets/jss/material-dashboard-pro-react';
import {
  Field, FieldProps, Form, Formik,
} from 'formik';
import passwordComplexity from 'joi-password-complexity';
import React from 'react';
import url from 'url';
import * as Yup from 'yup';

import {
  ButtonBase, Grid, makeStyles, useTheme,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LibraryAddCheckRoundedIcon from '@material-ui/icons/LibraryAddCheckRounded';

import Button from 'creative-components/CustomButtons/Button';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import ActionModal from 'components/ActionModal/ActionModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useAuthDataContext } from 'components/AuthDataProvider/AuthDataProvider';
import FormikOnError from 'components/FormikOnError/FormikOnError';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import PasswordValidationInfo from 'components/PasswordValidationInfo';
import PasswordVisibility from 'components/PasswordVisibility/PasswordVisibility';
import PaymentMethodCard from 'components/PaymentMethodCard/PaymentMethodCard';
import { useUserInfoContext } from 'components/UserInfoProvider/UserInfoProvider';

import { authenticate, submitInterestApplication } from 'utils/api';
import {
  maxPasswordLength, nameRegExp, passwordComplexityOptions, passwordValidationChecks, phoneRegExp, showAPIErrorAlert,
} from 'utils/lib';

import { SimplePricingPlan } from '../SimplePricingCards';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '250px',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  cardActions: {
    justifyContent: 'center',
  },
  field: {
    maxWidth: '400px',
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  confirmPopUp: {
    minWidth: '500px',
  },
  startButton: {
    float: 'right',
  },
  cancelButton: {
    backgroundColor: `${theme.palette.grayScale6.main} !important`,
  },
  paymentMethodContainer: {
    borderRadius: '12px',
    padding: '18px',
    backgroundColor: theme.palette.primary.light,
  },
}));

interface FormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

type Props = {
  zipCode: string;
  selectedPlan: SimplePricingPlan;
};

export default ({ zipCode, selectedPlan }: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext() as any;
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext() as any;
  const { onLogin, user } = useAuthDataContext() as any;
  const { userInfo, reloadUserInfo } = useUserInfoContext();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showBillingModal, setShowBillingModal] = React.useState(false);

  const isBillingInfoProvided = userInfo?.agentInfo.stripe.paymentMethodInfo && userInfo?.agentInfo.stripe.paymentMethodInfo.last4;

  const toggleShowPassword = () => setShowPassword(!showPassword);

  React.useEffect(() => {
    if (user) reloadUserInfo();
  }, [user]);

  React.useEffect(() => {
    if (userInfo && !isBillingInfoProvided) {
      setShowBillingModal(true);
      hideLoadingIndicatorModal();
    }
  }, [userInfo, isBillingInfoProvided]);

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        passwordConfirmation: '',
      } as FormValues}
      validateOnChange
      onSubmit={async (values) => {
        const {
          firstName,
          lastName,
          phoneNumber,
          email,
          password,
        } = values;

        try {
          showLoadingIndicatorModal();

          if (!userInfo) {
            // Register the user

            const { passwordResetUrl } = await submitInterestApplication(firstName, lastName, email, phoneNumber, '', true);

            const queryParams = url.parse(passwordResetUrl, true);
            const passwordResetToken = queryParams.query.token as string;

            const { token, expiresInDays } = await authenticate(email, password, passwordResetToken);

            // Call the login function on the auth context
            onLogin(token, expiresInDays, true, email, false);

            // Keep the loading indicator modal up until the payment modal opens up
          } else {
            // Make sure they have a payment method on file
            if (!isBillingInfoProvided) {
              setShowBillingModal(true);
              hideLoadingIndicatorModal();
              return;
            }
            setShowBillingModal(false);

            // TODO: TEMP, DO API CALL!!!
            console.log('nice', zipCode);
            console.log('nice2', selectedPlan);
            console.log('campaign start!');

            hideLoadingIndicatorModal();
          }
        } catch (err) {
          console.error('error', err);
          showAPIErrorAlert(setCurrentAlert, err);

          hideLoadingIndicatorModal();
        }
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required().matches(nameRegExp),
        lastName: Yup.string().required().matches(nameRegExp),
        phoneNumber: Yup.string().required().matches(phoneRegExp, 'Phone number is not valid'),
        email: Yup.string().email().required(),
        password: Yup.string().max(maxPasswordLength).required('Password is required')
          .test(
            'complexity',
            'Password must be between 8 and 24 characters',
            (value) => !passwordComplexity(passwordComplexityOptions).validate(value).error,
          ),
        passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required(),
      }).required()}
    >
      {({
        dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
      }) => {
        const passwordValidationInfos = [] as React.ReactNode[];
        const passwordValidation = passwordComplexity(passwordComplexityOptions).validate(values.password);

        const passwordErrors = passwordValidation.error ? passwordValidation.error.details.map(({ type }) => type) : [];

        passwordValidationChecks.forEach(({ description, errorName }: { description: string, errorName: string }) => {
          passwordValidationInfos.push(<PasswordValidationInfo
            key={errorName}
            description={description}
            valid={values.password.length > 0 && !passwordErrors.includes(errorName)}
          />);
        });

        return (
          <Form>
            <FormikOnError>
              {showBillingModal && (
                <ActionModal
                  maxWidth="sm"
                  backgroundColor={theme.palette.offWhite.main}
                  textColor={theme.palette.darkGray.main}
                  icon={<LibraryAddCheckRoundedIcon />}
                  onClose={() => setShowBillingModal(false)}
                  title="Start Farming Today!"
                  message={(
                    <div className={classes.confirmPopUp}>
                      Confirm your payment method:
                      <br />
                      <br />
                      <div className={classes.paymentMethodContainer}>
                        <PaymentMethodCard
                          billingName={`${userInfo.firstName} ${userInfo.lastName}`}
                          paymentMethodInfo={userInfo.agentInfo.stripe.paymentMethodInfo}
                          reloadCallback={reloadUserInfo}
                        />
                      </div>
                      <br />
                      <br />
                    </div>
                  )}
                  buttons={[
                    <ButtonBase onClick={() => setShowBillingModal(false)} className={classes.cancelButton}>Go Back</ButtonBase>,
                    <ButtonBase onClick={() => handleSubmit()}>Start Farming</ButtonBase>,
                  ]}
                />
              )}

              <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                  <Field
                    name="firstName"
                  >
                    {({ field, meta }: FieldProps) => (
                      <CustomInputDashboard
                        className={classes.field}
                        labelText="First Name"
                        inputProps={{
                          ...field,
                          disabled: user, // User was already registered
                          autoComplete: 'given-name',
                          placeholder: 'Enter name',
                          maxLength: 50,
                        }}
                        error={!!meta.touched && !!meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="lastName"
                  >
                    {({ field, meta }: FieldProps) => (
                      <CustomInputDashboard
                        className={classes.field}
                        labelText="Last Name"
                        inputProps={{
                          ...field,
                          disabled: user, // User was already registered
                          autoComplete: 'family-name',
                          placeholder: 'Enter name',
                          maxLength: 50,
                        }}
                        error={!!meta.touched && !!meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="email"
                  >
                    {({ field, meta }: FieldProps) => (
                      <CustomInputDashboard
                        className={classes.field}
                        labelText="Email Address"
                        inputProps={{
                          ...field,
                          disabled: user, // User was already registered
                          autoComplete: 'email',
                          placeholder: 'Enter email address',
                        }}
                        error={!!meta.touched && !!meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="phoneNumber"
                  >
                    {({ field, meta }: FieldProps) => (
                      <CustomInputDashboard
                        className={classes.field}
                        labelText="Phone Number"
                        inputProps={{
                          ...field,
                          disabled: user, // User was already registered
                          type: 'tel',
                          autoComplete: 'tel',
                          endAdornment: <InfoOutlinedIcon />,
                          placeholder: 'Enter phone number',
                        }}
                        mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                        error={!!meta.touched && !!meta.error}
                        tooltip="Used for internal communications, i.e. account and campaign updates"
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    name="password"
                  >
                    {({ field, meta }: FieldProps) => (
                      <CustomInputDashboard
                        labelText="Password"
                        className={classes.field}
                        inputProps={{
                          ...field,
                          disabled: user, // User was already registered
                          type: showPassword ? 'text' : 'password',
                          endAdornment: (
                            <PasswordVisibility
                              showPassword={showPassword}
                              toggleShowPassword={toggleShowPassword}
                              className={classes.inputAdornmentIcon}
                            />
                          ),
                          onChange: (e: any) => setFieldValue(field.name, e.target.value.substring(0, 16), true),
                          placeholder: 'Enter password',
                        }}
                        error={!!meta.touched && !!meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name="passwordConfirmation"
                  >
                    {({ field, meta }: FieldProps) => (
                      <CustomInputDashboard
                        labelText="Confirm password"
                        className={classes.field}
                        inputProps={{
                          ...field,
                          disabled: user, // User was already registered
                          type: showPassword ? 'text' : 'password',
                          onChange: (e: any) => setFieldValue(field.name, e.target.value.substring(0, 16), true),
                          onKeyDown: (event: any) => {
                            if (event.key === 'Enter') {
                              // Same checks as submit button below
                              if (isValid && dirty && !isSubmitting) {
                                handleSubmit();
                              }
                            }
                          },
                          placeholder: 'Confirm password',
                        }}
                        error={!!meta.touched && !!meta.error}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  {passwordValidationInfos}
                </Grid>
              </Grid>

              <Button
                round
                color="primary"
                className={classes.startButton}
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
              >
                Start Farming
              </Button>
            </FormikOnError>
          </Form>
        );
      }}
    </Formik>
  );
};
