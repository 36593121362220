import React from 'react';

import { Hidden, makeStyles } from '@material-ui/core';

import CustomAccordion from 'creative-components/CustomAccordion/CustomAccordion';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import styles from 'assets/jss/material-dashboard-pro-react/components/commonQuestionsStyle';
import { supportEmail } from 'utils/lib';

const useStyles = makeStyles(styles);

const content = [
  {
    title: 'Lorem ipsum dolor sit amet, consec tetur?',
    content: (
      <p>
        Registered users may check the status of their recent orders by visiting the order status page under "my account". To access the page the user will require entering an e-mail address and a password. The "order summary" page will provide detailed information about current and previous orders. Upon shipping an order, if available, a tracking number will be sent along with details of the courier company.
        If you have not registered with us prior to making your order or are otherwise unable to access your order details, please email us with your order number in the subject line and we will assist you with tracking and any other question you may have about your shipment.
      </p>
    ),
  },
  {
    title: 'I didn\'t receive a copy of my postcard',
    content: (
      <p>
        If you didn't receive a copy of your postcard, make sure that you've added your address where you receive mail as an extra address without your campaign dashboard. This will ensure you know when people are receiving your postcards and you'll feel confident that they were successfully sent out on your behalf. If you have further questions, you may contact
        {' '}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
        {' '}
        for proof of mailing from the United State Post Office.
      </p>
    ),
  },
];

// TODO: FOR FUTURE TYPESCRIPT: color: 'lightGray' | 'white'
const CommonQuestions = ({ color }) => {
  const classes = useStyles({ color });

  return (
    <div className={classes.root}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} className={classes.header}>
          <h2>Common Questions</h2>
          <div />
        </GridItem>
        <Hidden smDown>
          <GridItem md={1} />
        </Hidden>
        <GridItem xs={12} md={10} className={classes.faq}>
          <CustomAccordion collapses={content} color={color === 'white' ? 'darkGray' : 'white'} />
        </GridItem>
        <Hidden smDown>
          <GridItem md={1} />
        </Hidden>
      </GridContainer>
    </div>
  );
};

export default CommonQuestions;
