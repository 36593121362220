import {
  infoColor,
  cardTitle,
  backdropContainer,
  backdropImage,
} from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  center: {
    textAlign: 'center',
  },
  importAgentCustomersButton: {
    float: 'left',
  },
  createAgentCustomerButton: {
    float: 'right',
  },
  backdropContainer,
  backdropImage,
});

export default style;
