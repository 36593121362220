import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import styles from 'assets/jss/material-dashboard-pro-react/components/featureTestimonialStyle.js';

const useStyles = makeStyles(styles);

const FeatureTestimonial = ({
  text, avatar, name, occupation, textColor, profileTextColor, backgroundColor,
}) => {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: `rgba(${hexToRgb(backgroundColor)}, .1)`,
      }}
    >
      <p style={{ color: textColor }}>
        {`"${text}"`}
      </p>
      <div className={classes.profile} style={{ color: profileTextColor }}>
        <img src={avatar} alt="" />
        {`${name}, ${occupation}`}
      </div>
    </div>
  );
};

export default FeatureTestimonial;
