import { container, hexToRgb } from 'assets/jss/material-dashboard-pro-react';

import heroImage from 'assets/img/case-studies/hero.png';
import buildingImage from 'assets/img/case-studies/building.png';
import studyImage from 'assets/img/case-studies/study-1.png';
import whyChooseUsBgImage from 'assets/img/case-studies/why-choose-us-bg.png';
import orangeDotsImage from 'assets/img/orange-dots.png';

const style = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  container: {
    ...container,
    position: 'relative',
  },

  hero: {
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
  },
  heroOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.darkBlueGray.main,
    mixBlendMode: 'hard-light',
  },

  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  companyLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,

    '& > img': {
      marginTop: '10px',
      border: `2px solid rgba(${hexToRgb(theme.palette.primary.main)}, 0.66)`,
      borderRadius: '16px',

      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
        width: '284px',
        height: '166px',
        objectFit: 'contain',
      },
      [theme.breakpoints.up('md')]: {
        width: '284px',
        height: '166px',
        objectFit: 'contain',
      },
    },
  },

  headerRight: {
    '& > h1': {
      margin: '48px 0 35px',
      color: theme.palette.white.main,

      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
        margin: '32px 0 22px',
      },
    },
    '& > p': {
      margin: '0 0 40px',
      fontSize: '20px',
      lineHeight: '30px',
      color: theme.palette.white.main,
    },
    '& > h3': {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'bold',
      color: theme.palette.white.main,
    },
  },
  productMetrics: {
    margin: '20px 0 41px',
  },
  tagsList: {
    margin: '32px 0',

    '& > span': {
      border: `1px solid ${theme.palette.grayScale6.main}`,
      borderRadius: '24px',
      fontSize: '12px',
      lineHeight: '16px',
      padding: '8px',
      color: theme.palette.white.main,

      '&:not(:last-child)': {
        marginRight: '17px',
      },
    },
  },

  studyBackground: (props) => ({
    position: 'relative',
    backgroundColor: props.color === 'orange' ? theme.palette.orange.main : theme.palette.white.main,
  }),
  studyCard: {
    position: 'relative',
    padding: '32px 48px',
    background: theme.palette.white.main,
    borderRadius: '16px',
    filter: 'drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25))',

    [theme.breakpoints.down('sm')]: {
      padding: '32px 20px',
    },
  },
  studyChallenge: {
    margin: '60px 0 0',
    '& > h4': {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '30px',
      color: theme.palette.orange.main,

      [theme.breakpoints.down('sm')]: {
        margin: '160px 0 0',
      },
    },
    '& > p': {
      margin: '26px 0 0',
      color: theme.palette.darkGray.main,

      [theme.breakpoints.down('sm')]: {
        margin: '16px 0 0',
      },
      [theme.breakpoints.only('md')]: {
        maxWidth: '330px',
      },
      [theme.breakpoints.only('lg')]: {
        maxWidth: '450px',
      },
      [theme.breakpoints.only('xl')]: {
        maxWidth: '515px',
      },
    },
    '& > div': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      width: '50%',
      background: `url(${studyImage})`,
      backgroundSize: 'cover',
      borderRadius: '0 16px 16px 0',

      [theme.breakpoints.down('sm')]: {
        borderRadius: '16px 16px 0 0',
        width: '100%',
        height: '160px',
        top: 0,
        left: 0,
        right: 0,
        bottom: 'unset',
      },

      '& > div': {
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        mixBlendMode: 'multiply',
        borderRadius: '0 16px 16px 0',

        [theme.breakpoints.down('sm')]: {
          borderRadius: '16px 16px 0 0',
        },
      },
    },
  },
  studySolution: {
    margin: '40px 0 168px',

    [theme.breakpoints.down('sm')]: {
      margin: '40px 0 86px',
    },

    '& > h4': {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '30px',
      color: theme.palette.darkGray.main,
    },
    '& > p': {
      margin: '26px 0 36px',
      fontSize: '16px',
      lineHeight: '24px',
      color: theme.palette.darkGray.main,

      whiteSpace: 'pre-line', // pre-wrap if we want to preserve whitespaces (https://developer.mozilla.org/en-US/docs/Web/CSS/white-space)
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      [theme.breakpoints.down('sm')]: {
        margin: '16px 0 0',
      },
    },
  },

  supportCard: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    bottom: '-52px',
    display: 'flex',
    justifyContent: 'center',
  },

  testimonials: (props) => ({
    backgroundColor: props.color === 'orange' ? theme.palette.grayScale6.main : theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      padding: '42px 0 0',
    },
  }),

  whyChooseUs: (props) => ({
    // Same background color as the testimonial
    backgroundColor: props.color === 'orange' ? theme.palette.grayScale6.main : theme.palette.primary.main,

    marginTop: '-1px', // Gets rid of a white line
    backgroundImage: `url(${whyChooseUsBgImage})`,
    position: 'relative',
    backgroundSize: '100vw 100%',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      padding: '80px 0 100px',
    },
  }),
  whyChooseUsOrangeDots: {
    position: 'absolute',
    backgroundImage: `url(${orangeDotsImage})`,
    backgroundSize: 'cover',
    width: '57px',
    height: '47px',

    [theme.breakpoints.down('sm')]: {
      bottom: '90px',
      right: 'calc(50% - 30px)',
      width: 'calc(57px * .6)',
      height: 'calc(47px * .6)',
    },
    [theme.breakpoints.only('md')]: {
      bottom: '176px',
      right: '335px',
    },
    [theme.breakpoints.up('lg')]: {
      bottom: '176px',
      right: '475px',
    },
  },
  whyChooseUsBuilding: {
    position: 'absolute',
    backgroundImage: `url(${buildingImage})`,
    backgroundSize: 'cover',

    [theme.breakpoints.down('sm')]: {
      width: 'calc(477px * .4)',
      height: 'calc(401px * .4)',
      bottom: '0px',
      right: '0px',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(477px * .8)',
      height: 'calc(401px * .8)',
      bottom: '0px',
      right: '0px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '477px',
      height: '401px',
      bottom: '0px',
      right: '0px',
    },
  },
  whyChooseUsHeader: {
    margin: '116px 0 60px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      margin: '0 0 48px',
    },

    '& > h2': {
      color: theme.palette.darkGray.main,
    },
    '& > h2 > span': {
      display: 'inline-block',
      width: '30px',
      height: '0px',
      border: `2px solid ${theme.palette.orange.main}`,
      marginLeft: '14px',
    },
  },
  whyChooseUsPoints: {
    '& > h3': {
      color: theme.palette.darkGray.main,
    },
    '& > h3 > span': {
      display: 'inline-block',
      width: '30px',
      height: '0px',
      border: `2px solid ${theme.palette.orange.main}`,
      marginLeft: '14px',
    },
    '& > p': {
      margin: '36px 0 0',
      fontSize: '24px',
      lineHeight: '150%',
      color: theme.palette.darkGray.main,

      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '20px 0 40px',
        maxWidth: '220px',
      },
      [theme.breakpoints.only('sm')]: {
        maxWidth: '280px',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '360px',
      },
    },
  },
  whyChooseUsBody: {
    '& > p': {
      margin: '120px 0',
      height: '241px',
      color: theme.palette.darkGray.main,
    },
  },
});

export default style;
