import {
  infoColor,
  primaryColor,
  successColor,
  dangerColor,
  warningColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
  backdropContainer,
} from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  backdropContainer,
  filler: {
    flex: 1,
  },
  timeline: {
    padding: '20px 16px',
    overflowX: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',

    '&:before': {
      // // top: '50px',
      // // bottom: '0',
      // position: 'absolute',
      // content: '" "',
      // // width: '3px',
      // backgroundColor: grayColor[11],
      // // left: '50%',
      // // marginLeft: '-1px',

      // zIndex: '0',
      // width: '100%',
      // height: '3px',
      // left: '0',
      // top: '50%',
    },
  },

  item: {
    paddingRight: '50px',
    position: 'relative',
    '&:before,&:after': {
      content: '" "',
      display: 'table',
    },
    '&:after': {
      clear: 'both',
    },

    '&:before': {
      top: '50%',
      left: '50px',
      position: 'absolute',
      content: '" "',
      backgroundColor: grayColor[11],

      zIndex: '0',
      width: '100%',
      height: '3px',
    },
  },

  firstItem: {
    '&:before': {
    },
  },
  lastItem: {
    paddingRight: '16px',
    '&:before': {
      width: 0,
    },
  },

  timelineBadge: {
    [theme.breakpoints.down('sm')]: {
      left: '5% !important',
    },
    color: whiteColor,
    width: '50px',
    height: '50px',
    lineHeight: '51px',
    fontSize: '1.4em',
    textAlign: 'center',
    // position: 'absolute',
    // top: '16px',
    // left: '50%',
    // marginLeft: '-24px',
    zIndex: '100',
    borderTopRightRadius: '50%',
    borderTopLeftRadius: '50%',
    borderBottomRightRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  timelineBadgeLarge: {
    width: '60px',
    height: '60px',
  },
  badgeIcon: {
    width: '24px',
    height: '100%',
  },
  blinkIcon: {
    animation: '$blinker 1s linear infinite',
  },
  '@keyframes blinker': {
    '50%': {
      opacity: 0,
    },
  },
  infoBadgeBorder: {
    border: `3px solid ${infoColor[0]}`,
  },

  // timelineHeading: {
  //   position: 'absolute',
  //   bottom: '-50px',
  //   // left: '-50%',
  //   // width: '100%',

  //   left: '50%',
  //   '-webkit-transform': 'translateX(-50%)',
  //   transform: 'translateX(-50%)',

  //   // left: 0,
  //   // right: 0,
  //   // margin: 'auto',
  // },

  info: {
    backgroundColor: infoColor[0],
    boxShadow:
      `0 4px 20px 0px rgba(${
        hexToRgb(blackColor)
      }, 0.14), 0 7px 10px -5px rgba(${
        hexToRgb(infoColor[0])
      }, 0.4)`,
  },
  success: {
    backgroundColor: successColor[0],
    boxShadow:
      `0 4px 20px 0px rgba(${
        hexToRgb(blackColor)
      }, 0.14), 0 7px 10px -5px rgba(${
        hexToRgb(successColor[0])
      }, 0.4)`,
  },
  danger: {
    backgroundColor: dangerColor[0],
    boxShadow:
      `0 4px 20px 0px rgba(${
        hexToRgb(blackColor)
      }, 0.14), 0 7px 10px -5px rgba(${
        hexToRgb(dangerColor[0])
      }, 0.4)`,
  },
  warning: {
    backgroundColor: warningColor[0],
    boxShadow:
      `0 4px 20px 0px rgba(${
        hexToRgb(blackColor)
      }, 0.14), 0 7px 10px -5px rgba(${
        hexToRgb(warningColor[0])
      }, 0.4)`,
  },
  primary: {
    backgroundColor: primaryColor[0],
    boxShadow:
      `0 4px 20px 0px rgba(${
        hexToRgb(blackColor)
      }, 0.14), 0 7px 10px -5px rgba(${
        hexToRgb(primaryColor[0])
      }, 0.4)`,
  },
  white: {
    backgroundColor: whiteColor,
    boxShadow:
      `0 4px 20px 0px rgba(${
        hexToRgb(blackColor)
      }, 0.14), 0 7px 10px -5px rgba(${
        hexToRgb(whiteColor)
      }, 0.4)`,
  },
});

export default style;
