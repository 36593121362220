import React from 'react';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core';

import styles from 'assets/jss/material-dashboard-pro-react/components/productTermsStyle';

const useStyles = makeStyles(styles);

const ProductTerms = ({
  numCommitmentMonths,
  membershipFeePerMonth,
  mailOutFeePerPiece,
  minimumNumRecords,
  bgColor,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} style={{ backgroundColor: bgColor }}>
      <p className={cx(classes.leftText, classes.text)}>
        Commitment:
        <br />
        Membership Fee:
        <br />
        Postcard & Letter Mailing Fee:
        <br />
        Minimum Number of Records:
      </p>
      <p className={cx(classes.rightText, classes.text)}>
        {`${numCommitmentMonths} months`}
        <br />
        {`$${membershipFeePerMonth} / month`}
        <br />
        {`$${mailOutFeePerPiece} / piece`}
        <br />
        {minimumNumRecords}
      </p>
    </div>
  );
};

export default ProductTerms;
