import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Close from '@material-ui/icons/Close';

import Button from 'creative-components/CustomButtons/Button';
import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';
import CustomSelect from 'components/CustomSelect/CustomSelect';

import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';

import { createUser, submitInterestApplication } from 'utils/api';
import { nameRegExp, phoneRegExp, showAPIErrorAlert } from 'utils/lib';
import analyticsClient from 'utils/analytics';

import styles from 'assets/jss/material-dashboard-pro-react/components/agentInfoModalStyle';
import { useUsers } from 'data/user';

const useStyles = makeStyles(styles);

const CreateUserModal = ({ onCancel, roles }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();
  const { mutateUsers } = useUsers();

  if (!roles) return null;

  // Don't allow admins to create a user with the agent role directly
  const agentRole = roles.find((e) => e.name === 'Agent');
  const allowedRoles = roles.filter((e) => e.name !== 'Brokerage Agent' && e.name !== 'Brokerage Admin');

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        region: '',
        roleId: '',
      }}
      onSubmit={async ({
        email, firstName, lastName, phoneNumber, region, roleId,
      }) => {
        try {
          // Use the interest application endpoint for creating non-brokerage agent users
          if (roleId === agentRole._id) {
            await submitInterestApplication(firstName, lastName, email, phoneNumber, region);
          } else {
            const { user } = await createUser(email, firstName, lastName, phoneNumber, roleId);

            // Track GTM event
            analyticsClient.track('user_registration_started', {
              agentdata_user_id: user._id,
              email: user.email,
              role: user.role.name,
            });
          }

          // Reload users
          mutateUsers();

          onCancel();
        } catch (err) {
          console.error(err);
          showAPIErrorAlert(setCurrentAlert, err);
        }
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required(),
        firstName: Yup.string().required().matches(nameRegExp),
        lastName: Yup.string().required().matches(nameRegExp),
        phoneNumber: Yup.string().when('roleId', {
          is: agentRole._id,
          then: (schema) => schema,
          otherwise: (schema) => schema.required(),
        }),
        region: Yup.string().when('roleId', {
          is: agentRole._id,
          then: (schema) => schema.required(),
          otherwise: (schema) => schema,
        }),
        roleId: Yup.string().required(),
      })}
    >
      {(props) => {
        const {
          values, touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue,
        } = props;

        return (
          <Form>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal,
              }}
              open
              scroll="body"
              keepMounted
              onClose={() => onCancel()}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => onCancel()}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>Create User</h4>
              </DialogTitle>
              <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
              >
                {isSubmitting && <LoadingIndicator modal />}

                <Field
                  name="roleId"
                >
                  {({ field }) => (
                    <CustomSelect
                      labelText="Choose role"
                      items={allowedRoles.map(({ _id, name }) => ({ value: _id, name }))}
                      inputProps={{
                        ...field,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>

                {values.roleId && (
                  <>
                    <Field
                      name="firstName"
                    >
                      {({ field }) => (
                        <CustomInputDashboard
                          labelText="First Name"
                          inputProps={{
                            ...field,
                            autoComplete: 'given-name',
                            maxLength: 50,
                          }}
                          error={touched[field.name] && errors[field.name] !== undefined}
                        />
                      )}
                    </Field>
                    <Field
                      name="lastName"
                    >
                      {({ field }) => (
                        <CustomInputDashboard
                          labelText="Last Name"
                          inputProps={{
                            ...field,
                            autoComplete: 'family-name',
                            maxLength: 50,
                          }}
                          error={touched[field.name] && errors[field.name] !== undefined}
                        />
                      )}
                    </Field>
                    <Field
                      name="email"
                    >
                      {({ field }) => (
                        <CustomInputDashboard
                          labelText="Email"
                          inputProps={{
                            ...field,
                            autoComplete: 'email',
                          }}
                          error={touched[field.name] && errors[field.name] !== undefined}
                        />
                      )}
                    </Field>
                    <Field
                      name="phoneNumber"
                    >
                      {({ field }) => (
                        <CustomInputDashboard
                          labelText="Phone Number"
                          inputProps={{
                            ...field,
                            type: 'tel',
                            autoComplete: 'tel',
                          }}
                          mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                          error={touched[field.name] && errors[field.name] !== undefined}
                        />
                      )}
                    </Field>
                    {values.roleId === agentRole._id && (
                    <Field
                      name="region"
                    >
                      {({ field }) => (
                        <CustomInputDashboard
                          labelText="Region"
                          inputProps={{
                            ...field,
                            placeholder: 'Los Angeles, CA',
                          }}
                          error={touched[field.name] && errors[field.name] !== undefined}
                        />
                      )}
                    </Field>
                    )}
                  </>
                )}
              </DialogContent>
              <DialogActions
                className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                <Button
                  color="primary"
                  round
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={handleSubmit}
                >
                  Create
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

CreateUserModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
};

export default CreateUserModal;
