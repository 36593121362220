import React from 'react';
import moment from 'moment';

import SimpleAlert from 'components/SimpleAlert/SimpleAlert';

const localStorageKey = 'currentAlert';

export const AlertContext = React.createContext(null);

const AlertProvider = (props) => {
  // Toast alerts
  const [currentAlert, setCurrentAlert] = React.useState(null);

  // Modal alerts
  const queuedModalsRef = React.useRef(null); // Use a ref to get around the memoized stuff
  const [currentModal, setCurrentModal] = React.useState(null);
  const [queuedModals, setQueuedModals] = React.useState([]);

  const clearAlert = () => {
    setCurrentAlert(null);
    localStorage.removeItem(localStorageKey);
  };

  React.useEffect(() => {
    const existingAlert = localStorage.getItem(localStorageKey);

    if (existingAlert) {
      const {
        severity, message, autoHideDuration, time,
      } = JSON.parse(existingAlert);

      // Only show stored alerts if they happened within the past 5 minutes
      if (time && moment(time).add('5', 'minutes').isAfter(Date.now())) {
        setCurrentAlert(<SimpleAlert
          severity={severity}
          message={message}
          autoHideDuration={autoHideDuration}
        />);
      } else {
        clearAlert();
      }
    }
  }, []);

  const showModal = (modal, key) => {
    setCurrentModal(modal);

    // If a key was passed in then save the time that this modal was shown so that it can be retrieved later
    if (key) localStorage.setItem(key, JSON.stringify({ lastModalTime: Date.now() }));
  };

  const closeCurrentModal = () => {
    setCurrentModal(null);

    const arr = [...queuedModalsRef.current];
    arr.shift();
    setQueuedModals(arr);
  };

  const addModalToQueue = (modal, key) => {
    setQueuedModals([...queuedModalsRef.current, { modal, key }]);
  };

  React.useEffect(() => {
    queuedModalsRef.current = queuedModals;

    if (queuedModals.length === 0) return;
    if (currentModal) return;

    const nextModal = queuedModals[0];

    showModal(nextModal.modal, nextModal.key);
  }, [queuedModals]);

  const alertProviderValue = React.useMemo(() => ({
    currentAlert,
    setCurrentAlert: (severity, message, autoHideDuration = 6000) => {
      setCurrentAlert(<SimpleAlert
        severity={severity}
        message={message}
        autoHideDuration={autoHideDuration}
      />);

      // We can't serialize component alerts correctly, so don't cache them
      if (typeof message !== 'string') {
        return;
      }

      // Store date too because we will only show this alert on a refresh within 5 minutes of when the alert actually went out
      localStorage.setItem(localStorageKey, JSON.stringify({ severity, message, time: Date.now() }));
    },
    clearAlert,

    currentModal,
    getModalLastAlert: (key) => {
      if (!key) throw new Error('Key must be provided');

      const data = localStorage.getItem(key);

      if (data) {
        const { lastModalTime } = JSON.parse(data);
        return lastModalTime;
      }

      return null;
    },
    setCurrentModal: (modal, key = '') => addModalToQueue(modal, key),
    closeCurrentModal,
  }), [currentAlert, currentModal]);

  return <AlertContext.Provider value={alertProviderValue} {...props} />;
};

export const useAlertContext = () => React.useContext(AlertContext);

export default AlertProvider;
