import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import PublicRoute from 'components/AuthDataProvider/PublicRoute';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import UnsubscribeErrorPage from 'views/Unsubscribe/UnsubscribeErrorPage';

import routes from 'routes';

import styles from 'assets/jss/material-dashboard-pro-react/layouts/agentLandingStyle';

const useStyles = makeStyles(styles);

const Unsubscribe = () => {
  const classes = useStyles();
  const { currentAlert, currentModal } = useAlertContext();
  const { isLoadingIndicatorModalShowing } = useLoadingIndicatorContext();

  const getRoutes = (routes2) => routes2.map((prop, key) => {
    if (prop.collapse) {
      return getRoutes(prop.views);
    }

    if (prop.layout === '/unsubscribe') {
      return (
        <Route
          exact={prop.exact}
          path={prop.layout + prop.path}
          component={prop.component}
          render={prop.render}
          key={key}
        />
      );
    }

    return null;
  });

  return (
    <div className={classes.wrapper}>
      {currentAlert}
      {currentModal}
      {isLoadingIndicatorModalShowing ? (<LoadingIndicator modal />) : null}

      <div className={classes.content}>

        <Switch>
          {getRoutes(routes)}
          <Route path="*" component={() => (<PublicRoute component={UnsubscribeErrorPage} />)} />
        </Switch>
      </div>
    </div>
  );
};

export default Unsubscribe;
