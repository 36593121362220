import React from 'react';
import { FieldArray } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import FeedbackResponseShortText from 'components/Feedback/ResponseType/FeedbackResponseShortText';
import FeedbackResponseLongText from 'components/Feedback/ResponseType/FeedbackResponseLongText';
import FeedbackResponseToggleButtons from 'components/Feedback/ResponseType/FeedbackResponseToggleButtons';
import FeedbackResponseStarRating from 'components/Feedback/ResponseType/FeedbackResponseStarRating';

import styles from 'assets/jss/material-dashboard-pro-react/components/feedbackResponseInputStyle';

const useStyles = makeStyles(styles);

const FeedbackResponseInput = ({ responseIndex, responseTypes }) => {
  const classes = useStyles();

  return (
    <FieldArray name={`responses[${responseIndex}]`}>
      {(arrayHelpers) => (
        <>
          {responseTypes.map((responseType, responseTypeIndex) => {
            switch (responseType) {
              case 'SHORT_TEXT':
                return (
                  <div key={responseTypeIndex} className={classes.responseBase}>
                    <FeedbackResponseShortText
                      className={classes.responseBase}
                      responseIndex={responseIndex}
                      responseTypeIndex={responseTypeIndex}
                      responseTypesArrayHelpers={arrayHelpers}
                    />
                  </div>
                );
              case 'LONG_TEXT':
                return (
                  <div key={responseTypeIndex} className={classes.responseBase}>
                    <FeedbackResponseLongText
                      className={classes.responseBase}
                      responseIndex={responseIndex}
                      responseTypeIndex={responseTypeIndex}
                      responseTypesArrayHelpers={arrayHelpers}
                    />
                  </div>
                );
              case 'YES_NO':
                return (
                  <div key={responseTypeIndex} className={classes.responseBase}>
                    <FeedbackResponseToggleButtons
                      responseIndex={responseIndex}
                      responseTypeIndex={responseTypeIndex}
                      responseTypesArrayHelpers={arrayHelpers}
                      availableOptions={['Yes', 'No']}
                    />
                  </div>
                );
              case 'STAR_RATING':
                return (
                  <div key={responseTypeIndex} className={classes.responseBase}>
                    <FeedbackResponseStarRating
                      responseIndex={responseIndex}
                      responseTypeIndex={responseTypeIndex}
                      responseTypesArrayHelpers={arrayHelpers}
                    />
                  </div>
                );
              case 'RECOMMEND_LIKELINESS':
                return (
                  <div key={responseTypeIndex} className={classes.responseBase}>
                    <FeedbackResponseToggleButtons
                      className={classes.responseBase}
                      responseIndex={responseIndex}
                      responseTypeIndex={responseTypeIndex}
                      responseTypesArrayHelpers={arrayHelpers}
                      availableOptions={['Wouldn\'t Recommend', 'Not Likely', 'Likely', 'Highly Likely']}
                    />
                  </div>
                );
              default:
                console.error('Unhandled feedback response type', responseType);
                return null;
            }
          })}
        </>
      )}
    </FieldArray>
  );
};

export default FeedbackResponseInput;
