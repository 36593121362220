import React from 'react';

import { useTheme } from '@material-ui/core/styles';

import ProductLayout2 from 'components/Product/ProductLayout2';

import heroImage from 'assets/img/products/new/hero/crm-pro-hero.png';

const CRMProPage = () => {
  const theme = useTheme();

  return (
    <ProductLayout2
      eventTypeName="CRM Pro"
      heroImage={heroImage}
      heroColorVariant="orange"
      productTermsBgColor={theme.palette.primary.main}
      section1BgVariant="gray"
      section3BgVariant="orange"
    />
  );
};

export default CRMProPage;
