import React from 'react';

import { SvgIcon, useTheme } from '@material-ui/core';

const LetterIcon = (props) => {
  const theme = useTheme();

  return (
    <SvgIcon width="50" height="50" viewBox="0 0 50 50" {...props}>
      <path d="M8.33366 8H41.667C43.9587 8 45.8337 9.875 45.8337 12.1667V37.4999C45.8337 39.7916 43.9587 41.6666 41.667 41.6666H8.33366C6.04199 41.6666 4.16699 39.7916 4.16699 37.4999L4.18783 12.1667C4.18783 9.875 6.04199 8 8.33366 8Z" fill={theme.palette.orange.main} />
      <path d="M10 15L25 27L40 15" stroke="white" strokeWidth="4" strokeLinecap="round" fill="none" />
    </SvgIcon>
  );
};

export default LetterIcon;
