import React from 'react';

import { useTheme } from '@material-ui/core/styles';

import ProductLayout1 from 'components/Product/ProductLayout1';

import heroImage from 'assets/img/products/new/hero/commercial-hero.png';

const CommercialPage = () => {
  const theme = useTheme();

  return (
    <ProductLayout1
      eventTypeName="Commercial"
      heroImage={heroImage}
      heroColor={theme.palette.darkBlueGray.main}
      productTermsBgColor={theme.palette.primary.main}
    />
  );
};

export default CommercialPage;
