import React from 'react';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core';

import VoicemailIcon from 'components/CustomIcons/VoicemailIcon';
import LetterIcon from 'components/CustomIcons/LetterIcon';
import PostcardIcon from 'components/CustomIcons/PostcardIcon';
import EmailIcon from 'components/CustomIcons/EmailIcon';

import styles from 'assets/jss/material-dashboard-pro-react/components/productTouchesStyle';

import voicemailTouchImage from 'assets/img/products/new/touch/voicemail-touch.png';
import letterTouchImage from 'assets/img/products/new/touch/letter-touch.png';
import postcardTouchImage from 'assets/img/products/new/touch/postcard-touch.png';
import emailTouchImage from 'assets/img/products/new/touch/email-touch.png';

const useStyles = makeStyles(styles);

const NumberTouchesCard = ({
  icon: Icon, num, description, className,
}) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.numTouchesCard, className)}>
      <Icon />
      <p>{`${num} ${description}`}</p>
    </div>
  );
};

// TODO: FOR TYPESCRIPT: positionVariant: 'left' | 'right'
const ProductTouches = ({
  numVoicemails, numLetters, numEmails, numPostcards, positionVariant,
}) => {
  const classes = useStyles({ positionVariant });

  return (
    <div className={classes.root}>
      <img className={cx(classes.image, classes.voicemails)} src={voicemailTouchImage} alt="" />
      <img className={cx(classes.image, classes.letters)} src={letterTouchImage} alt="" />
      <img className={cx(classes.image, classes.emails)} src={emailTouchImage} alt="" />
      <img className={cx(classes.image, classes.postcards)} src={postcardTouchImage} alt="" />

      <NumberTouchesCard icon={VoicemailIcon} num={numVoicemails} description="Voicemails" className={classes.numVoicemails} />
      <NumberTouchesCard icon={LetterIcon} num={numLetters} description="Letters" className={classes.numLetters} />
      <NumberTouchesCard icon={EmailIcon} num={numEmails} description="Emails" className={classes.numEmails} />
      <NumberTouchesCard icon={PostcardIcon} num={numPostcards} description="Postcards" className={classes.numPostcards} />
    </div>
  );
};

export default ProductTouches;
