import { container } from 'assets/jss/material-dashboard-pro-react';

const style = (theme) => ({
  container,
  root: (props) => ({
    backgroundColor: props.color === 'lightGray' ? theme.palette.lightGray.main : theme.palette.white.main,

    [theme.breakpoints.down('sm')]: {
      padding: '40px 0 78px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '78px 0',
    },
  }),
  header: (props) => ({
    '& h2': {
      color: props.color === 'lightGray' ? theme.palette.white.main : theme.palette.darkGray.main,
      textAlign: 'center',

      [theme.breakpoints.down('sm')]: {
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
    '& > div': {
      margin: '14px auto 30px',
      width: '281px',
      height: '0px',
      border: `2px solid ${theme.palette.primary.main}`,

      [theme.breakpoints.down('sm')]: {
        width: '160px',
      },
    },
  }),
  faq: (props) => ({
    '& p': {
      color: props.color === 'lightGray' ? theme.palette.white.main : theme.palette.darkGray.main,
      marginTop: '25px',
      marginBottom: 0,

      // [theme.breakpoints.down('sm')]: {
      //   fontSize: '14px',
      //   lineHeight: '20px',
      // },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '906px',
      },
    },
  }),
});

export default style;
