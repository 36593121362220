import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import {
  makeStyles, useTheme, useMediaQuery, Hidden,
} from '@material-ui/core';

import Button from 'creative-components/CustomButtons/Button';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import ProductMetrics from 'components/Product/ProductMetrics';
import ProductTerms from 'components/Product/ProductTerms';
import ProductTouches from 'components/Product/ProductTouches';
import ProductSequence from 'components/Product/ProductSequence';
import SupportContactCard from 'components/SupportContactCard/SupportContactCard';
import Testimonials from 'components/Testimonials/Testimonials';

import { productMarketingInfo, whyChooseUsText } from 'utils/productMarketingInfo';

import styles from 'assets/jss/material-dashboard-pro-react/components/productLayout2Style';

import section1BgGray from 'assets/img/products/new/layout2/section1-bg-gray.png';
import section1BgWhite from 'assets/img/products/new/layout2/section1-bg-white.png';
import section2Building from 'assets/img/products/new/layout2/section2-building.png';
import section3BgDarkGray from 'assets/img/products/new/layout2/section3-bg-dark-gray.png';
import section3BgOrange from 'assets/img/products/new/layout2/section3-bg-orange.png';

const useStyles = makeStyles(styles);

// TODO: When typescript, heroColorVariant: 'gray' | 'orange'
// TODO: When typescript, section1BgVariant: 'gray' | 'offWhite'
// TODO: When typescript, section3BgVariant: 'darkGray' | 'orange'
const ProductLayout2 = ({
  eventTypeName, heroImage, heroColorVariant, productTermsBgColor, section1BgVariant, section3BgVariant,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const isXsWidth = useMediaQuery(theme.breakpoints.only('xs'));

  const productInfo = productMarketingInfo[eventTypeName];

  // Separate these into variables because we change the
  // render order depending on screen size
  const headerLeft = (
    <GridItem xs={12} md={6}>
      <div className={classes.headerVideoContainer}>
        <iframe
          className={classes.videoContainer}
          src={`https://www.youtube-nocookie.com/embed/${productInfo.youtubeVideoId}?autoplay=0&modestbranding=1&rel=0`}
          title={eventTypeName}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <div className={classes.headerProductInfo}>
        <ProductTerms
          numCommitmentMonths={productInfo.numCommitmentMonths}
          membershipFeePerMonth={productInfo.membershipFeePerMonth}
          mailOutFeePerPiece={productInfo.mailOutFeePerPiece}
          minimumNumRecords={productInfo.minimumNumRecords}
          bgColor={productTermsBgColor}
        />
      </div>
    </GridItem>
  );

  const headerRight = (
    <GridItem xs={12} md={6} className={classes.headerTextContainer}>
      <h1>
        {eventTypeName}
        <span />
      </h1>
      <p className={classes.heroText1}>
        {productInfo.heroText1}
      </p>
      <p className={classes.heroText2}>
        {productInfo.heroText2}
      </p>
      <Button
        color="orange"
        round
        outlined
        size={!isXsWidth ? 'lg' : null}
        component={Link}
        to={{
          pathname: '/sign-up',
          state: { eventTypeName },
        }}
      >
        Get Started - Today!
      </Button>
    </GridItem>
  );

  const whatsIncluded = (
    <GridItem xs={12} md={6} className={cx(classes.section1TextContainer, section1BgVariant === 'gray' ? classes.section1TextColorBgGray : classes.section1TextColorBgOffWhite)}>
      <h2>
        What's Included:
        <span />
      </h2>
      {productInfo.whatsIncludedText}
    </GridItem>
  );

  const productTouches = (
    <GridItem xs={12} md={6} className={classes.section1TouchesContainer}>
      <ProductTouches
        positionVariant="left"
        numVoicemails={productInfo.numVoicemails}
        numLetters={productInfo.numLetters}
        numEmails={productInfo.numEmails}
        numPostcards={productInfo.numPostcards}
      />
    </GridItem>
  );

  return (
    <div className={classes.root}>
      <div
        className={classes.hero}
        style={{
          background: section1BgVariant === 'gray' ? theme.palette.lightGray.main : theme.palette.offWhite.main, // For continuity with section 1
        }}
      >
        <div className={classes.heroBackground} style={{ backgroundImage: `url(${heroImage})` }}>
          <div
            style={
            heroColorVariant === 'gray'
              ? { background: theme.palette.darkBlueGray.main, mixBlendMode: 'hard-light' }
              : { background: theme.palette.orange.main, mixBlendMode: 'multiply' }
            }
          />
        </div>

        <GridContainer className={classes.container}>
          {!isMobileWidth ? headerLeft : headerRight}
          {!isMobileWidth ? headerRight : headerLeft}
        </GridContainer>
      </div>

      <div
        className={classes.section1}
        style={{
          backgroundImage: section1BgVariant === 'gray' ? `url(${section1BgGray})` : `url(${section1BgWhite})`,
        }}
      >
        <GridContainer className={classes.container}>
          {!isMobileWidth ? productTouches : whatsIncluded}
          {!isMobileWidth ? whatsIncluded : productTouches}
        </GridContainer>
      </div>

      <div className={classes.section2}>
        {section1BgVariant === 'gray' ? (
          <div className={classes.section2Building} style={{ backgroundImage: `url(${section2Building})` }} />
        ) : (
          <div className={classes.section2Building} style={{ backgroundImage: `url(${section2Building})` }} />
        )}

        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.section2Container}>
            <ProductSequence />
            <div className={classes.section2Button}>
              <Button
                color="orange"
                round
                outlined
                size={!isXsWidth ? 'lg' : null}
                component={Link}
                to={{
                  pathname: '/sign-up',
                  state: { eventTypeName },
                }}
              >
                Get Started - Today!
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.section3Background} style={{ backgroundImage: section3BgVariant === 'darkGray' ? `url(${section3BgDarkGray})` : `url(${section3BgOrange})` }}>
        <GridContainer className={classes.container}>
          <GridItem xs={12} className={classes.section3Header}>
            <h2>
              Why Choose Us
              <span />
            </h2>
          </GridItem>

          {whyChooseUsText.points.map((text, i) => (
            <React.Fragment key={i}>
              <Hidden mdUp><GridItem xs={1} /></Hidden>
              <GridItem xs={10} md={4} className={classes.section3Points}>
                <h3>
                  {`0${i + 1}`}
                  <span />
                </h3>
                <p>
                  {text}
                </p>
              </GridItem>
              <Hidden mdUp><GridItem xs={1} /></Hidden>
            </React.Fragment>
          ))}

          <Hidden smDown>
            <GridItem md={6} className={classes.section3Body}>
              {whyChooseUsText.body}
            </GridItem>
          </Hidden>

          <GridItem xs={12} md={6} className={classes.supportContactCard}>
            <SupportContactCard color="white" />
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.metricsBackground}>
        <GridContainer className={classes.container} alignItems="center">
          <GridItem xs={12} md={4} lg={5} className={classes.metricsText}>
            <h2>
              We love numbers
            </h2>
          </GridItem>
          <Hidden mdUp><GridItem xs={1} /></Hidden>
          <GridItem xs={10} md={8} lg={7}>
            <ProductMetrics
              metrics={productInfo.metrics}
              metricColor="white"
              valueColor={theme.palette.grayScale8.main}
            />
          </GridItem>
          <Hidden mdUp><GridItem xs={1} /></Hidden>
        </GridContainer>
      </div>

      <div className={classes.testimonials}>
        <Testimonials />
      </div>
    </div>
  );
};

export default ProductLayout2;
