const style = (theme) => ({
  root: {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    backgroundColor: theme.palette.white.main,
    borderRadius: '16px',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',

    [theme.breakpoints.down('sm')]: {
      padding: '48px 29px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '514px',
      padding: '50px 46px',
    },
  },
  button: {
    marginTop: '30px',
    width: '100%',
    color: theme.palette.white.main,
    padding: '17px 0',
    fontSize: '29px',
    fontWeight: '500',
    lineHeight: '35px',
    background: theme.palette.orange.main,
    borderRadius: '71px',

    '&:hover': {
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '22px',
    },
  },

  hidden: {
    visibility: 'hidden',
  },
  submissionMessage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.darkGray.main,
  },
});

export default style;
