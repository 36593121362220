import capitalize from 'capitalize';
import cx from 'classnames';
import React from 'react';

import {
  ButtonBase, Dialog, Tab,
  Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SettingsIcon from '@material-ui/icons/Settings';
import TextFormatIcon from '@material-ui/icons/TextFormat';

import ActionModal from 'components/ActionModal/ActionModal';
import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import ChooseVoicemail from 'components/CampaignSteps/CustomTouches/ChooseVoicemail';
import EditBodyText from 'components/CampaignSteps/CustomTouches/EditBodyText';
import ReplaceDesign from 'components/CampaignSteps/CustomTouches/ReplaceDesign';
import VoicemailTouchBehaviorForm from 'components/CampaignSteps/CustomTouches/VoicemailTouchBehaviorForm';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';
import SimpleModalHeader from 'components/SimpleModalHeader/SimpleModalHeader';

import { resetCustomTouchTemplate } from 'utils/api';
import { showAPIErrorAlert } from 'utils/lib';

enum ETabType {
  ChooseVoicemailBehaviorTab = 'CHOOSE_VOICEMAIL_BEHAVIOR',
  ChooseVoicemailTab = 'CHOOSE_VOICEMAIL',
  EditTextTab = 'EDIT_TEXT',
  ReplaceDesignTab = 'REPLACE_DESIGN',
}

const useStyles = makeStyles((theme) => ({
  tabs: {
    margin: '40px 0 0',
  },
  tabsHidden: {
    display: 'none',
  },
  instructions: {
    color: theme.palette.darkGray.main,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    margin: '30px 0 20px',
    fontSize: '18px',

    '& > span': {
      color: theme.palette.lightGray.main,
      fontSize: '15px',
    },
  },
  cancelButton: {
    backgroundColor: `${theme.palette.warning.main} !important`,
  },
}));

const getActiveTabForTouchType = (touchTypeName: string) => {
  if (touchTypeName === 'LETTER' || touchTypeName === 'EMAIL') return ETabType.EditTextTab;
  if (touchTypeName === 'POSTCARD') return ETabType.ReplaceDesignTab;
  if (touchTypeName === 'VOICEMAIL') return ETabType.ChooseVoicemailBehaviorTab;
  return '';
};

interface Props {
  eventTypeVoicemails: any;
  touchTrigger: any;
  onReceiveEditedTouchTrigger: any;
  onClose: any;
}

const EditCustomTouchTemplateModal = ({
  eventTypeVoicemails, touchTrigger, onReceiveEditedTouchTrigger, onClose,
}: Props) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext() as any;
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext() as any;

  const { touchType: { name: touchTypeName } } = touchTrigger;

  // Letters have both options, postcards only PDF, and emails only editing text
  const [activeTab, setActiveTab] = React.useState(getActiveTabForTouchType(touchTypeName));
  const [isConfirmingReset, setIsConfirmingReset] = React.useState(false);

  const onResetCustomTouchTemplate = async () => {
    // Show a loading indicator during the upload
    showLoadingIndicatorModal();

    try {
      const { touchTrigger: updatedTouchTrigger } = await resetCustomTouchTemplate(touchTrigger._id);

      // Callback to update the touch trigger locally
      onReceiveEditedTouchTrigger(updatedTouchTrigger);

      onClose();

      setCurrentAlert('success', `Your edits have been reset for this touch. The default campaign ${touchTypeName.toLowerCase()} will be used when sending out your touch.`);
    } catch (err) {
      console.error(err);
      showAPIErrorAlert(setCurrentAlert, err);
    }

    hideLoadingIndicatorModal();
  };

  return (
    <>
      {isConfirmingReset && (
        <ActionModal
          onClose={() => setIsConfirmingReset(false)}
          title="Reset Edits"
          message="Are you sure you want to reset your edits for this touch? The default materials will be used for sending out this touch."
          buttons={[
            <ButtonBase onClick={() => setIsConfirmingReset(false)} className={classes.cancelButton}>No</ButtonBase>,
            <ButtonBase onClick={onResetCustomTouchTemplate}>Yes</ButtonBase>,
          ]}
        />
      )}

      <Dialog
        open
        scroll="body"
        keepMounted
        maxWidth="md"
        onClose={onClose}
      >
        <SimpleModalHeader onClose={onClose} title={`Customize Your ${capitalize(touchTypeName)}`} />

        <Tabs
          className={cx(classes.tabs, touchTypeName !== 'VOICEMAIL' && touchTypeName !== 'LETTER' && classes.tabsHidden)}
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          indicatorColor="secondary"
          textColor="secondary"
          centered
        >
          {touchTypeName === 'VOICEMAIL' && (
            <Tab
              icon={<SettingsIcon />}
              label="Delivery"
              value={ETabType.ChooseVoicemailBehaviorTab}
            />
          )}

          {touchTypeName === 'VOICEMAIL' && (
            <Tab
              icon={<PermPhoneMsgIcon />}
              label="Choose Recording"
              value={ETabType.ChooseVoicemailTab}
            />
          )}

          {['VOICEMAIL', 'EMAIL', 'LETTER'].includes(touchTypeName) && (
            <Tab
              icon={<TextFormatIcon />}
              label="Edit Text"
              value={ETabType.EditTextTab}
            />
          )}

          {['LETTER', 'POSTCARD'].includes(touchTypeName) && (
            <Tab
              icon={<PictureAsPdfIcon />}
              label="Replace Design"
              value={ETabType.ReplaceDesignTab}
            />
          )}
        </Tabs>

        {activeTab === ETabType.ChooseVoicemailBehaviorTab && (
          <VoicemailTouchBehaviorForm
            touchTrigger={touchTrigger}
            onReceiveEditedTouchTrigger={onReceiveEditedTouchTrigger}
          />
        )}

        {activeTab === ETabType.ChooseVoicemailTab && (
          <ChooseVoicemail
            touchTrigger={touchTrigger}
            onReceiveEditedTouchTrigger={onReceiveEditedTouchTrigger}
            eventTypeVoicemails={eventTypeVoicemails}
          />
        )}

        {activeTab === ETabType.EditTextTab && (
          <EditBodyText
            onClose={onClose}
            touchTrigger={touchTrigger}
            onReceiveEditedTouchTrigger={onReceiveEditedTouchTrigger}
            setIsConfirmingReset={setIsConfirmingReset}
          />
        )}

        {activeTab === ETabType.ReplaceDesignTab && (
          <ReplaceDesign
            onClose={onClose}
            touchTrigger={touchTrigger}
            onReceiveEditedTouchTrigger={onReceiveEditedTouchTrigger}
            setIsConfirmingReset={setIsConfirmingReset}
          />
        )}

      </Dialog>
    </>
  );
};

export default EditCustomTouchTemplateModal;
