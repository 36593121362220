import { container, hexToRgb } from 'assets/jss/material-dashboard-pro-react';

import section1Bg from 'assets/img/products/new/layout1/section1-bg.png';
import section3Bg from 'assets/img/products/new/layout1/section3-bg.png';
import section3Building from 'assets/img/products/new/layout1/section3-building.png';
import orangeDotsImage from 'assets/img/orange-dots.png';

const style = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  container: {
    ...container,
    position: 'relative',
  },

  hero: {
    position: 'relative',
  },
  heroBackground: {
    position: 'absolute',
    top: '-96px', // Home navbar is 96px in height
    left: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('sm')]: {
      bottom: '57px',
    },
    [theme.breakpoints.only('md')]: {
      bottom: '70px',
    },
    [theme.breakpoints.up('lg')]: {
      bottom: '116px',
    },

    '& div': {
      height: '100%',
      width: '100%',
      mixBlendMode: 'hard-light',
    },
  },
  headerTextContainer: {
    [theme.breakpoints.only('md')]: {
      marginBottom: '50px',
    },

    '& > h1 > span': {
      display: 'inline-block',
      width: '30px',
      height: '0px',
      border: `3px solid ${theme.palette.orange.main}`,
      marginLeft: '14px',
    },

    '& > h1': {
      [theme.breakpoints.down('sm')]: {
        margin: '22px 0 0',
        fontSize: '28px',
        lineHeight: '33px',
      },
      [theme.breakpoints.only('md')]: {
        margin: '70px 0 0',
        fontSize: '40px',
      },
      [theme.breakpoints.up('lg')]: {
        margin: '70px 0 0',
      },
    },

    '& > p': {
      [theme.breakpoints.only('md')]: {
        maxWidth: '561px',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '561px',
      },
    },
  },
  heroText1: {
    color: theme.palette.white.main,
    fontSize: '24px',
    lineHeight: '140%',
    margin: '18px 0 0',

    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  heroText2: {
    color: theme.palette.white.main,
    fontSize: '16px',
    lineHeight: '140%',
    marginTop: '36px',
    marginBottom: '43px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  headerVideoContainer: {
    border: `2px solid rgba(${hexToRgb(theme.palette.primary.main)}, 0.66)`,
    borderRadius: '16px',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      margin: '36px 0 16px',
      height: '160px',
    },
    [theme.breakpoints.only('md')]: {
      margin: '80px 0 130px',
      height: '230px',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '20px',
      height: '374px',
    },

    '& > iframe': {
      borderRadius: '16px',

      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  headerProductInfo: {
    [theme.breakpoints.up('md')]: {
      marginTop: '23px',
    },
  },

  section1: {
    backgroundImage: `url(${section1Bg})`,
    backgroundSize: '100vw 100%',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      backgroundPosition: '80%',
      marginTop: '-110px',
      padding: '130px 0 83px',
    },
    [theme.breakpoints.only('md')]: {
      backgroundSize: 'cover',
      marginTop: '-110px',
      padding: '130px 0 160px',
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '-110px',
      padding: '130px 0 200px',
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '-110px',
      padding: '130px 0 90px',
    },
  },
  section1TextContainer: {
    '& h2': {
      color: theme.palette.darkGray.main,
    },
    '& h2 span': {
      display: 'inline-block',
      height: '0px',
      border: `3px solid ${theme.palette.orange.main}`,
      marginLeft: '28px',
    },
    '& p': {
      color: theme.palette.darkGray.main,
      fontSize: '24px',
      lineHeight: '150%',
    },

    [theme.breakpoints.down('sm')]: {
      '& h2': {
        margin: '30px 0 0',
        display: 'flex',
        alignItems: 'end',
      },
      '& h2 span': {
        width: '100%',
        flex: 1,
        border: `2px solid ${theme.palette.orange.main}`,
      },
      '& p': {
        margin: '24px 0 0',
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    [theme.breakpoints.only('md')]: {
      '& h2': {
        margin: '32px 0 0',
        fontSize: '28px',
      },
      '& h2 span': {
        width: '140px',
      },
      '& p': {
        margin: '40px 0 0',
        fontSize: '20px',
        maxWidth: '360px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      '& h2': {
        margin: '32px 0 0',
      },
      '& h2 span': {
        width: '281px',
      },
      '& p': {
        margin: '60px 0 0',
        maxWidth: '580px',
      },
    },
  },
  section1TouchesContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '100px',
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '50px',
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '19px',
    },
  },

  section2Container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '160px',
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '150px',
    },
    [theme.breakpoints.only('xl')]: {
      marginTop: '56px',
    },
  },
  section2Button: {
    marginTop: '30px',
    textAlign: 'center',
  },

  section3: {
    position: 'relative',
    backgroundImage: `url(${section3Bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    margin: '50px 0 -2px', // -2px to get rid of white line

    [theme.breakpoints.only('md')]: {
      padding: '120px 0 200px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '120px 0',
    },
  },
  section3OrangeDots: {
    position: 'absolute',
    backgroundImage: `url(${orangeDotsImage})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',

    [theme.breakpoints.only('xs')]: {
      width: '29px',
      height: '24px',
      bottom: '100px',
      right: '50%',
    },
    [theme.breakpoints.only('sm')]: {
      width: '57px',
      height: '47px',
      bottom: '100px',
      right: '300px',
    },
    [theme.breakpoints.only('md')]: {
      width: '57px',
      height: '47px',
      bottom: '176px',
      right: '300px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '57px',
      height: '47px',
      bottom: '176px',
      right: '475px',
    },
  },
  section3Building: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    backgroundImage: `url(${section3Building})`,
    backgroundSize: 'contain',

    [theme.breakpoints.only('xs')]: {
      width: 'calc(477px * 0.5)',
      height: 'calc(401px * 0.5)',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(477px * 0.62)',
      height: 'calc(401px * 0.62)',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(477px * 0.8)',
      height: 'calc(401px * 0.8)',
    },
    [theme.breakpoints.up('lg')]: {
      width: '477px',
      height: '401px',
    },
  },
  section3Header: {
    marginBottom: '60px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      margin: '82px 0 48px',
    },

    '& > h2 > span': {
      display: 'inline-block',
      width: '30px',
      height: '0px',
      border: `2px solid ${theme.palette.orange.main}`,
      marginLeft: '14px',
    },
  },
  section3Points: {
    '& > h3 > span': {
      display: 'inline-block',
      width: '30px',
      height: '0px',
      border: `2px solid ${theme.palette.orange.main}`,
      marginLeft: '14px',
    },
    '& > p': {
      marginTop: '36px',
      color: 'white',
      fontSize: '24px',
      lineHeight: '150%',

      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        lineHeight: '24px',
        margin: '20px 0 40px',
        maxWidth: '360px',
      },
      [theme.breakpoints.only('xs')]: {
        maxWidth: '220px',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '360px',
      },
    },
  },
  section3Body: {
    '& > p': {
      margin: '0',
      marginTop: '120px',
      height: '241px',
      color: 'white',
    },
  },
  section3SmSpacer: {
    height: '241px',
  },

  supportContactCard: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    bottom: '-72px', // Half the height of the support card
    display: 'flex',
    justifyContent: 'center',
    zIndex: 5,

    [theme.breakpoints.down('sm')]: {
      bottom: '-52px', // Half the height of the support card
    },
  },

  metricsBackground: {
    padding: '132px 0 60px',
    backgroundColor: theme.palette.darkGray.main,
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      paddingTop: '40px 0 60px',
    },
  },
  metricsText: {
    '& > h2': {

      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        margin: '0 0 28px',
      },
    },
  },

  testimonials: {
    backgroundColor: theme.palette.primary.main,
  },
});

export default style;
