import { container } from 'assets/jss/material-dashboard-pro-react';

import heroImage from 'assets/img/pricing/hero.png';

const style = (theme) => ({
  root: {
    position: 'relative',
    width: '100%',
  },
  container: {
    ...container,
    position: 'relative',
  },
  hero: {
    position: 'relative',
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  heroOverlayColor: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,0.78)',
    mixBlendMode: 'hard-light',
  },

  header: {
    '& > h1': {
      margin: '50px 0 0',
      textAlign: 'center',
      fontSize: '51px',
      lineHeight: '60px',
      color: theme.palette.darkGray.main,

      [theme.breakpoints.down('sm')]: {
        margin: '32px 0 0',
        fontSize: '28px',
        lineHeight: '33px',
      },
    },
    '& > div': {
      margin: '18px auto 76px',
      width: '281px',
      height: '0px',
      border: `3px solid ${theme.palette.primary.main}`,

      [theme.breakpoints.down('sm')]: {
        margin: '12px auto 32px',
        width: '160px',
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
  },

  detailsContainer: {
    position: 'relative',
    background: 'linear-gradient(180deg, #A2E7F6 0%, #FFFFFF 58.85%)',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.25)',
    borderRadius: '16px',
    margin: '67px 25px 143px',
    padding: '66px 100px',

    '& > h3': {
      margin: '0 0 24px',
      color: theme.palette.darkGray.main,
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'bold',
    },
    '& > h3 > span': {
      display: 'inline-block',
      width: '24px',
      height: '0px',
      border: `2px solid ${theme.palette.orange.main}`,
      marginLeft: '10px',
    },
    '& > p': {
      color: theme.palette.darkGray.main,
      fontSize: '20px',
      lineHeight: '30px',
      margin: '48px 0 0',

      [theme.breakpoints.up('md')]: {
        maxWidth: '520px',
      },
    },
  },
  detailsInfo: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
      maxWidth: '720px',
    },
  },

  pricingCardTitleOrangeAccent: {
    display: 'inline-block',
    width: '24px',
    height: '0px',
    border: `2px solid ${theme.palette.orange.main}`,
    marginLeft: '10px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '4px',
    },
  },
  pricingCard: (props) => ({
    background: theme.palette.white.main,
    borderRadius: '16px',
    boxShadow: '0px 4px 40px rgba(105, 111, 121, 0.2)',
    padding: '24px 16px 35px',

    ...(props.orientation === 'horizontal' && {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '24px 32px 12px',

      [theme.breakpoints.down('sm')]: {
        padding: '24px 16px',
      },
    }),

    '& > div > h3': {
      margin: '0 0 12px',
      color: theme.palette.darkGray.main,
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'bold',
    },
    '& > div > p': {
      margin: '0 0 16px',
      fontSize: '14px',
      lineHeight: '150%',
      color: theme.palette.black.main,

      [theme.breakpoints.only('md')]: {
        maxWidth: props.orientation === 'horizontal' && '182px',
      },
      [theme.breakpoints.only('lg')]: {
        maxWidth: props.orientation === 'horizontal' && '222px',
      },
      [theme.breakpoints.only('xl')]: {
        maxWidth: props.orientation === 'horizontal' && '272px',
      },
    },
  }),
  pricingCardCostAndButtonContainer: (props) => ({
    order: props.orientation === 'horizontal' && 2, // Order at end
  }),
  pricingCardPriceAmount: {
    color: theme.palette.orange.main,
    fontWeight: '500',
    fontSize: '48px',
    lineHeight: '57px',
    letterSpacing: '-0.02em',

    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: '38px',
    },
  },
  pricingCardPriceAmountSlash: {
    color: theme.palette.darkGray.main,
    fontSize: '40px',
    lineHeight: '47px',
    letterSpacing: '-0.02em',

    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      lineHeight: '33px',
    },
  },
  pricingCardPriceAmountTerm: {
    color: theme.palette.darkGray.main,
    fontSize: '20px',
    lineHeight: '23px',
    letterSpacing: '-0.02em',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  pricingCardPriceDisclaimer: {
    color: theme.palette.darkGray.main,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '-0.02em',

    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      lineHeight: '12px',
    },
  },
  pricingCardButton: {
    margin: '24px 0 32px',
    color: theme.palette.darkGray.main,

    [theme.breakpoints.down('sm')]: {
      margin: '16px 0',
    },
  },
  pricingCardFeatures: (props) => ({
    marginBottom: props.orientation === 'vertical' && '12px',
  }),
  pricingCardBulletPoint: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.darkGray.main,
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '12px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },

    '& > svg': {
      color: `${theme.palette.orange.main} !important`,
      marginRight: '4px',
    },
  },
  spacer: {
    marginTop: '32px',
    width: '100%',
  },

  crmProCard: {
    marginTop: '32px',
  },

  brokerageAccount: {
    margin: '58px 0',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      margin: '40px 0 56px',
    },
  },
  brokerageAccountButton: {
    fontSize: '20px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
  },

  commonQuestions: {
    paddingBottom: '70px',
    background: theme.palette.lightGray.main,

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  testimonials: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    paddingTop: '30px',

    [theme.breakpoints.down('sm')]: {
      paddingTop: '36px',
    },
  },
  supportCard: {
    position: 'absolute',
    left: '0px',
    right: '0px',
    top: '-72px', // Half the height of the support card
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      top: '-52px', // Half the height of the support card
    },
  },

  pricingCardsCarousel: {
    overflow: 'unset', // Show shadow around card
  },
});

export default style;
