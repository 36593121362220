import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, InputBase } from '@material-ui/core';

import CustomInput from 'creative-components/CustomInput/CustomInput';
import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import CityStateAutocomplete from 'components/CityStateAutocomplete/CityStateAutocomplete';
import { useLoadingIndicatorContext } from 'components/LoadingIndicator/LoadingIndicatorProvider';

import { newsletterSubscribe } from 'utils/api';
import { nameRegExp } from 'utils/lib';

import styles from 'assets/jss/material-dashboard-pro-react/components/freeGuideFormStyle';

const useStyles = makeStyles(styles);

const FreeGuideForm = () => {
  const classes = useStyles();
  const { showLoadingIndicatorModal, hideLoadingIndicatorModal } = useLoadingIndicatorContext();

  const [submitted, setSubmitted] = React.useState(false);

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        region: '',
      }}
      onSubmit={async ({
        firstName, lastName, email, region,
      }) => {
        showLoadingIndicatorModal();

        try {
          await newsletterSubscribe({
            firstName,
            lastName,
            email,
            region,
            utmSource: 'harvist',
            utmMedium: 'website',
            utmCampaign: 'close-like-a-pro',
          });

          // TODO: SEND FREE GUIDE ENDPOINT???

          setSubmitted(true);
        } catch (error) {
          console.error('error', error);
        }

        hideLoadingIndicatorModal();
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required().matches(nameRegExp),
        lastName: Yup.string().required().matches(nameRegExp),
        email: Yup.string().email().required(),
        region: Yup.string().required(),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
        } = props;

        return (
          <Form className={classes.root}>
            {submitted && (
              <h3 className={classes.submissionMessage}>You will receive your free guide shortly in your email!</h3>
            )}

            <GridContainer className={submitted ? classes.hidden : null}>
              {/* <GridItem md={12}>
                <CustomInput labelText="Test" />
              </GridItem>
              <GridItem md={12}>
                <CustomInput
                  labelText="Test w/ placeholder"
                  inputProps={{
                    placeholder: 'Enter Email',
                  }}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomInput
                  labelText="Test w/ end adorn"
                  inputProps={{
                    endAdornment: (
                      <Visibility />
                    ),
                  }}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomInput
                  labelText="Test with start adorn"
                  inputProps={{
                    startAdornment: (
                      <Visibility />
                    ),
                  }}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomInput
                  labelText="Test with start and end adorn"
                  inputProps={{
                    startAdornment: (
                      <Visibility />
                    ),
                    endAdornment: (
                      <Visibility />
                    ),
                  }}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomInput
                  labelText="Test with start and end adorn with feedback error"
                  showFeedbackEndIcon
                  error
                  inputProps={{
                    startAdornment: (
                      <Visibility />
                    ),
                    endAdornment: (
                      <Visibility />
                    ),
                  }}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomInput
                  labelText="Test w/ end adorn with feedback error"
                  showFeedbackEndIcon
                  error
                  inputProps={{
                    endAdornment: (
                      <Visibility />
                    ),
                  }}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomInput labelText="Test with feedback error" showFeedbackEndIcon error />
              </GridItem>
              <GridItem md={12}>
                <CustomInput labelText="Test error" error />
              </GridItem>
              <GridItem md={12}>
                <CustomInput labelText="Test disabled" inputProps={{ disabled: true }} />
              </GridItem>
              <GridItem md={12}>
                <CustomInput
                  labelText="Test disabled w/ placeholder"
                  inputProps={{
                    disabled: true,
                    placeholder: 'Enter Email',
                  }}
                />
                </GridItem> */}
              <GridItem xs={12}>
                <Field
                  name="firstName"
                >
                  {({ field }) => (
                    <CustomInput
                      labelText="First Name"
                      inputProps={{
                        ...field,
                        autoComplete: 'given-name',
                        maxLength: 50,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="lastName"
                >
                  {({ field }) => (
                    <CustomInput
                      labelText="Last Name"
                      inputProps={{
                        ...field,
                        autoComplete: 'family-name',
                        maxLength: 50,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="email"
                >
                  {({ field }) => (
                    <CustomInput
                      labelText="Email"
                      inputProps={{
                        ...field,
                        autoComplete: 'email',
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="region"
                >
                  {({ field }) => (
                    <CityStateAutocomplete
                      fieldName={field.name}
                      setFieldValue={setFieldValue}
                      labelText="Where are you based?"
                      inputProps={{
                        ...field,
                        autoComplete: 'forceoff',
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem xs={12}>
                <ButtonBase
                  className={classes.button}
                  disableRipple
                  disableTouchRipple
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Get This Free Guide
                </ButtonBase>
              </GridItem>
            </GridContainer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FreeGuideForm;
