/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import 'utils/yupExtensions';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Button from 'creative-components/CustomButtons/Button';
import CustomInput from 'creative-components/CustomInput/CustomInput';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';
import ContactFields from 'components/ContactFields/ContactFields';
import { initialAddress, addressOptionalLatLngYupValidation } from 'components/AddressAutocomplete/AddressForm';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

import { createAgentCustomer, updateAgentCustomer } from 'utils/api';

import { nameRegExp, phoneRegExp, birthdayNoYearRegExp } from 'utils/lib';

import styles from 'assets/jss/material-dashboard-pro-react/modalStyle';
import customCheckboxRadioSwitchStyles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';

const useStyles = makeStyles((theme) => ({
  ...styles(theme),
  ...customCheckboxRadioSwitchStyles(theme),
}));

// const buyerCustomerType = 'BUYER';
// const sellerCustomerType = 'SELLER';

const initialContactValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  birthday: '',
  address: { ...initialAddress },
};

const EditAgentCustomerModal = ({ agentCustomer, onCancel, addAgentCustomer }) => {
  const classes = useStyles();
  const { setCurrentAlert } = useAlertContext();

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        primaryContact: agentCustomer
          ? {
            ...initialContactValues,
            ..._.pick(agentCustomer.primaryContact, ['firstName', 'lastName', 'email', 'phoneNumber', 'birthday', 'address']),
          }
          : {
            ...initialContactValues,
          },
        secondaryContact: agentCustomer && agentCustomer.secondaryContact
          ? {
            ...initialContactValues,
            ..._.pick(agentCustomer.secondaryContact, ['firstName', 'lastName', 'email', 'phoneNumber', 'birthday', 'address']),
          }
          : {
            ...initialContactValues,
          },
        notes: agentCustomer ? agentCustomer.notes : '',
        // customerType: agentCustomer ? agentCustomer.customerType : '',
      }}
      onSubmit={async ({
        primaryContact, secondaryContact, notes,
        // customerType,
      }) => {
        try {
          // Filter out any empty values and if the secondary contact isn't set, don't include it in the API request (check the required firstName field)
          primaryContact = {
            ..._.pickBy(primaryContact, _.identity),
            address: primaryContact.address.address1 ? primaryContact.address : undefined, // Remove address if unset
          };
          secondaryContact = secondaryContact.firstName ? {
            ..._.pickBy(secondaryContact, _.identity),
            address: secondaryContact.address.address1 ? secondaryContact.address : undefined, // Remove address if unset
          } : undefined;

          if (agentCustomer) {
            // Update existing agent customer
            // const { agentCustomer: newAgentCustomer } = await updateAgentCustomer(agentCustomer._id, primaryContact, secondaryContact, notes, customerType);
            const { agentCustomer: newAgentCustomer } = await updateAgentCustomer(agentCustomer._id, primaryContact, secondaryContact, notes);
            addAgentCustomer(newAgentCustomer);
          } else {
            // Create new agent customer
            // const { agentCustomer: newAgentCustomer } = await createAgentCustomer(primaryContact, secondaryContact, notes, customerType);
            const { agentCustomer: newAgentCustomer } = await createAgentCustomer(primaryContact, secondaryContact, notes);
            addAgentCustomer(newAgentCustomer);
          }

          setCurrentAlert('success', 'Successfully updated customer record');

          onCancel();
        } catch (error) {
          if (error.response && error.response.data.error) {
            setCurrentAlert('error', error.response.data.error);
          } else {
            setCurrentAlert('error', 'Something went wrong, please try again.');
          }

          console.error('error', error);
        }
      }}
      validationSchema={Yup.object().shape({
        primaryContact: Yup.object().shape({
          firstName: Yup.string().required().matches(nameRegExp),
          lastName: Yup.string().optional().matches(nameRegExp),
          email: Yup.string().email().optional(),
          phoneNumber: Yup.string().optional().matches(phoneRegExp, 'Phone number is not valid'),
          birthday: Yup.string().optional().matches(birthdayNoYearRegExp, 'Birthday is not valid'),
          address: addressOptionalLatLngYupValidation.optional(),
        }).required().atLeastOneRequired(['email', 'phoneNumber', 'address']),
        secondaryContact: Yup.object().shape({
          firstName: Yup.string().required().matches(nameRegExp),
          lastName: Yup.string().optional().matches(nameRegExp),
          email: Yup.string().email().optional(),
          phoneNumber: Yup.string().optional().matches(phoneRegExp, 'Phone number is not valid'),
          birthday: Yup.string().optional().matches(birthdayNoYearRegExp, 'Birthday is not valid'),
          address: addressOptionalLatLngYupValidation.optional(),
        }).optional().atLeastOneRequired(['email', 'phoneNumber', 'address']),
        notes: Yup.string().optional(),
        // customerType: Yup.string().oneOf([buyerCustomerType, sellerCustomerType]).required(),
      })}
    >
      {(props) => {
        const {
          touched, errors, dirty, isValid, handleSubmit, isSubmitting, setFieldValue, values,
        } = props;

        return (
          <Form>
            <Dialog
              classes={{
                root: classes.center,
                paper: classes.modal,
              }}
              open
              scroll="body"
              maxWidth="sm"
              keepMounted
              onClose={() => onCancel()}
              aria-labelledby="modal-slide-title"
              aria-describedby="modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  justIcon
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  color="transparent"
                  onClick={() => onCancel()}
                >
                  <CloseIcon className={classes.modalClose} />
                </Button>
                <h4 className={classes.modalTitle}>{agentCustomer ? 'Edit Customer' : 'Create Customer'}</h4>
              </DialogTitle>
              <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}
              >
                {isSubmitting ? <LoadingIndicator modal /> : null}
                {/* <h6>Customer Type</h6>
                <Field
                  name="customerType"
                >
                  {({ field }) => (
                    <>
                      <FormControlLabel
                        control={(
                          <Radio
                            {...field}
                            checked={field.value === buyerCustomerType}
                            onChange={() => setFieldValue(field.name, buyerCustomerType, true)}
                            icon={(
                              <FiberManualRecordIcon
                                className={classes.radioUnchecked}
                              />
                        )}
                            checkedIcon={(
                              <FiberManualRecordIcon
                                className={classes.radioChecked}
                              />
                        )}
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                    )}
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Buyer"
                      />
                      <FormControlLabel
                        control={(
                          <Radio
                            {...field}
                            checked={field.value === sellerCustomerType}
                            onChange={() => setFieldValue(field.name, sellerCustomerType, true)}
                            icon={(
                              <FiberManualRecordIcon
                                className={classes.radioUnchecked}
                              />
                        )}
                            checkedIcon={(
                              <FiberManualRecordIcon
                                className={classes.radioChecked}
                              />
                        )}
                            classes={{
                              checked: classes.radio,
                              root: classes.radioRoot,
                            }}
                          />
                    )}
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot,
                        }}
                        label="Seller"
                      />
                    </>
                  )}
                </Field> */}
                <h6>Primary Contact</h6>
                <ContactFields
                  valueName="primaryContact"
                  addressDisplayName="Primary Contact Address"
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <br />
                <h6>Secondary Contact</h6>
                <ContactFields
                  valueName="secondaryContact"
                  addressDisplayName="Secondary Contact Address"
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
                <br />
                <br />
                <h6>Notes</h6>
                <Field
                  name="notes"
                >
                  {({ field }) => (
                    <CustomInput
                      labelText=""
                      margin="normal"
                      formControlProps={{
                        fullWidth: true,
                        style: { paddingTop: 0 },
                      }}
                      inputProps={{
                        ...field,
                        multiline: true,
                        rows: 5,
                      }}
                      error={touched[field.name] && errors[field.name] !== undefined}
                    />
                  )}
                </Field>
              </DialogContent>
              <DialogActions
                className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                <Button
                  color="primary"
                  simple
                  size="lg"
                  block
                  disabled={!isValid || !dirty || isSubmitting}
                  onClick={handleSubmit}
                >
                  {agentCustomer !== undefined ? 'Update' : 'Create'}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

EditAgentCustomerModal.propTypes = {
  agentCustomer: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  addAgentCustomer: PropTypes.func.isRequired,
};

export default EditAgentCustomerModal;
