import React from 'react';

import { hexToRgb } from 'assets/jss/material-dashboard-pro-react';
import { useTheme, makeStyles } from '@material-ui/core/styles';

import styles from 'assets/jss/material-dashboard-pro-react/components/insightsCardStyle.js';

const useStyles = makeStyles(styles);

const InsightsCard = ({
  image, description, link,
}) => {
  const theme = useTheme();
  const classes = useStyles({ image });

  return (
    <div className={classes.root}>
      <a href={link}>
        <div className={classes.image} />
        <p className={classes.descriptionText}>
          {description}
        </p>
        <span className={classes.learnMoreText}>
          Learn More
        </span>
      </a>
    </div>
  );
};

export default InsightsCard;
