import React from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';

import GridContainer from 'creative-components/Grid/GridContainer';
import GridItem from 'creative-components/Grid/GridItem';

import OurMissionStatement from 'components/Product/OurMissionStatement';
import SignUpForm from 'components/SignUpForm/SignUpForm';

import styles from 'assets/jss/material-dashboard-pro-react/views/signUpPageStyle';

const useStyles = makeStyles(styles);

const Step = ({ className, title, text }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.step, className)}>
      <h3>{title}</h3>
      <p>
        {text}
      </p>
    </div>
  );
};

const SignUpPage = () => {
  const classes = useStyles();
  const location = useLocation();

  let eventTypeName;
  if (location.state) eventTypeName = location.state.eventTypeName;

  return (
    <div className={classes.root}>
      <div className={classes.background}>
        <div className={classes.hero}>
          <div className={classes.heroOverlay} />
          <Hidden smDown>
            <div className={classes.person} />
          </Hidden>
        </div>
      </div>

      <div className={classes.hero}>
        <div className={classes.heroOverlay} />
        <Hidden smDown>
          <div className={classes.person} />
        </Hidden>

        <GridContainer className={classes.container}>
          <div className={classes.heroGraphic} />
          <Hidden mdUp>
            <div className={classes.person} />
          </Hidden>

          <GridItem xs={12}>
            <h1 className={classes.title}>
              {`Apply to Activate ${eventTypeName || 'Your Account'} 🚀 Today!`}
            </h1>
          </GridItem>

          <GridItem xs={12} md={6}>
            <div className={classes.left}>
              <Step
                className={classes.step1}
                title="Step 1: Apply"
                text="We will take the thought out of farming. Our application process is based on ability and performance."
              />
              <Step
                className={classes.step2}
                title="Step 2: Complete Your Profile"
                text="After you submit your interest application and complete your profile..."
              />
              <Step
                className={classes.step3}
                title="Step 3: Invite Agents!"
                text="Our reps will guide you on how to invite agents and start them on our platform."
              />
            </div>
          </GridItem>
          <GridItem xs={12} md={6}>
            <div className={classes.right}>
              <div className={classes.rightHeader}>
                <h2>Sign Up!</h2>
                <div />
              </div>
              <SignUpForm initialSelectedEventTypeName={eventTypeName} />
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <div className={classes.missionStatement}>
        <OurMissionStatement showBackgroundImage />
      </div>
    </div>
  );
};

export default SignUpPage;
