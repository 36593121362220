const style = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',

    '& * h3': {
      fontFamily: 'Roboto',
    },
  },
  metricContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px 16px 0',
    },
  },
  value: {
    color: theme.palette.white.main,
    letterSpacing: 'normal !important;',
    lineHeight: '26px',

    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      lineHeight: '26px',
    },
  },
  metric: {
    color: theme.palette.black.main,
    fontSize: '16px',
    lineHeight: '20px',
    margin: '16px 0 0',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
      margin: '8px 0 0',
    },
  },
});

export default style;
