import React from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { makeStyles } from '@material-ui/core/styles';

import Button from 'creative-components/CustomButtons/Button';

import styles from 'assets/jss/material-dashboard-pro-react/components/feedbackResponseInputStyle';

const useStyles = makeStyles(styles);

const numberOfStars = 5;

const FeedbackResponseStarRating = ({
  responseIndex, responseTypesArrayHelpers, responseTypeIndex,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const stars = [];

  const numStarsSelected = values.responses[responseIndex][responseTypeIndex] || 0;

  for (let i = 0; i < numberOfStars; i++) {
    stars.push((
      <Button
        key={i}
        simple
        round
        justIcon
        size="lg"
        color="dark"
        onClick={() => responseTypesArrayHelpers.replace(responseTypeIndex, String(i + 1))}
      >
        {i + 1 > numStarsSelected ? <StarBorderIcon /> : <StarIcon />}
      </Button>
    ));
  }

  return (
    <>
      {stars}
    </>
  );
};

export default FeedbackResponseStarRating;
