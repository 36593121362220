import React from 'react';
import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import CustomInput from 'creative-components/CustomInput/CustomInput';

import styles from 'assets/jss/material-dashboard-pro-react/components/feedbackResponseInputStyle';

const useStyles = makeStyles(styles);

const FeedbackResponseShortText = ({ responseIndex, responseTypesArrayHelpers, responseTypeIndex }) => {
  const classes = useStyles();
  const { errors } = useFormikContext();

  return (
    <div className={classes.shortInputContainer}>
      <CustomInput
        labelText=""
        margin="normal"
        formControlProps={{
          fullWidth: true,
        }}
        inputProps={{
          placeholder: 'Answer',
          onChange: (e) => responseTypesArrayHelpers.replace(responseTypeIndex, e.target.value),
        }}
        error={errors.responses !== undefined && errors.responses[responseTypeIndex] !== undefined}
        feedbackEndIconStyles={{ marginRight: '20px' }} // To not overlap the scrollbar, if it appears
      />
    </div>
  );
};

export default FeedbackResponseShortText;
