import { ArrayHelpers, useFormikContext } from 'formik';
import React from 'react';
import zipcodes from 'zipcodes';

import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

import CustomInputDashboard from 'creative-components/CustomInput/CustomInputDashboard';

import { useAlertContext } from 'components/AlertProvider/AlertProvider';

const useStyles = makeStyles((theme) => ({
  zipcodesChip: {
    padding: 0,
    paddingRight: '6px',

    margin: theme.spacing(0.5),
    fontSize: '14px',
  },
  zipcodesFormControl: {
    marginBottom: 0,
    '& .MuiInputBase-formControl': {
      overflowX: 'auto',
    },
    '& .MuiInputBase-adornedStart': {
      paddingLeft: '10px',
    },
  },
}));

export interface Props {
  labelText: string;
  maxNumberOfZipcodes?: number;

  // With Formik
  fieldName?: string;
  arrayHelpers?: ArrayHelpers;
  error?: string;

  // Without Formik
  selectedZipcodes?: string[];
  selectedZipcodeColors?: string[]; // Allows setting the color per-chip
  setSelectedZipcodes?: (selectedZipcodes: string[]) => void;
}

const MultiZipcodesInput: React.FC<Props> = ({
  labelText,
  maxNumberOfZipcodes = 6,

  // With Formik
  fieldName, arrayHelpers, error,

  // Without Formik
  selectedZipcodes,
  selectedZipcodeColors,
  setSelectedZipcodes,
}) => {
  const classes = useStyles();
  const { values } = useFormikContext() as any;
  const { setCurrentAlert } = useAlertContext() as any;

  const newZipcodeInputRef = React.createRef<HTMLInputElement>();

  const toggleSelect = (zipcode: string, index: number) => {
    // Check if this uses Formik or not
    if (fieldName) { // Formik
      if (index === -1) {
        arrayHelpers!.push(zipcode);
      } else {
        arrayHelpers!.remove(index);
      }

      return;
    }

    // Not Formik
    if (selectedZipcodes!.indexOf(zipcode) !== -1) {
      setSelectedZipcodes!(selectedZipcodes!.filter((z) => z !== zipcode));
    } else {
      setSelectedZipcodes!([
        ...selectedZipcodes!,
        zipcode,
      ]);
    }
  };

  const handleButtonPress = (showAlerts: boolean) => {
    if (!newZipcodeInputRef.current) return;

    const { value } = newZipcodeInputRef.current;

    if (!value) {
      if (showAlerts) setCurrentAlert('warning', 'Please enter a zipcode!');
    } else if (!zipcodes.lookup(value)) {
      if (showAlerts) setCurrentAlert('error', `${value} is not a valid zipcode!`);
    } else if ((fieldName && !values[fieldName].includes(value)) || (selectedZipcodes && !selectedZipcodes.includes(value))) {
      toggleSelect(value, -1);
    }

    newZipcodeInputRef.current.value = '';
  };

  return (
    <CustomInputDashboard
      labelText={labelText}
      formControlProps={{
        className: classes.zipcodesFormControl,
      }}
      showFeedbackEndIcon={false}
      error={error}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
      inputProps={{
        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            // Enter button submit
            event.preventDefault();
            handleButtonPress(true);
          } else if (event.key === 'Backspace' || event.key === 'Delete') { // Backspace button delete last
            // Start by deleting text in the input field, then by deleting zipcode chips
            if (newZipcodeInputRef.current && newZipcodeInputRef.current.value && newZipcodeInputRef.current.value.length > 0) {
              newZipcodeInputRef.current.value.slice(0, -1);
            } else if ((fieldName && values[fieldName].length > 0) || (selectedZipcodes && selectedZipcodes.length > 0)) {
              toggleSelect(fieldName ? values[fieldName].slice(-1)[0] : selectedZipcodes!.slice(-1)[0], -1);
            }
          }
        },
        onBlur: () => handleButtonPress(false),
        autoComplete: 'forceoff',
        disabled: fieldName ? values[fieldName].length >= maxNumberOfZipcodes : selectedZipcodes!.length >= maxNumberOfZipcodes,
        inputRef: newZipcodeInputRef,
        startAdornment: (
          <>
            {(values[fieldName!] || selectedZipcodes).map(((zipcode: string, index: number) => (
              <Chip
                key={index}
                style={selectedZipcodeColors
                  ? { backgroundColor: selectedZipcodeColors[index] ?? '' }
                  : {}}
                color={selectedZipcodeColors ? 'secondary' : 'primary'}
                label={zipcode}
                onDelete={() => toggleSelect(zipcode, index)}
                className={classes.zipcodesChip}
              />
            )))}
          </>
        ),
      }}
    />
  );
};

export default MultiZipcodesInput;
