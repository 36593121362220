import React from 'react';

import { makeStyles } from '@material-ui/core';

import styles from 'assets/jss/material-dashboard-pro-react/components/productMetricsStyle';

const useStyles = makeStyles(styles);

const ProductMetrics = ({ metrics, metricColor, valueColor }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {metrics.map(({ metric, value }) => (
        <div key={metric} className={classes.metricContainer}>
          <h3 className={classes.value} style={{ color: valueColor }}>{value}</h3>
          <p className={classes.metric} style={{ color: metricColor }}>{metric}</p>
        </div>
      ))}
    </div>
  );
};

export default ProductMetrics;
