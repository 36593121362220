const style = (theme) => ({
  root: {
    backgroundColor: theme.palette.gray.main,
    borderRadius: '16px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.only('xs')]: {
      padding: '18px 16px',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '18px 24px',
    },
    [theme.breakpoints.only('md')]: {
      padding: '24px 16px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '32px 20px',
    },

    '& p': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  text: {
    color: theme.palette.white.main,
    lineHeight: '140%',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.only('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.only('lg')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.only('xl')]: {
      fontSize: '24px',
    },
  },
  leftText: {
    textAlign: 'right',
    marginRight: '24px',

    [theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
  },
  rightText: {
    fontWeight: 'bold',
  },
});

export default style;
